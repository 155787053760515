import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule as LibSharedModule } from '@swan/lib/shared';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslocoModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslocoModule,
        LibSharedModule,
    ],
})
export class SharedModule
{
}
