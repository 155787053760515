import { Injectable } from '@angular/core';
import { LoginRequestEventArgs } from './auth';
import { AuthGuardService } from './AuthGuardService';
import { SessionService } from './SessionService';


@Injectable()
export class NoAuthGuardService extends AuthGuardService
{
    constructor(sessionService: SessionService)
    {
        super(sessionService);
    }

    protected override async validate(redirectUrl: string): Promise<boolean>
    {
        if (await this._sessionService.validate()) {

            if (redirectUrl.startsWith(this._loginRequestEventArgs?.signInUrl as string)) {
                return true;
            }

            this._loginRequestEventArgs = new LoginRequestEventArgs(redirectUrl);
            this._sessionService.auth.loginRequest.invoke(this._loginRequestEventArgs);

            return false;
        }

        return true;
    }
}
