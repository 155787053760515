import { Injector } from '@angular/core';
import {
    Content,
    ContentVideoList,
    ContentViewService,
    FavoriteVideoList,
    IVideoFile,
    VideoListException,
    VideoListTypes,
} from '@swan/lib/content';
import { ContentFilter, ContentStreamType } from '@swan/lib/domain';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { lastValueFrom } from 'rxjs';
import { SwanVideoFile } from './swan-video-file.entity';


export class SwanVideoList extends ContentVideoList<SwanVideoFile>
{
    public constructor(
        injector: Injector,
        private readonly _type: VideoListTypes,
        favoriteVideoList?: FavoriteVideoList<Content, SwanVideoFile>,
        filter?: ContentFilter,
    )
    {
        super(injector, favoriteVideoList, filter);
    }

    public get type(): VideoListTypes
    {
        return this._type;
    }

    public override get items(): SwanVideoFile[]
    {
        return this.streamType === ContentStreamType.FAVORITE
            ? this.favorites.items
            : super.items;
    }

    public override async reload(file: SwanVideoFile): Promise<boolean>
    {
        const index = this.items.indexOf(file);

        if (index === -1) {
            throw new VideoListException(`Video file '${file.id}' does not exist within video list '${this.streamType}'.`);
        }

        const queryResult = (await lastValueFrom((this.injector.get(ContentViewService)).queryEnriched({
            contentId: file.id,
        }))) as QueryResult<SwanVideoFile>;

        if (queryResult.rows === 0) {
            return false;
        }
        const item = queryResult.items[0];
        file.updateFromJson({
            content : item.fileInfo,
            favorite: item.favorite,
            liked   : item.liked,
        });

        return true;
    }

    protected override map(
        fileInfo: IVideoFile<Content>,
        index: number,
        array: Array<IVideoFile<Content>>): SwanVideoFile
    {
        return new SwanVideoFile(fileInfo);
    }
}
