import { isEqualWith } from 'lodash-es';
import { PlainObject } from 'simplytyped';


/**
 * Returns a time format like "1:01" or "4:03:59" or "123:03:59"
 *
 * @param time The time as unix timestamp.
 * @param round
 */
export const timeFormat = (time: number, round: boolean = false): string =>
{
    // Hours, minutes and seconds
    /* eslint-disable no-bitwise */
    const hrs  = ~~(time / 3600);
    const mins = ~~((time % 3600) / 60);
    const secs = ~~time % 60;

    const numbers = new Array<string>();
    if (hrs > 0) {
        numbers.push(hrs.toString());
    }
    if (!round || mins > 0) {
        numbers.push(hrs > 0 ? mins.toString().padStart(2, '0') : mins.toString());
    }
    if (!round || secs > 0) {
        numbers.push(!round || mins > 0 ? secs.toString().padStart(2, '0') : secs.toString());
    }

    return numbers.length > 0 ? numbers.join(':') : '0';
};

/**
 * Updates the list indexes with only updated items.
 *
 * @param list
 * @param items
 */
export const updateList = <T>(list: Array<T>, items: Array<T>): void =>
{
    list.length = items.length;
    for (const _item of items) {
        const index = items.indexOf(_item);

        if (!isEqualWith(_item, list[index])) {
            list[index] = _item;
        }
    }
};

export const enumKeys = <O extends object, K extends keyof O = keyof O>(obj: O): K[] =>
    (Object.keys(obj).filter(k => Number.isNaN(+k)) as K[]);

export const removeEmpty = <T extends PlainObject = PlainObject>(obj: T): T =>
{
    Object.keys(obj).forEach((key) =>
    {
        if (obj[key] === Object(obj[key])) {
            (obj as PlainObject)[key] = removeEmpty(obj[key]);
        }
        else if (obj[key] === undefined) {
            delete obj[key];
        }
    });

    return obj as T;
};
