import { ObjectModel } from '@awesome-nodes/mvvm/model';
import { IEvent } from '@awesome-nodes/object';
import { ActionObserver, EventDelegate } from '@swan/mvvm/model';
import { ServiceBase } from '@swan/mvvm/services';
import { firstValueFrom, lastValueFrom, map, Observable, tap, throwError } from 'rxjs';
import { Nullable } from 'simplytyped';
import { Credentials } from '../model/user';
import { ISessionService } from './ISessionService';
import { ISessionChangeOptions, SessionChangedEventArgs } from './SessionChangedEventArgs';
import { SessionException } from './SessionException';
import { User } from './user/User';


export abstract class Session extends ActionObserver<Session>
{
    //eslint-disable-next-line @typescript-eslint/naming-convention
    public static readonly SessionDisconnected: typeof SessionDisconnected = class extends Session
    {
        public constructor()
        {
            super();
        }

        public async login(
            sessionService: ISessionService,
            credentials: Credentials): Promise<Session>
        {
            return await firstValueFrom(this.observe(
                () => sessionService.auth.login(credentials).pipe(
                    map(user => new Session.SessionConnected(user)),
                ),
                {
                    complete: () =>
                    {
                        const session = this.actionResultValue;
                        const user    = session.user as User;

                        try {
                            sessionService.store.setJSON('token', user.authToken);
                            sessionService.store.setJSON('user', user.info);
                        }
                        catch (error) {
                            console.error(error);
                        }

                        return new SessionChangedEventArgs(session);
                    },
                },
            ));
        }

        public logout(): void
        {
            throw new SessionException('Logout failed. No session active!');
        }
    };

    //eslint-disable-next-line @typescript-eslint/naming-convention
    public static readonly SessionConnected: typeof SessionConnected = class extends Session
    {
        public constructor(user: User)
        {
            super(user);
        }

        public override get user(): User
        {
            return this._user as User;
        }

        public login(): Promise<Session>
        {
            throw new SessionException('Login failed. Session is not disconnected.');
        }

        public logout(sessionService: ISessionService, options?: ISessionChangeOptions): void
        {
            sessionService.auth.logout();
            this._user = null;
            sessionService.store.clear();

            this.onSessionChanged(this, new SessionChangedEventArgs(new Session.SessionDisconnected(), options));
        }
    };

    static #_instance: Session;
    private static readonly _sessionChanged = new EventDelegate<Session, SessionChangedEventArgs>(null as unknown as Session);

    //region Instance Members

    protected constructor(protected _user?: Nullable<User>)
    {
        super();

        this.onComplete = this.onSessionChanged as IEvent<ObjectModel>;
        this.onError    = ServiceBase.handleError;
    }

    //region Static Properties

    public static get sessionChanged(): EventDelegate<Session, SessionChangedEventArgs>
    {
        return this._sessionChanged;
    }

    public static get instance(): Session
    {
        return Session.#_instance;
    }

    //endregion

    public get user(): Nullable<User>
    {
        return this._user;
    }

    //region Static Members

    public static async restore(sessionService: ISessionService): Promise<boolean>
    {
        let session: Nullable<Session> = null;
        const { auth, store }          = sessionService;

        try {
            const newSession = await lastValueFrom(auth.restore(store.getJSON('token')));
            if (newSession instanceof Session.SessionConnected) {
                sessionService.store.setJSON('token', newSession.user.authToken);
                sessionService.store.setJSON('user', newSession.user.info);
            }
            session = newSession;
        }
        catch (error) {
            console.error(error);
        }

        if (session == null) {
            session = new this.SessionDisconnected();
        }

        session.onSessionChanged(session, new SessionChangedEventArgs(session));

        return this.#_instance instanceof Session.SessionConnected;
    }

    public static refresh(sessionService: ISessionService): Observable<Session>
    {
        if (Session.instance instanceof Session.SessionDisconnected) {
            return throwError(() => new SessionException('Session disconnected'));
        }

        return sessionService.auth.restore(Session.instance.user?.authToken.toJSON()).pipe(
            tap((session) =>
            {
                if (session instanceof Session.SessionConnected) {
                    sessionService.store.setJSON('token', session.user.authToken);
                    sessionService.store.setJSON('user', session.user.info);
                }
                return Session.#_instance = session;
            }),
        );
    }

    //endregion

    protected onSessionChanged(sender: Session, ea: SessionChangedEventArgs): void
    {
        Session.#_instance = ea.session;
        Session._sessionChanged.invoke(ea);
    }

    public abstract login(sessionService: ISessionService, credentials: Credentials): Promise<Session>;

    public abstract logout(sessionService: ISessionService, options?: ISessionChangeOptions): void;

    //endregion
}


export declare class SessionDisconnected extends Session
{
    constructor();

    public login(sessionService: ISessionService, credentials: Credentials): Promise<Session>;

    public logout(sessionService: ISessionService): void;
}

export declare class SessionConnected extends Session
{
    constructor(user: User);

    override get user(): User;

    public login(sessionService: ISessionService, credentials: Credentials): Promise<Session>;

    public logout(sessionService: ISessionService, options?: ISessionChangeOptions): void;
}
