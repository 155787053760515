import { ProgressBarMode } from '@angular/material/progress-bar';
import { Disposable } from '@awesome-nodes/mvvm/model';
import { Observable, Subscription } from 'rxjs';
import { PlainObject } from 'simplytyped';
import { MuxUpload, MuxUploadEvent, MuxUploadStatus as IMuxUploadStatus } from './mux-upload';


export class MuxUploadProgress extends Disposable implements IMuxUploadStatus
{
    private _event!: MuxUploadEvent;
    private _message!: string;
    private _progress!: number;
    private _progressbarMode: ProgressBarMode = 'query';
    private _uploadProgressSubscription: Subscription;

    public constructor(
        private _muxUpload: MuxUpload,
        private _statusHandler?: (uploadStatus: IMuxUploadStatus) => void,
    )
    {
        super();
        this._uploadProgressSubscription = _muxUpload.uploadProgress$.subscribe(this.update.bind(this));
    }

    public get status(): IMuxUploadStatus
    {
        return this;
    }

    public get status$(): Observable<IMuxUploadStatus>
    {
        return this._muxUpload.uploadProgress$;
    }

    public get event(): MuxUploadEvent
    {
        return this._event;
    }

    public get message(): string
    {
        return this._message;
    }

    public get progress(): number
    {
        return this._progress;
    }

    public get progressbarMode(): ProgressBarMode
    {
        return this._progressbarMode;
    }

    public update(_uploadStatus: IMuxUploadStatus): void
    {
        this._event = _uploadStatus.event;
        switch (_uploadStatus.event) {
            case 'attempt':
                this._progressbarMode = this._progress === 0 ? 'query' : 'determinate';
                this._message         = _uploadStatus.message;
                this._message += this.generateDetails(_uploadStatus.progress as object);
                break;
            case 'attemptFailure':
                this._progressbarMode = 'determinate';
                this._message         = _uploadStatus.message;
                this._message += this.generateDetails(_uploadStatus.progress as object);
                break;
            case 'chunkSuccess':
                this._progressbarMode = 'indeterminate';
                this._message         = _uploadStatus.message;
                this._message += this.generateDetails(_uploadStatus.progress as object);
                break;
            case 'offline':
                this._progressbarMode = 'buffer';
                this._message         = _uploadStatus.message;
                break;
            case 'online':
                this._progressbarMode = 'indeterminate';
                this._message         = _uploadStatus.message;
                break;
            case 'error':
                this._progressbarMode = 'determinate';
                this._message         = _uploadStatus.message;
                this._message += this.generateDetails(_uploadStatus.progress as object);
                break;
            case 'progress':
                this._progressbarMode = 'determinate';
                this._message         = _uploadStatus.message;
                this._progress        = _uploadStatus.progress as number;
                break;
            case 'completed':
                this._progressbarMode = 'determinate';
                this._message += `\n\n${_uploadStatus.message}`;
                this._progress        = 100;
                break;

        }

        if (this._statusHandler) {
            this._statusHandler(_uploadStatus);
        }
    }

    public override dispose(disposing?: boolean): void
    {
        if (disposing) {
            this._uploadProgressSubscription.unsubscribe();
        }
        super.dispose(disposing);
    }

    public pause(): void
    {
        this._muxUpload.pause();
    }

    public resume(): void
    {
        this._muxUpload.resume();
    }

    public abort(): void
    {
        this._muxUpload.abort();
    }

    private generateDetails(data: PlainObject, depth = 0): string
    {
        let details = '';

        if (depth === 2) {
            return '';
        }

        for (const _key of Object.keys(data)) {
            const valueKey = _key[0].toUpperCase() + _key.substring(1);
            let value      = data[_key];
            if (value == null) {
                continue;
            }
            if (typeof value === 'object') {
                details += this.generateDetails(value, depth + 1);
            }
            else {
                if (_key === 'url' && typeof value === 'string') {
                    value = value.substring(0, value.lastIndexOf('/'));
                }

                details += `\n${valueKey}: ${value}`;
            }
        }

        return details;
    };
}
