import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CordovaService } from '@swan/lib/cordova';
import { filter, take } from 'rxjs';
import { AppInjector } from '../app.injector';


@Injectable({ providedIn: 'root' })
export class SwanSplashScreenService
{
    /**
     * Constructor
     */
    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private _router: Router,
    )
    {
        // Hide it on the first NavigationEnd event
        this._router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                take(1),
            )
            .subscribe((routerEvent) =>
            {
                // eslint-disable-next-line no-console
                if (SwanSplashScreenService.logsAvailable) {

                    window.addEventListener('close-splash-screen', this.closSplashScreen);
                    return;
                }
            });
    }

    private static get logsAvailable(): boolean
    {
        return window.logService.logs.some(line => line.startsWith('warn') || line.startsWith('error'));
    }

    private static get hideClassName(): string
    {
        return AppInjector.get(CordovaService).onCordova
            ? 'swan-splash-screen-hidden'
            : 'swan-splash-screen-hidden-animated';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Show the splash screen
     */
    show(): void
    {
        this._document.body.classList.remove(SwanSplashScreenService.hideClassName);
    }

    /**
     * Hide the splash screen
     */
    hide(force = false): void
    {
        if (force || !SwanSplashScreenService.logsAvailable) {

            window.removeEventListener('close-splash-screen', this.closSplashScreen);
            window.dispatchEvent(new Event('close-splash-screen'));
            this._document.body.classList.add(SwanSplashScreenService.hideClassName);
            setTimeout(() =>
            {
                const splashScreen = document.body.getElementsByTagName('swan-splash-screen');
                if (splashScreen.length === 0) {
                    throw new Error('Splash screen not found!');
                }
                splashScreen.item(0)?.remove();
            }, 1000);
        }
    }

    private closSplashScreen = (): void =>
    {
        this.hide(true);
    };
}
