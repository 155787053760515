import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { fadeIn } from '@swan/lib/animations';
import { EmojiService, getPaginatorIntl, MarkedPipe } from '@swan/lib/shared';
import { TableFilter } from '@yukawa/chain-base-angular-domain';
import { Subject, takeUntil } from 'rxjs';
import { ConstructorFor, Nullable } from 'simplytyped';
import { QueryTableStore } from './model';
import { IQueryTableEntry, IQueryTableEntryDetail } from './types';


@Component({
    selector   : 'lib-query-table',
    templateUrl: './query-table.component.html',
    styleUrls  : ['./query-table.component.scss'],
    animations : [fadeIn],
    providers  : [
        {
            provide   : MatPaginatorIntl,
            useFactory: getPaginatorIntl,
        },
    ],
})
export class QueryTableComponent<T extends IQueryTableEntry = IQueryTableEntry> implements OnInit, OnChanges, OnDestroy
{
    @ViewChild(MatPaginator, { static: true })
    paginator!: MatPaginator;

    @ViewChild(MatSort, { static: true })
    sort!: MatSort;

    @Input()
    filter!: TableFilter;

    @Input()
    dataSource!: QueryTableStore<T>;

    @Input()
    dataStore!: ConstructorFor<QueryTableStore<T>>;

    @Input()
    reloadEntry!: EventEmitter<T>;

    @Output()
    entrySelected                = new EventEmitter<Nullable<T>>();
    readonly #unsubscribeAll     = new Subject();
    private readonly _markedPipe = new MarkedPipe();

    constructor(
        private readonly _changeDetectorRef: ChangeDetectorRef,
        private readonly _emojiService: EmojiService,
    )
    {
    }

    async ngOnInit(): Promise<void>
    {
        if (this.dataSource?.dispose) {
            this.dataSource?.dispose();
        }
        this.dataSource = new this.dataStore(this.paginator, this.sort, this.filter);
        this.dataSource.entrySelected.subscribe(entry => this.entrySelected.emit(entry));
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void>
    {
        if (changes['dataSource']?.previousValue) {
            this.dataSource.disconnect();
            this._changeDetectorRef.detectChanges();
            await this.ngOnInit();
        }

        if (changes['reloadEntry'].previousValue == null) {
            this.reloadEntry
                ?.pipe(takeUntil(this.#unsubscribeAll))
                .subscribe(() =>
                {
                    this.dataSource.reload();
                });
        }
    }

    ngOnDestroy(): void
    {
        if (this.dataSource?.dispose) {
            this.dataSource.dispose();
        }
        this.#unsubscribeAll.next(null);
        this.#unsubscribeAll.complete();
    }

    getRowDetail(row: T, detail: IQueryTableEntryDetail): any
    {
        return this.dataSource.getRowDetail<string>(row, detail, (value: string) =>
        {
            if (detail?.emojiSupport) {
                value = this._emojiService.colonsToNative(value);
            }
            if (detail?.markdownSupport) {
                value = this._markedPipe.transform(value);
            }
            return value;
        });
    }
}
