import { Attribute, Component, OnInit, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { swanAnimations } from '@swan/lib/animations';
import { CordovaService, StatusBar } from '@swan/lib/cordova';
import { InterestInfo } from '@swan/lib/profile';
import { AppInjector } from '@swan/lib/shared';
import { VideoRecordService } from '../../video-record.service';


@Component({
    selector   : 'app-video-tags',
    templateUrl: './video-tags.component.html',
    styleUrls  : ['./video-tags.component.scss'],
    animations : [swanAnimations],
})
export class VideoTagsComponent implements OnInit
{
    public readonly tags        = new Array<InterestInfo>();
    public readonly maxTagCount = 8;

    constructor(
        @Attribute('class') private readonly _classNames: string,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _router: Router,
        private readonly _videoRecordService: VideoRecordService,
        @Optional() private readonly _dialogRef: MatDialogRef<VideoTagsComponent>,
    )
    {
    }

    public get isDialog(): boolean
    {
        return this._dialogRef != null;
    }

    public get statusBarPadding(): boolean
    {
        return AppInjector.get(CordovaService).onCordova && (AppInjector.get(StatusBar).overlays ?? false);
    }

    public get selectedTagsCount(): number
    {
        return this._videoRecordService.selectedInterests.length;
    }

    async ngOnInit(): Promise<void>
    {
        await this._videoRecordService.loadInterests();
    }

    public searchTextChanged(value: string): void
    {
        this.tags.length = 0;
        if (!value) {
            return;
        }

        // Search equal
        this.tags.push(...this._videoRecordService.interests.filter(
            interest => interest.name.startsWith(value)),
        );

        // Search equal and ignore case
        this.tags.push(...this._videoRecordService.interests.filter(
            interest => !this.tags.some(_interest => _interest.id === interest.id) && interest.name.toLowerCase().startsWith(
                value.toLowerCase())),
        );

        // Search global
        this.tags.push(...this._videoRecordService.interests.filter(
            interest => !this.tags.some(_interest => _interest.id === interest.id) && interest.name.indexOf(value) > -1),
        );

        // Search global and ignore case
        this.tags.push(...this._videoRecordService.interests.filter(
            interest => !this.tags.some(_interest => _interest.id === interest.id) && interest.name.toLowerCase().indexOf(
                value.toLowerCase()) > -1),
        );
    }

    public selectTag(_interest: InterestInfo): void
    {
        if (!_interest.selected && this._videoRecordService.selectedInterests.length === this.maxTagCount) {
            return;
        }
        _interest.selected = !_interest.selected;
    }

    public navigateBack(): void
    {
        if (this._dialogRef != null) {
            this._dialogRef.close();
        }
        else {
            this._router.navigate(['..'], { relativeTo: this._activatedRoute });
        }
    }
}
