import { EventEmitter } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { EntityFilter, QueryResult, TableFilter } from '@yukawa/chain-base-angular-domain';
import { QueryTableSource } from '@yukawa/chain-base-angular-store';
import { BehaviorSubject, Observable } from 'rxjs';
import { Nullable, Required } from 'simplytyped';
import { IQueryTableEntry, IQueryTableEntryDetail } from '../types';


interface IQueryTableEntryDetailGroup extends Required<Partial<IQueryTableEntryDetail>, 'group' | 'key' | 'label'>
{
    details: Array<IQueryTableEntryDetail>;
}

export type EntryGroupDirection = 'vertical' | 'horizontal';

export interface IQueryTableGroupDetail
{
    name: string;
    direction: EntryGroupDirection;
    details: Array<IQueryTableEntryDetail>;
}

export abstract class QueryTableStore<E extends IQueryTableEntry, F extends TableFilter | EntityFilter = EntityFilter> extends QueryTableSource<F, E>
{
    public readonly headers          = new Array<IQueryTableEntryDetail | IQueryTableEntryDetailGroup>();
    public readonly displayedColumns = new Array<string>();
    public readonly detailGroups     = new Map<string, Array<IQueryTableGroupDetail>>();
    public readonly detailValues     = new Map<string, Map<string, unknown>>();
    public readonly loaded           = new EventEmitter();
    public readonly entrySelected    = new EventEmitter<Nullable<E>>();

    private _selectedEntry: Nullable<E>;

    protected constructor(
        paginator: MatPaginator,
        sort: MatSort,
        tableFilter: F)
    {
        super(paginator, sort, tableFilter);
        this.filterSubject = new BehaviorSubject<F>(tableFilter);

        this.showSpinner = true;
    }

    public abstract get entries(): Array<E>;

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public get selectedEntry(): Nullable<E>
    {
        return this._selectedEntry;
    }

    public set selectedEntry(value: Nullable<E>)
    {
        this._selectedEntry = value;
        this.entries.forEach(file => file.selected = file === value);
        this.entrySelected.emit(value);
    }

    /**
     * Retrieves and caches table entry detail values.
     *
     * @param row
     * @param detail
     * @param transform
     * @return
     */
    public getRowDetail<T = unknown>(row: E, detail: IQueryTableEntryDetail, transform?: (value: T) => T): T
    {
        const videoFileDetail = row.details.get(detail.key);

        let rowMap: Map<string, unknown>;

        if (!this.detailValues.has(row.id)) {
            rowMap = new Map();
            this.detailValues.set(row.id, rowMap);
        }
        else {
            rowMap = this.detailValues.get(row.id) as Map<string, unknown>;
        }

        if (rowMap.has(detail.key)) {
            return rowMap.get(detail.key) as T;
        }

        let value = videoFileDetail?.value || '';
        value     = transform ? transform(value) : value;

        rowMap.set(detail.key, value);
        return value;
    }

    protected override async onLoaded(rows: E[]): Promise<void>
    {
        super.onLoaded(rows);
        if (this.selectedEntry) {
            const entry = rows.find(row => row.id === this.selectedEntry?.id);
            if (entry) {
                entry.selected     = true;
                this.selectedEntry = entry;
            }
            else {
                this.selectedEntry = null;
            }
        }
        await this.init();
    }

    protected override queryTable(): Observable<QueryResult<E>>
    {
        const filter = this.findFilter();

        this.applyFilter(filter);
        if (filter.keyword) {
            filter.keyword += '*';
        }

        return this.queryEntries(filter);
    }

    protected override applyFilter(filter: F): void
    {
        super.applyFilter(filter);

        const orderByColumn = this.headers
            .find(header => (header as IQueryTableEntryDetailGroup).key === filter.orderBy) as IQueryTableEntryDetailGroup;

        if (orderByColumn && orderByColumn.group) {
            const detail   = this.detailGroups.get(orderByColumn.group)?.reduce<IQueryTableEntryDetail | null>((
                previous,
                next) => next.details
                .find(_detail => _detail.groupByField) as IQueryTableEntryDetail, null);
            filter.orderBy = `${orderByColumn.group}.${detail?.key || filter.orderBy}`;
        }
    }

    protected entryUpdated(entry: E): void
    {
        this.detailValues.delete(entry.id);
        this.reload();
    }

    private async init(): Promise<void>
    {
        const entryDetails = this.entries.reduce((current, next) =>
        {
            next.details.forEach((detail, key, index) =>
            {
                if (!current.find(_detail => (_detail as IQueryTableEntryDetail).key === detail.key)) {
                    current.splice(
                        Array.from(next.details.values()).findIndex(_detail => _detail.key === detail.key),
                        0,
                        detail,
                    );
                }
            });

            return current;
        }, new Array<IQueryTableEntryDetail>());

        this.headers.length = 0;
        this.headers.push(...entryDetails.reduce((previous, detail) =>
        {
            if (detail.showInTable) {
                if (!detail.tableGroup) {
                    previous.push(detail as IQueryTableEntryDetail);
                }
                else {
                    let groupDetail = previous.find(_detail => _detail.group === detail.group) as IQueryTableEntryDetailGroup;
                    if (!groupDetail) {
                        groupDetail = {
                            group     : detail.group as string,
                            key       : detail.group as string,
                            label     : detail.label,
                            tableGroup: detail.tableGroup,
                            details   : new Array<IQueryTableEntryDetail>(),
                        };
                        previous.push(groupDetail as IQueryTableEntryDetail);
                    }
                    groupDetail.details.push(detail as IQueryTableEntryDetail);

                }
            }
            return previous;
        }, new Array<IQueryTableEntryDetail>()));

        this.displayedColumns.length = 0;
        this.displayedColumns.push(...this.headers.reduce((previous, next) =>
        {
            const item = next.tableGroup ? next.group as string : next.key;
            if (!previous.includes(item)) {
                previous.push(item);
            }
            return previous;
        }, new Array<string>()));

        this.headers.forEach((header) =>
        {
            if ((header as IQueryTableEntryDetailGroup).details) {
                this.detailGroups.set(
                    header.key,
                    this.createDetailGroups((header as IQueryTableEntryDetailGroup).details),
                );
            }
        });

        this.detailValues.clear();
    }

    private createDetailGroups(details: Array<IQueryTableEntryDetail>): Array<IQueryTableGroupDetail>
    {
        return details.sort((detail, other) =>
        {
            if (detail.groupIndex == null || other.groupIndex == null) {
                return 0;
            }

            if (detail.groupIndex > other.groupIndex) {
                return +1;
            }

            if (detail.groupIndex < other.groupIndex) {
                return -1;
            }

            return 0;
        }).reduce((previousValue, currentValue) =>
        {
            let detailGroup = previousValue.find(_detailGroup => _detailGroup.name === currentValue.group
                && _detailGroup.direction === currentValue.groupDirection);

            if (!detailGroup) {
                detailGroup = {
                    name     : currentValue.group as string,
                    direction: currentValue.groupDirection as EntryGroupDirection,
                    details  : new Array<IQueryTableEntryDetail>(),
                };
                previousValue.push(detailGroup);
            }
            detailGroup.details.push(currentValue);

            return previousValue;
        }, new Array<IQueryTableGroupDetail>());
    }

    public abstract dispose?(): void;

    protected abstract queryEntries(filter: F): Observable<QueryResult<E>>;
}
