import { Injectable, Renderer2 } from '@angular/core';
import { LoadScriptService, ScriptLoadingStatus } from '@swan/lib/content/utils';


/**
 * Provides a way to load the most recent mux web player from CDN.
 * @example
 *  const ladingResults = await this._muxPlayerService.loadPlayer(this._renderer);
 *  ladingResults.forEach((result) =>
 *  {
 *      if (result.error != null) {
 *          console.error(result);
 *      }
 *  });
 */
@Injectable()
export class MuxPlayerService extends LoadScriptService
{
    private readonly _playerName = 'mux-player';

    public async loadPlayer(renderer: Renderer2): Promise<Array<ScriptLoadingStatus>>
    {
        if (!this.scriptStore.contains(this._playerName)) {
            this.scriptStore.add(
                'javascript',
                `${this._playerName}-javascript`,
                'https://unpkg.com/@mux/videojs-kit@latest/dist/index.js',
            );
            this.scriptStore.add(
                'css',
                `${this._playerName}-css`,
                'https://unpkg.com/@mux/videojs-kit@latest/dist/index.css',
            );
        }

        return this.loadScripts(renderer);
    }
}
