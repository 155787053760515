import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PublicProfile, PublicProfileFilter } from '@swan/lib/domain';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { lastValueFrom, map } from 'rxjs';
import { Profile } from './profile.entity';


@Injectable()
export class PublicProfileService extends RestAspect
{
    constructor(http: HttpClient, configService: ConfigService)
    {
        super(http, configService, configService.formatUrl('publicProfileUrl'));
    }

    public queryByUserName(username: string): Promise<Profile>
    {
        return lastValueFrom(super.query<PublicProfile>(
            this.formatServiceUrl('/query'),
            { username } as PublicProfileFilter,
        ).pipe(
            map((response) =>
            {
                if (response.rows === 0) {
                    throw new Error(`Profile '${username}' not found`);
                }
                return new Profile(response.items[0]);
            }),
        ));
    }
}
