import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { swanAnimations } from '@swan/lib/animations';
import { AuthService, ProfileService, UserService } from '@swan/lib/profile';
import { lastValueFrom } from 'rxjs';


@Component({
    selector   : 'app-intro',
    templateUrl: './intro.component.html',
    styleUrls  : ['./intro.component.scss'],
    animations : swanAnimations,
})
export class IntroComponent implements OnInit, OnDestroy
{
    username: string                   = null;
    showPersonalizeExperience: boolean = false;
    showPersonalizeLater: boolean      = false;

    constructor(
        private _router: Router,
        private _auth: AuthService,
        private _user: UserService,
        private _profile: ProfileService,
    )
    {
    }

    async ngOnInit(): Promise<void>
    {
        const profile = await lastValueFrom(this._profile.getProfile());

        this.username = profile
            ? profile.displayName
            : (this._user.user ? this._user.user.username : '');

        setTimeout(() =>
        {
            this.showPersonalizeExperience = true;
        }, 3000);
        setTimeout(() =>
        {
            this.showPersonalizeLater = true;
        }, 3075);
    }

    public ngOnDestroy(): void
    {
        window.document.body.classList.remove('bg-black');
    }

}
