import { Location } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponentBase } from '@swan/components/app-component-base';
import { ProfileService } from '@swan/lib/profile';
import { SwanSplashScreenService } from '@swan/lib/shared';
import { AuthGuardService, SessionService } from '@swan/session';


@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'],
})
export class AppComponent extends AppComponentBase
{
    /**
     * Constructor
     */
    constructor(
        authGuardService: AuthGuardService,
        changeDetector: ChangeDetectorRef,
        location: Location,
        profileService: ProfileService,
        router: Router,
        sessionService: SessionService,
        splashScreenService: SwanSplashScreenService,
    )
    {
        super(
            authGuardService,
            changeDetector,
            location,
            profileService,
            router,
            sessionService,
            splashScreenService,
        );
    }

    protected get initialRoute(): string
    {
        return '/home';
    }
}
