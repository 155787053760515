import { Location } from '@angular/common';
import { ChangeDetectorRef, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CordovaService, SplashScreen } from '@swan/lib/cordova';
import { ProfileService } from '@swan/lib/profile';
import { AppInjector, SwanSplashScreenService } from '@swan/lib/shared';
import {
    AuthGuardService,
    AuthService,
    LoginRequestEventArgs,
    Session,
    SessionChangedEventArgs,
    SessionService,
} from '@swan/session';


@Injectable()
export abstract class AppComponentBase implements OnInit
{
    /**
     * Constructor
     */
    protected constructor(
        private readonly _authGuardService: AuthGuardService,
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _location: Location,
        private readonly _profileService: ProfileService,
        private readonly _router: Router,
        private readonly _sessionService: SessionService,
        private readonly _splashScreenService: SwanSplashScreenService,
        /*private readonly _platform: Platform,
        private readonly update: SwUpdate,
        private readonly appRef: ApplicationRef,
        private readonly swPush: SwPush,*/
    )
    {
        _sessionService.sessionChanged.subscribe(this.sessionChanged, this);
        _sessionService.auth.loginRequest.subscribe(this.loginRequest, this);
    }

    protected abstract get initialRoute(): string;

    // eslint-disable-next-line @angular-eslint/contextual-lifecycle
    public async ngOnInit(): Promise<void>
    {
        let command: string;
        if (await this._sessionService.restore()) {

            if (this._authGuardService.lastForbiddenRoute) {
                command = this._authGuardService.lastForbiddenRoute;
            }
            else {
                const locationPath = this._location.path(true);
                if (locationPath !== '' && locationPath !== '/') {
                    command = locationPath;
                }
                else {
                    command = '/signed-in-redirect';
                }
            }

        }
        else {
            command = this.initialRoute;
        }
        await this._router.navigateByUrl(command);

        switch (command) {
            case '/signed-in-redirect':
            case '/video':
                break;
            default:
                this._splashScreenService.hide();
                this._changeDetector.detectChanges();
                if (AppInjector.get(CordovaService).onCordova) {
                    await AppInjector.get(SplashScreen).hide({
                        fadeOutDuration: 1000,
                    });
                }
                break;
        }
    }

    /*public updateClient(): void
    {
        if (!this.update.isEnabled) {
            console.log('Not Enabled');
            return;
        }
        this.update.available.subscribe((event) =>
        {
            console.log('current', event.current, 'available ', event.available);
            if (confirm('Update available for the app please conform')) {
                this.update.activateUpdate().then(() => location.reload());
            }
        });

        this.update.activated.subscribe((event) =>
        {
            console.log('current', event.previous, 'available ', event.current);
        });
    }

    public checkUpdate(): void
    {
        this.appRef.isStable.subscribe((isStable) =>
        {
            if (isStable) {
                const timeInterval = interval(8 * 60 * 60 * 1000);

                timeInterval.subscribe(() =>
                {
                    this.update.checkForUpdate().then(() => console.log('checked'));
                    console.log('update checked');
                });
            }
        });
    }*/

    private async sessionChanged(sender: Session, ea: SessionChangedEventArgs): Promise<void>
    {
        if (ea.session instanceof Session.SessionDisconnected) {
            if (this._router.navigated && !this._router.url.startsWith('/sign-out')) {
                await this._router.navigate(['home']);
            }
        }
    }

    private async loginRequest(sender: AuthService, ea: LoginRequestEventArgs): Promise<void>
    {
        let redirectUrl: string;
        let queryParams = {};
        if (sender.isAuthenticated) {
            redirectUrl = '/signed-in-redirect';
        }
        else {
            if (this._authGuardService.lastForbiddenRoute) {
                queryParams = { redirectURL: this._authGuardService.lastForbiddenRoute };
            }

            redirectUrl = '/sign-in';
        }

        ea.signInUrl = redirectUrl;
        await this._router.navigate([redirectUrl], { queryParams });
    }
}
