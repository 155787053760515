import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogData, SwanConfirmDialogComponent } from '@swan/lib/components';
import { CordovaService, StatusBar } from '@swan/lib/cordova';
import { AppInjector } from '@swan/lib/shared';
import { Nullable } from 'simplytyped';
import { VideoRecordService, VideoRecordServiceException } from './video-record.service';


@Component({
    selector   : 'app-video-record',
    templateUrl: './video-record.component.html',
    styleUrls  : ['./video-record.component.scss'],
})
export class VideoRecordComponent implements OnInit, AfterViewInit, OnDestroy
{
    @ViewChild('video')
    public video!: ElementRef<HTMLVideoElement>;

    @ViewChild('fileDropRef')
    public fileDrop!: ElementRef<HTMLInputElement>;

    public lastVideoImage: Nullable<string> = null;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _matDialog: MatDialog,
        private _router: Router,
        private _videoRecordService: VideoRecordService,
    )
    {
    }

    get statusBarVisible(): boolean
    {
        return AppInjector.get(CordovaService).onCordova && (AppInjector.get(StatusBar).overlays ?? false);
    }

    public async ngOnInit(): Promise<void>
    {
        if (await this._videoRecordService.init()) {
            await this.init();
        }
    }

    public async ngAfterViewInit(): Promise<void>
    {
        if (!this._videoRecordService.cordovaService.onCordova) {
            await this.init();
        }
    }

    public ngOnDestroy(): void
    {
        this.video.nativeElement.pause();
        this.video.nativeElement.srcObject = null;
        this.video.nativeElement.load();
        this.video.nativeElement.remove();
    }

    public async init(): Promise<void>
    {
        const video     = this.video.nativeElement;
        video.muted     = true;
        video.volume    = 0;
        const srcObject = await this._videoRecordService.captureCamera();
        if (srcObject) {
            video.srcObject = srcObject;
        }
    }

    public async openPhotoLibrary(): Promise<void>
    {
        if (await this._videoRecordService.openPhotoLibrary()) {
            this.fileDrop.nativeElement.click();
        }
    }

    public fileSelected(files: Event): void
    {
        try {
            this._videoRecordService.file = ((files.target as HTMLInputElement).files as FileList)[0];
        }
        catch (error) {
            if (error instanceof VideoRecordServiceException) {
                this._matDialog.open(SwanConfirmDialogComponent, {
                    disableClose: false,
                    minWidth    : '50vw',
                    data        : {
                        title         : 'VIDEO.RECORD.UPLOAD.SIZE_TO_LARGE.TITLE',
                        confirmMessage: 'VIDEO.RECORD.UPLOAD.SIZE_TO_LARGE.MESSAGE',
                        yesButton     : 'BUTTON.OK',
                        noButton      : '',
                    } as ConfirmDialogData,
                });
            }
            else {
                console.error(error);
            }
        }

        this._router.navigate(['details'], { relativeTo: this._activatedRoute });
    }
}
