import {Change, ChangeFilter, Changed, Created, EntityFilter, Info} from '@yukawa/chain-base-angular-domain';


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.Complaint <br/>
*/
export interface Complaint extends  Changed, Created{
change?: Change;
complaintId?: number;
created?: Change;

/**
*	@example I am not OK with this
*/
description?: string;

/**
*	@example speech
*/
reason?: string;

/**
*	@example 100
*/
refCommentId?: number;

/**
*	@example 100
*/
refContentId?: number;

/**
*	@example user1
*/
refProfileId?: string;

/**
*	@example action taken
*/
remark?: string;

/**
*	@example NEW
*/
status?: ComplaintStatus;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.ComplaintFilter <br/>
*/
export interface ComplaintFilter extends  EntityFilter{
complaintId?: number;
reason?: string;
status?: ComplaintStatus[];
}

export enum ComplaintStatus {
NEW = "NEW",
RESOLVED = "RESOLVED",
REJECTED = "REJECTED"
}

export enum Gender {
MALE = "MALE",
FEMALE = "FEMALE",
DIVERSE = "DIVERSE"
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.Interest <br/>
*/
export interface Interest extends  Changed, Created{
change?: Change;
children?: Interest[];
contentAssignments?: number;
created?: Change;
desc?: string;
i18n?: {[key: string]: Info};
imageId?: string;
interestId?: string;
name?: string;
parent?: Interest;
position?: number;
profileAssignments?: number;
shortName?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.InterestFilter <br/>
*/
export interface InterestFilter extends  EntityFilter{
desc?: string;
enrichChildren?: boolean;
interestId?: string;
name?: string;
parentInterestId?: string;
shortName?: string;
underInterestIds?: string[];
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.InterestInfo <br/>
*/
export interface InterestInfo extends  Info{
assignments?: number;
interests?: InterestInfo[];
position?: number;
selected?: boolean;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.Profile <br/>
*/
export interface Profile extends  Changed, Created{
change?: Change;
created?: Change;
currentOffersConsent?: boolean;
dateOfBirth?: Date;
description?: string;
displayName?: string;
earlyAccessConsent?: boolean;
gender?: Gender;
imageId?: string;
interests?: string[];
latestNewsConsent?: boolean;
skinType?: SkinType;
skipNotifications?: string[];
username?: string;
verified?: string;
website?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.ProfileFilter <br/>
*/
export interface ProfileFilter extends  EntityFilter{
username?: string;
usernames?: string[];
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.ProfileFollow <br/>
*/
export interface ProfileFollow extends  Changed{
change?: Change;
username?: string;
usernameFollowed?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.ProfileFollowFilter <br/>
*/
export interface ProfileFollowFilter extends  EntityFilter{
changeFilter?: ChangeFilter;
username?: string;
usernameFollowed?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.PublicProfile <br/>
*/
export interface PublicProfile {
description?: string;
displayName?: string;
imageId?: string;
username?: string;
verified?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.PublicProfileFilter <br/>
*/
export interface PublicProfileFilter extends  EntityFilter{
username?: string;
usernames?: string[];
}

export enum SkinType {
PALE_WHITE = "PALE_WHITE",
FAIR_WHITE = "FAIR_WHITE",
DARKER_WHITE = "DARKER_WHITE",
LIGHT_BROWN = "LIGHT_BROWN",
BROWN = "BROWN",
DARK_BROWN = "DARK_BROWN"
}

