import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PlayerTick as IPlayerTick } from '@swan/lib/domain';
import { AppInjector } from '@swan/lib/shared';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { Observable, tap } from 'rxjs';
import { PlayerPosition } from 'swan/lib/src/lib/content/player.position.entity';
import { PlayerPositionService } from './player-position.service';


export interface PlayerTick extends IPlayerTick
{
    contentId: number;
    second: number;
    totalSecondsViewed: number;
}

@Injectable()
export class PlayerStatisticsService extends RestAspect
{
    constructor(
        http: HttpClient,
        configService: ConfigService,
    )
    {
        super(http, configService, configService.formatUrl('playerTickUrl'));
    }

    tick(tick: PlayerTick): Observable<void>
    {
        return this.http.post<void>(this.formatServiceUrl('/tick'), tick).pipe(
            tap(() =>
            {
                const playerPosition = AppInjector.get(PlayerPositionService).repository.get(tick.contentId.toString()) as PlayerPosition;
                if (playerPosition) {
                    playerPosition.second = Math.round(tick.second);
                }
            }),
        );
    }
}
