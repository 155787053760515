import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import { Observable } from 'rxjs';
import { InitService } from './init.service';


@NgModule({
    providers: [
        {
            provide   : APP_INITIALIZER,
            useFactory: (myInitService: InitService) => (): Observable<void> => myInitService.initConfigs(),
            deps      : [
                InitService,
                ConfigService,
            ],
            multi     : true,
        },
    ],
})
export class InitModule
{
}
