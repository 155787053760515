import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { EntityFilter } from '@yukawa/chain-base-angular-domain/chain/base/core/entity';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PlainObject } from 'simplytyped';
import { Entity } from './entity';
import { EntityCache } from './entity-cache';
import { HttpOptions, RestEntityService } from './rest-entity.service';


/**
 * The CachedEntityService provides wraps the EntityService and provides a cache that stores
 * previously fetched entity objects from the server. Objects within the cache are updated when
 * new values are fetched from the server.
 */
export abstract class CachedRestEntityService<T extends Entity> extends RestEntityService<T>
{
    public readonly cache = new EntityCache<T>();

    /**
     * This function is used to map the path ids to a unique key to locate the associated entity
     * within the cache.
     *
     * @param pathIds the pathIds used to determine the key
     * @returns       a unique key to identify the associated entity
     */
    private static keyFromPathIds(pathIds: number | string | Entity | PlainObject): string
    {
        if (typeof pathIds === 'object') {
            if (pathIds instanceof Entity) {
                return pathIds.key;
            }
            return pathIds.hasOwnProperty('id') ? pathIds['id'].toString() : '';
        }
        else if (typeof pathIds === 'number') {
            return pathIds.toString();
        }
        else {
            return pathIds;
        }
    }

    /**
     * Make an update request to the endpoint, using the supplied object to identify which id to update.
     * If updated, the cache is updated ot set with the entity.
     *
     * @param pathIds An object with keys which match the placeholders within the endpointFormat string.
     * @param obj
     * @param options Optional http options
     */
    public override update(pathIds: object | T, obj?: T, options?: HttpOptions): Observable<T>;
    public override update(pathIds: any, obj?: T, options?: HttpOptions): Observable<T>
    {
        return super
            .update(pathIds, obj, options)
            .pipe(tap(updatedEntity => this.cache.add(updatedEntity.key, updatedEntity)));
    }

    /**
     * Make a query request (get all) to the end point, using the supplied parameters to determine path.
     * Caches all returned entities
     *
     * @param pathIds An object with keys which match the placeholders within the endpointFormat string.
     * @param other   Any other data that is needed to be passed to the creation of entities
     *                resulting from this get request.
     * @param options Optional http options
     * @returns a new cold observable
     */
    public override query<E = T>(pathIds?: object, other?: object, options?: HttpOptions): Observable<QueryResult<E>>;
    public override query(url: string, filter?: EntityFilter, options?: HttpOptions): Observable<QueryResult<T>>;
    public override query(
        pathIds?: string | object,
        other?: object | EntityFilter,
        options?: HttpOptions): Observable<QueryResult<T>>
    {
        return super.query<T>(pathIds as object, other, options).pipe(
            tap((entityList) =>
            {
                entityList.items.forEach((entity) =>
                {
                    this.cache.add((entity as never as T).key, entity as never);
                });
            }),
        );
    }

    /**
     * First, tries to retrieve from cache, the object with the id, or id field from the pathIds.
     * If found, return the item from cache, otherwise make a get request to the end point,
     * using the supplied parameters to determine path. Caches the returned object
     *
     * @param pathIds Either the id, if a number and maps simple to ':id', otherwise an object
     *                with keys the match the placeholders within the endpointFormat string.
     * @param other   Any other data that is needed to be passed to the creation of entities
     *                resulting from this get request.
     * @param options Optional http options
     */
    public fetch(pathIds: number | string | Entity | object, other?: any, options?: HttpOptions): Observable<T>;
    public fetch(pathIds: any, other?: any, options?: HttpOptions): Observable<T>
    {
        const key: string = CachedRestEntityService.keyFromPathIds(pathIds);
        return this.get(pathIds, other, options).pipe(
            map((entity: T) =>
            {
                if (this.cache.has(key)) {
                    const cachedEntity = this.cache.get(key);
                    Object.assign(cachedEntity, entity);
                    return cachedEntity as T;
                }
                else {
                    this.cache.add(key, entity);
                    return entity;
                }
            }),
        );
    }

    /**
     * First, tries to retrieve from cache, the object with the id, or id field from the pathIds.
     * If found, return the item from cache, otherwise make a get request to the end point,
     * using the supplied parameters to determine path. Caches the returned object
     *
     * @param pathIds Either the id, if a number and maps simple to ':id', otherwise an object
     *                with keys the match the placeholders within the endpointFormat string.
     * @param other   Any other data that is needed to be passed to the creation of entities
     *                resulting from this get request.
     * @param options Optional http options
     */
    public override get(pathIds: number | string | object, other?: any, options?: HttpOptions): Observable<T>;
    public override get(pathIds: any, other?: any, options?: HttpOptions): Observable<T>
    {
        const key: string = CachedRestEntityService.keyFromPathIds(pathIds);
        if (this.cache.has(key)) {
            return of(this.cache.get(key) as T);
        }
        else {
            return super.get(pathIds, other, options).pipe(tap((entity: T) => this.cache.add(
                entity.key,
                entity,
            )));
        }
    }

    /**
     * Make a create request to the endpoint, using the supplied parameters to determine the path.
     * The results of the request are cached using the key of the entity.
     *
     * @param pathIds An object with keys which match the placeholders within the endpointFormat string.
     * @param data    A FormData or object with the values to pass up in the body of the update/put request.
     * @param other   Any other data needed to be passed to the entity on creation
     * @param options Optional http options
     * @returns a new cold observable with the newly created @type {T}
     */
    public override create(
        pathIds?: object,
        data?: FormData | object,
        other?: any,
        options?: HttpOptions): Observable<T>
    {
        return super.create(pathIds, data, other, options).pipe(tap(entity => this.cache.add(
            entity.key,
            entity,
        )));
    }

    /**
     * Make a delete request to the end point, using the supplied parameters to determine path.
     * If deleted, the object is removed from the cache.
     *
     * @param pathIds Either the id, if a number and maps simple to ':id', otherwise an object
     *                with keys the match the placeholders within the endpointFormat string.
     * @param options Optional http options
     */
    public override delete(pathIds: number | object, options?: HttpOptions): Observable<object>;
    public override delete(pathIds: any, options?: HttpOptions): Observable<object>
    {
        const key: string = CachedRestEntityService.keyFromPathIds(pathIds);

        const cache = this.cache;

        return super.delete(pathIds, options).pipe(
            // Tap performs a side effect on Observable, but return it identical to the source.
            tap((response: object) =>
            {
                cache.remove(key);
            }),
        );
    }
}
