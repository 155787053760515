import { EventEmitter } from '@angular/core';
import { InjectionToken } from '@awesome-nodes/injection-factory';
import { EVENT_EMITTER_TOKEN, EventDelegate as Base, IEventEmitter, ObjectModel } from '@awesome-nodes/mvvm/model';
import { EventArgs } from '@awesome-nodes/object';


export class EventDelegate<TSender extends ObjectModel, TEventArgs extends EventArgs = EventArgs>
    extends Base<TSender, TEventArgs>
{
    //noinspection JSUnusedLocalSymbols
    private static eventEmitterInjectionToken = ((): InjectionToken<IEventEmitter> =>
    {
        if (EVENT_EMITTER_TOKEN.scope.providers.length === 0) {
            EVENT_EMITTER_TOKEN.scope.addProvider({
                provide : EVENT_EMITTER_TOKEN,
                useValue: EventEmitter,
            });
        }

        return EVENT_EMITTER_TOKEN;
    })();
}
