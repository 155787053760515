import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { SwanMediaWatcherModule } from '@swan/services/media-watcher/media-watcher.module';
import { SwanTailwindConfigModule } from '@swan/services/tailwind/tailwind.module';
import { SwanUtilsModule } from '@swan/services/utils/utils.module';


@NgModule({
    imports  : [
        SwanMediaWatcherModule,
        SwanTailwindConfigModule,
        SwanUtilsModule
    ],
    providers: [
        {
            // Use the 'fill' appearance on Angular Material form fields by default
            provide : MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'fill'
            }
        }
    ]
})
export class SwanModule
{
    /**
     * Constructor
     */
    constructor(@Optional() @SkipSelf() parentModule?: SwanModule)
    {
        if ( parentModule )
        {
            throw new Error('SwanModule has already been loaded. Import this module in the AppModule only!');
        }
    }
}
