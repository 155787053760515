import { HttpErrorResponse } from '@angular/common/http';
import { Renderer2 } from '@angular/core';
import { Nullable } from 'simplytyped';


interface Script
{
    kind: 'css' | 'javascript';
    url: string;
    loaded: boolean;
}

class ScriptStore
{
    #store: Record<string, Script> = {};

    public get keys(): Array<string>
    {
        return Object.keys(this.#store);
    }

    public add(kind: 'css' | 'javascript', name: string, url: string): Script
    {
        return this.#store[name] = {
            kind,
            url,
            loaded: false,
        };
    }

    public contains(name: string): boolean
    {
        return this.#store.hasOwnProperty(name);
    }

    public get(playerName: string): Script
    {
        return this.#store[playerName];
    }
}

export interface ScriptLoadingStatus
{
    script: string;
    loaded: boolean;
    error?: HttpErrorResponse;
}

export class LoadScriptService
{
    readonly #scriptStore = new ScriptStore();

    public get scriptStore(): ScriptStore
    {
        return this.#scriptStore;
    }

    public async loadScripts(renderer: Renderer2): Promise<Array<ScriptLoadingStatus>>
    {
        const statuses = new Array<ScriptLoadingStatus>();
        for (const key of this.#scriptStore.keys) {
            statuses.push(await this.loadScript(key, renderer));
        }
        return statuses;
    }

    public loadScript(name: string, renderer: Renderer2): Promise<ScriptLoadingStatus>
    {
        return new Promise((resolve, reject) =>
        {
            const scriptStoreElement = this.#scriptStore.get(name);

            if (scriptStoreElement.loaded) {
                resolve({ script: name, loaded: true });
            }
            else {
                const script = document.createElement('script') as HTMLScriptElement & {
                    readyState: string;
                    onreadystatechange: Nullable<() => void>;
                };
                script.type  = `text/${scriptStoreElement.kind}`;
                script.src   = scriptStoreElement.url;

                if (script.readyState) { //IE Fallback
                    script.onreadystatechange = (): void =>
                    {
                        if (script.readyState === 'loaded' || script.readyState === 'complete') {
                            script.onreadystatechange = null;
                            scriptStoreElement.loaded = true;
                            resolve({ script: name, loaded: true });
                        }
                    };
                }
                else { // Default
                    script.onload = (): void =>
                    {
                        scriptStoreElement.loaded = true;
                        resolve({ script: name, loaded: true });
                    };
                }
                script.onerror = (error: any): void => resolve({ script: name, loaded: false, error });

                renderer.appendChild(document.head, script);
            }
        });
    }
}
