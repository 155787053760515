import { ObjectBase } from '@awesome-nodes/object/data';
import { Address as IAddress, GeoLocation } from '@yukawa/chain-base-angular-domain';


export class Address extends ObjectBase implements IAddress
{
    private _addressId: string;
    private _city: string;
    private _countryCode: string;
    private _geoLocation: GeoLocation;
    private _houseNumber: string;
    private _region: string;
    private _state: string;
    private _street: string;
    private _type: string;
    private _zipCode: string;

    public constructor(address: IAddress)
    {
        super();

        this._addressId   = address.addressId as string;
        this._city        = address.city as string;
        this._countryCode = address.countryCode as string;
        this._geoLocation = address.geoLocation as GeoLocation;
        this._houseNumber = address.houseNumber as string;
        this._region      = address.region as string;
        this._state       = address.state as string;
        this._street      = address.street as string;
        this._type        = address.type as string;
        this._zipCode     = address.zipCode as string;
    }

    //region Public Properties

    public get addressId(): string
    {
        return this._addressId;
    }

    public set addressId(value: string)
    {
        this._addressId = value;
    }

    public get city(): string
    {
        return this._city;
    }

    public set city(value: string)
    {
        this._city = value;
    }

    public get countryCode(): string
    {
        return this._countryCode;
    }

    public set countryCode(value: string)
    {
        this._countryCode = value;
    }

    public get geoLocation(): GeoLocation
    {
        return this._geoLocation;
    }

    public set geoLocation(value: GeoLocation)
    {
        this._geoLocation = value;
    }

    public get houseNumber(): string
    {
        return this._houseNumber;
    }

    public set houseNumber(value: string)
    {
        this._houseNumber = value;
    }

    public get region(): string
    {
        return this._region;
    }

    public set region(value: string)
    {
        this._region = value;
    }

    public get state(): string
    {
        return this._state;
    }

    public set state(value: string)
    {
        this._state = value;
    }

    public get street(): string
    {
        return this._street;
    }

    public set street(value: string)
    {
        this._street = value;
    }

    public get type(): string
    {
        return this._type;
    }

    public set type(value: string)
    {
        this._type = value;
    }

    public get zipCode(): string
    {
        return this._zipCode;
    }

    public set zipCode(value: string)
    {
        this._zipCode = value;
    }

    //endregion

    public override toJSON<T = this>(): IAddress
    {
        return super.toJSON(
            'addressId',
            'city',
            'countryCode',
            'geoLocation',
            'houseNumber',
            'region',
            'state',
            'street',
            'type',
            'zipCode',
        );
    }
}
