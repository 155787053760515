import { Injector } from '@angular/core';
import { ContentFilter, ContentStatus, ContentStreamType } from '@swan/lib/domain';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { lastValueFrom } from 'rxjs';
import { ContentVideoFile } from './content-video-file.model';
import { ContentViewService } from './content-view.service';
import { Content } from './content.model';
import { ContentService } from './content.service';
import { FavoriteVideoList } from './favorite-video-list.model';
import { VideoList } from './video-list.model';


export class ContentVideoList<TFile extends ContentVideoFile>
    extends VideoList<Content, ContentFilter, TFile>
{
    private static readonly defaultPager = {
        firstResult: 0,
        pageSize   : 10,
    };

    private static readonly defaultFilter: ContentFilter = {
        orderDir: 'DESC',
        pager   : ContentVideoList.defaultPager,
        statuses: [
            ContentStatus.PUBLISHED,
        ],
        stream  : ContentStreamType.TRENDING,
    };

    public constructor(
        injector: Injector,
        favoriteVideoList?: FavoriteVideoList<Content, TFile>,
        filter: ContentFilter = ContentVideoList.defaultFilter,
    )
    {
        super(
            injector,
            {
                ...filter,
                pager: ContentVideoList.defaultPager,
            },
            favoriteVideoList,
        );
    }

    public get streamType(): ContentStreamType
    {
        return this._filter.stream as ContentStreamType;
    }

    public override get favorites(): FavoriteVideoList<Content, TFile>
    {
        return super.favorites as FavoriteVideoList<Content, TFile>;
    }

    public async query(stream: ContentStreamType = ContentStreamType.TRENDING): Promise<QueryResult<TFile>>
    {
        if (this.favorites.items.length === 0) {
            await this.favorites.load();
        }
        if (this.items === this.favorites.items) {
            return this.favorites.queryResult as never;
        }

        return (await lastValueFrom((this.injector.get(ContentViewService)).queryEnriched(this.filter))) as QueryResult<TFile>;
    }

    public override async delete(videoFile: TFile): Promise<void>
    {
        await this.injector.get(ContentService).deleteContent(videoFile.fileInfo);
        this.items.splice(this.items.indexOf(videoFile), 1);
    }
}
