import { IEquatable } from '@awesome-nodes/object';
import { ObjectBase } from '@awesome-nodes/object/data';
import { Change, Owner } from '@yukawa/chain-base-angular-domain';
import { User } from '@yukawa/chain-main-angular-core';
import { GroupContext } from '@yukawa/chain-security-angular-core';
import { Account } from './Account';
import { Person } from './Person';


export class UserInfo extends ObjectBase implements User, IEquatable<UserInfo>
{
    public static readonly empty = new UserInfo(
        {
            account: {
                credentials: {},
                status     : {},
            },
            person : {},
        },
    );

    private _account?: Account;
    private _change?: Change;
    private _created?: Change;
    private _defaultOrgId?: string;
    private _details: { [p: string]: object };
    private _groupContexts: GroupContext[];
    private _orgIds: string[];
    private _owner?: Owner;
    private _person: Person;

    private _username: string;

    public constructor(user: User)
    {
        super(user.username);

        this._account       = !user.account ? undefined : new Account(user.account as Account);
        this._change        = user.change;
        this._created       = user.created;
        this._defaultOrgId  = user.defaultOrgId;
        this._details       = user.details ?? {};
        this._groupContexts = user.groupContexts ?? [];
        this._orgIds        = user.orgIds || [];
        this._owner         = user.owner as Owner;
        this._person        = new Person(user.person as Person);
        this._username      = user.username as string;
    }

    //region Public Properties

    public get account(): Account | undefined
    {
        return this._account;
    }

    public set account(value: Account | undefined)
    {
        this._account = value;
    }

    public get change(): Change | undefined
    {
        return this._change;
    }

    public set change(value: Change | undefined)
    {
        this._change = value;
    }

    public get created(): Change | undefined
    {
        return this._created;
    }

    public set created(value: Change | undefined)
    {
        this._created = value;
    }

    public get defaultOrgId(): string | undefined
    {
        return this._defaultOrgId;
    }

    public set defaultOrgId(value: string | undefined)
    {
        this._defaultOrgId = value;
    }

    public get details(): { [p: string]: object }
    {
        return this._details;
    }

    public set details(value: { [p: string]: object })
    {
        this._details = value;
    }

    public get groupContexts(): GroupContext[]
    {
        return this._groupContexts;
    }

    public set groupContexts(value: GroupContext[])
    {
        this._groupContexts = value;
    }

    public get orgIds(): string[]
    {
        return this._orgIds;
    }

    public set orgIds(value: string[])
    {
        this._orgIds = value;
    }

    public get owner(): Owner | undefined
    {
        return this._owner;
    }

    public set owner(value: Owner | undefined)
    {
        this._owner = value;
    }

    public get person(): Person
    {
        return this._person;
    }

    public set person(value: Person)
    {
        this._person = value;
    }

    public get username(): string
    {
        return this._username;
    }

    public set username(value: string)
    {
        this._username = value;
    }

    //endregion

    public override equals(other: UserInfo): boolean
    {
        return super.equals(other) &&
            this._username === other._username;
    }

    public override toJSON<T = this>(): User
    {
        return super.toJSON(
            'account',
            'change',
            'created',
            'defaultOrgId',
            'details',
            'groupContexts',
            'orgIds',
            'owner',
            'person',
            'username',
        );
    }
}
