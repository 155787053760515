/* eslint-disable no-bitwise */
import { FlagsEnum } from '@awesome-nodes/object';
import { Role } from './Role';


export class Roles extends FlagsEnum
{
    static readonly none = Roles.enum<Roles>(Role.none.value, Role.none.name);

    static readonly user = Roles.enum<Roles>(Role.user.value, Role.user.name);

    static readonly tester = Roles.enum<Roles>(Role.tester.value ^ Role.user.value, Role.tester.name);

    static readonly developer = Roles.enum<Roles>(
        Role.developer.value ^
        Role.tester.value ^
        Role.user.value,
        Role.developer.name,
    );

    static readonly admin = Roles.enum<Roles>(
        Role.admin.value ^
        Role.developer.value ^
        Role.tester.value ^
        Role.user.value,
        Role.admin.name,
    );

    static readonly yukawa = Roles.enum<Roles>(0x20, 'Yukawa');

    static readonly yukawaAdmin = Roles.enum<Roles>(
        Roles.yukawa.value ^ Roles.admin.value,
        Roles.yukawa.name + Roles.admin.name,
    );

    static readonly yukawaDeveloper = Roles.enum<Roles>(
        Roles.yukawa.value ^ Roles.developer.value,
        Roles.yukawa.name + Roles.developer.name,
    );

    static readonly yukawaTester = Roles.enum<Roles>(
        Roles.yukawa.value ^ Roles.tester.value,
        Roles.yukawa.name + Roles.tester.name,
    );

    static readonly yukawaUser = Roles.enum<Roles>(
        Roles.yukawa.value ^ Roles.user.value,
        Roles.yukawa.name + Roles.user.name,
    );

    static readonly swan = Roles.enum<Roles>(0x40, 'Swan');

    static readonly swanAdmin = Roles.enum<Roles>(
        Roles.swan.value ^ Roles.admin.value,
        Roles.swan.name + Roles.admin.name,
    );

    static readonly swanDeveloper = Roles.enum<Roles>(
        Roles.swan.value ^ Roles.developer.value,
        Roles.swan.name + Roles.developer.name,
    );

    static readonly swanTester = Roles.enum<Roles>(
        Roles.swan.value ^ Roles.tester.value,
        Roles.swan.name + Roles.tester.name,
    );

    static readonly swanUser = Roles.enum<Roles>(
        Roles.swan.value ^ Roles.user.value,
        Roles.swan.name + Roles.user.name,
    );
}
