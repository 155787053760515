import { EventEmitter, Injector } from '@angular/core';
import { TemplateString, TemplateStringValues } from '@awesome-nodes/object';
import { APP_ENVIRONMENT_TOKEN } from '@swan/lib/config';
import { Entity, updateList } from '@swan/lib/content/utils';
import { Profile } from '@swan/lib/domain';
import { InterestInfo } from '@swan/lib/profile';
import { AppInjector } from '@swan/lib/shared';
import { Exception, IEquatable } from '@swan/mvvm/model';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { EntityFilter } from '@yukawa/chain-base-angular-domain/chain/base/core/entity';
import { Info } from '@yukawa/chain-base-angular-domain/chain/base/core/info';
import { lastValueFrom } from 'rxjs';
import { Nullable, PlainObject } from 'simplytyped';
import { LikedService } from './liked.service';


export interface IVideoFile<T>
{
    id: number;
    fileId: string;
    author: Profile;
    info: Info;
    interests: Array<InterestInfo>;
    duration: string;
    fileInfo: T;

    favorite: boolean;
    liked: boolean;
}

export abstract class VideoFile<T, TView = T> extends Entity<T, TView> implements IVideoFile<T>, IEquatable<IVideoFile<T>>
{
    abstract readonly id: number;
    abstract readonly fileId: string;
    abstract readonly author: Profile;
    abstract readonly info: Info;
    abstract readonly duration: string;
    abstract readonly interests: Array<InterestInfo>;
    abstract readonly fileInfo: T;

    abstract favorite: boolean;
    abstract liked: boolean;

    public equals(other: VideoFile<T, TView>): boolean
    {
        return other == null
            ? false
            : this.id === other.id;
    }
}

export abstract class VideoList<TInfo extends PlainObject = PlainObject,
    TFilter extends EntityFilter = EntityFilter,
    TFile extends IVideoFile<TInfo> = IVideoFile<TInfo>>
{
    protected readonly injector: Injector;
    protected _filter: TFilter;
    private _queryResult: Nullable<QueryResult<TFile>> = null;
    private readonly _items                            = new Array<TFile>();
    private readonly _favorites?: Nullable<VideoList<TInfo, TFilter, TFile>>;
    private _loading                                   = false;
    private readonly _loaded                           = new EventEmitter<never>();

    protected constructor(
        injector: Injector,
        filter: TFilter = {} as TFilter,
        favorites?: VideoList<TInfo, TFilter, TFile>,
    )
    {
        this.injector   = injector;
        this._filter    = filter;
        this._favorites = favorites;
    }

    public get items(): TFile[]
    {
        return this._items;
    }

    public get favorites(): Nullable<VideoList<TInfo, TFilter, TFile>>
    {
        return this._favorites;
    }

    public get queryResult(): Nullable<QueryResult<TFile>>
    {
        return this._queryResult;
    }

    public get isLoading(): boolean
    {
        return this._loading;
    }

    public get isLoadingNextPage(): boolean
    {
        return this._loading && this._filter.pager?.firstResult === this.items.length;
    }

    public get loaded(): EventEmitter<never>
    {
        return this._loaded;
    }

    public get filter(): TFilter
    {
        return this._filter;
    }

    public async load(): Promise<void>
    {
        if (this._loading) {
            const videoListException = new VideoListException('Video list is already loading.');
            if (AppInjector.get(APP_ENVIRONMENT_TOKEN).production === null) {
                throw videoListException;
            }
            else {
                console.warn(videoListException);
                return;
            }
        }

        this._loading = true;
        try {
            this._queryResult = await this.query();

            let files = this.map
                ? this._queryResult.items.map(this.map.bind(this))
                : this._queryResult.items;

            if (this.filter.pager?.firstResult) {
                files = this.items.concat(...files);
            }

            updateList(
                this.items,
                files,
            );

            this._loaded.emit();
        }
        finally {
            this._loading = false;
        }
    }

    public loadNextPage(): Promise<void>
    {
        if (this.isLoadingNextPage) {
            throw new VideoListException('Next video list page is already loading.');
        }

        this._filter = {
            ...this.filter,
            ...{
                pager: {
                    ...this.filter.pager,
                    firstResult: this.items.length,
                },
            },
        };

        return this.load();
    }

    public async toggleFavorite(file: TFile): Promise<void>
    {
        if (this.favorites == null) {
            return;
        }

        if (!file.favorite) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            this.favorites.add && await this.favorites.add(file);
        }
        else {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            this.favorites.remove && await this.favorites.remove(file);
        }
        file.favorite = !file.favorite;
    }

    public async toggleLike(file: TFile): Promise<void>
    {
        if (!file.liked) {
            await lastValueFrom(this.injector.get(LikedService).add(file.id));
            file.fileInfo['stats'].likes++;
        }
        else {
            await lastValueFrom(this.injector.get(LikedService).remove(file.id));
            file.fileInfo['stats'].likes--;
        }
        file.liked = !file.liked;
    }

    public reset(): void
    {
        this.items.length = 0;
        if (this._filter.pager) {
            this._filter.pager.firstResult = 0;
        }
    }

    public isEmpty(): boolean
    {
        return this._items.length === 0;
    }

    add?(file: TFile): Promise<void>;

    reload?(file: TFile): Promise<boolean>;

    remove?(file: TFile): Promise<void>;

    delete?(file: TFile): Promise<void>;

    protected map?(fileInfo: IVideoFile<TInfo>, index: number, array: Array<IVideoFile<TInfo>>): TFile;

    protected abstract query(): Promise<QueryResult<TFile>>;
}

export class VideoListException extends Exception
{
    public constructor(
        message: TemplateString,
        messageTemplateValues?: TemplateStringValues,
        innerException?: Error | undefined)
    {
        super(message, messageTemplateValues, innerException);

        VideoListException.setPrototype(this);
    }

}

