import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Nullable } from 'simplytyped';
import { Credentials, Roles, User, UserInfo } from '../../model/user';
import { SessionStoreService } from '../SessionStoreService';
import { UserService } from '../user';
import { AuthServiceBase } from './AuthServiceBase';
import { IAuthDetails, IAuthRefreshDetails, IAuthService, IAuthTokenResponse, IWebTokenPayload } from './IAuthService';


@Injectable({
    providedIn: 'root',
})
export class AuthDesignService extends AuthServiceBase implements IAuthService
{
    public constructor(
        _userInfoService: UserService,
        private _injector: Injector,
    )
    {
        super(_userInfoService, null, null);
    }

    private static generateAccessToken(username: string): IAuthTokenResponse
    {
        const date = new Date();

        const lifetimeInDays = 7;
        const issuer         = {
            sub: 'app@yukawa.de',
            iss: 'https://swan.design.cloud.yukawa.de',
            iat: Math.floor(date.getTime() / 1000),
            exp: Math.floor((date.setDate(date.getDate() + lifetimeInDays)) / 1000),
        };

        let payload: Nullable<IWebTokenPayload>;
        let roleType: Roles;
        if (username.indexOf('admin@yukawa') !== -1) {
            roleType = Roles.yukawaAdmin;
        }
        else if (username.indexOf('@yukawa') !== -1) {
            roleType = Roles.yukawaUser;
        }
        else if (username.indexOf('admin@swan') !== -1) {
            roleType = Roles.swanAdmin;
        }
        else if (username.indexOf('@swan') !== -1) {
            roleType = Roles.swanUser;
        }
        else {
            roleType = Roles.user;
        }

        const adminProfile: IAuthDetails = {
            groups         : [
                'ADMINS',
            ],
            groupContexts  : [
                {
                    orgId : 'swan',
                    groups: [
                        'ADMINS',
                    ],
                },
            ],
            defaultOrgId   : 'swan',
            orgId          : 'swan',
            organisationIds: [
                'swan',
            ],
        };

        const userDetails: IAuthRefreshDetails = {
            groupContexts  : [
                {
                    orgId : 'swan',
                    groups: [
                        'ADMINS',
                    ],
                },
            ],
            orgId          : 'swan',
            organisationIds: [
                'swan',
            ],
        };

        switch (roleType) {
            case Roles.yukawaAdmin:
            case Roles.swanAdmin:
                payload = {
                    ...issuer,
                    scope  : ['ROLE_ADMIN'],
                    details: adminProfile,
                };
                break;
            case Roles.yukawaUser:
            case Roles.swanUser:
                payload = {
                    ...issuer,
                    scope  : ['ROLE_USER'],
                    details: userDetails,
                };
                break;
            case Roles.none:
            default:
                payload = {
                    ...issuer,
                    scope  : ['ROLE_NONE'],
                    details: userDetails,
                };
                break;
        }

        const accessToken = `eyJhbGciOiJIUzI1NiJ9.${
            btoa(JSON.stringify(payload))
        }.OBZXPVXHPmbgv1syqhewVXtXA7FnYByTMvMH4DZ_hpE`;

        return {
            access_token   : accessToken,
            refresh_token  : accessToken,
            scope          : payload.scope,
            details        : payload.details,
            token_type     : 'Bearer',
            expires_in     : 60 * 60 * 24 * lifetimeInDays,
            access_expires : new Date(date.getTime() + 60 * 60 * 24 * 1000).toISOString(), // + 1 day
            refresh_expires: new Date(date.getTime() + 60 * 60 * 24 * lifetimeInDays * 1000).toISOString(), // + 7 days
            username       : username,
        };
    }

    public login(credentials: Credentials): Observable<User>
    {
        const response = AuthDesignService.generateAccessToken(credentials.username);

        return this.createWorker('login', of(response));
    }

    public override refresh(sessionToken: IAuthTokenResponse): Observable<IAuthTokenResponse>
    {
        const userInfo = JSON.parse(this._injector.get(SessionStoreService).get('user') as string) as UserInfo;

        return of(AuthDesignService.generateAccessToken(userInfo.username));
    }
}
