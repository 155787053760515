import { Injectable } from '@angular/core';
import { TemplateString, TemplateStringValues } from '@awesome-nodes/object';
import { Upload } from '@mux/mux-node/types/interfaces/Upload';
import { TranslocoService } from '@ngneat/transloco';
import { Content, ContentService, ContentViewService, UploadContent } from '@swan/lib/content';
import { MuxUpload, MuxUploadStatus } from '@swan/lib/content/mux';
import { removeEmpty } from '@swan/lib/content/utils';
import { SwanVideoFile } from '@swan/lib/content/video';
import { AndroidPermissions, CordovaService } from '@swan/lib/cordova';
import { Content as IContent, ContentInterest, Profile } from '@swan/lib/domain';
import { ImageService, InterestInfo, InterestInfoService, ProfileService } from '@swan/lib/profile';
import { Exception } from '@swan/mvvm/model';
import { Info } from '@yukawa/chain-base-angular-domain/chain/base/core/info';
import { cloneDeep } from 'lodash-es';
import { lastValueFrom, map } from 'rxjs';
import { Nullable } from 'simplytyped';
import { UAParser } from 'ua-parser-js';


interface IVideoContent extends IContent
{
    contentId?: number;
    fileName?: string;
    info: Info;
    interests: Array<ContentInterest>;
}

export type VideoSavedResult = MuxUpload | boolean;

@Injectable({ providedIn: 'root' })
export class VideoRecordService
{
    private static emptyContent: IVideoContent = {
        interests: [],
        info     : {},
    };

    public readonly interests     = new Array<InterestInfo>();
    public content: IVideoContent = cloneDeep(VideoRecordService.emptyContent);
    public image: Nullable<File>;

    private _file: Nullable<File>;
    private _muxUpload: Nullable<MuxUpload>;

    public constructor(
        private readonly _contentService: ContentService,
        private readonly _contentViewService: ContentViewService,
        private readonly _cordovaService: CordovaService,
        private readonly _imageService: ImageService,
        private readonly _interestInfoService: InterestInfoService,
        private readonly _profileService: ProfileService,
        private readonly _translocoService: TranslocoService,
    )
    {
    }

    public get cordovaService(): CordovaService
    {
        return this._cordovaService;
    }

    public get selectedInterests(): Array<InterestInfo>
    {
        return this.interests.reduce((previous, next) =>
        {
            if (next.selected) {
                previous.push(next);
            }
            return previous;
        }, new Array<InterestInfo>());
    }

    public get muxUpload(): Nullable<MuxUpload>
    {
        return this._muxUpload;
    }

    public get file(): Nullable<File>
    {
        return this._file;
    }

    /**
     * @throws VideoRecordServiceException Thrown if the file size was too large for mobile.
     * @param value
     */
    public set file(value: Nullable<File>)
    {
        this._file = value;

        if ((value?.size ?? 0) > 1024 * 1024 * 1024) {
            const parser = new UAParser(navigator.userAgent);
            const os     = parser.getOS();
            switch (os.name) {
                case 'Android':
                case 'iOS':
                    throw new VideoRecordServiceException('Video file to large for mobile uploads.');
                default:
                    break;
            }
        }
    }

    public async init(videoFile?: SwanVideoFile): Promise<boolean>
    {
        this.interests.length = 0;
        if (videoFile) {
            this.interests.push(...videoFile.interests.map((interest) =>
            {
                interest.selected = true;
                return interest;
            }));
        }
        this.content = videoFile?.fileInfo as Content || cloneDeep(VideoRecordService.emptyContent);
        this._file   = null;
        this.image   = null;

        // Cancel if video file provided (update only, no record)
        if (videoFile || !this._cordovaService.onCordova) {
            return true;
        }

        try {
            if (window.Capacitor == null) {
                await this._cordovaService.requestPermissions(
                    AndroidPermissions.CAMERA,
                    AndroidPermissions.RECORD_AUDIO,
                );
            }
            return true;
        }
        catch (error) {
            console.error(error);
        }

        return false;
    }

    public async captureCamera(): Promise<Nullable<MediaStream>>
    {
        try {
            // TODO: audio doesnt work entirely (desktop, mobile) :(
            return await navigator.mediaDevices.getUserMedia({ /*audio: true,*/ video: true });
        }
        catch (error) {
            console.warn('Unable to capture your camera.');
            console.error(error);
        }

        return null;
    }

    public async openPhotoLibrary(): Promise<boolean>
    {
        if (!this._cordovaService.onCordova) {
            return true;
        }

        try {
            if (window.Capacitor == null) {
                await this._cordovaService.requestPermissions(AndroidPermissions.READ_EXTERNAL_STORAGE);
            }
            return true;
        }
        catch (error) {
            console.error(error);
        }

        return false;
    }

    /**
     * Loads all interests below the second tree level flattened into the {@link interests} array of this instance.
     */
    public async loadInterests(): Promise<void>
    {
        const selectedInterests = this.interests.filter(interest => interest.selected);
        this.interests.length   = 0;

        const loadInterests = (interests: Array<InterestInfo>): void =>
        {
            for (const _interest of interests) {
                _interest.selected = false;
                this.interests.push(..._interest.interests);
                loadInterests(_interest.interests);
            }
        };

        for (const _interest of await this._interestInfoService.loadInterests()) {
            loadInterests(_interest.interests);
        }

        selectedInterests.forEach((interest) =>
        {
            const interestInfo = this.interests.find(_interest => _interest.id === interest.id);
            if (interestInfo) {
                interestInfo.selected = interest.selected;
            }
        });
    }

    public async saveFile(): Promise<VideoSavedResult>
    {
        if (!this.content.contentId) {
            // Create content entity
            this.content = await lastValueFrom(await this._contentService.create({
                ...this.content,
                author       : {
                    username: this._profileService.profile?.username,
                } as Profile,
                fileName     : this._file?.name,
                language     : this._translocoService.getActiveLang(),
                recordingDate: new Date(),
            } as Content));
        }
        if (!this.content.recordingDate) {
            this.content.recordingDate = this.content.created?.date;
        }

        // Add content interests
        this.content.interests.length = 0;
        this.content.interests.push(...this.selectedInterests.map(interest => ({
            contentId : this.content.contentId,
            interestId: interest.id,
        })));

        let imageUrl: string | undefined;
        if (this.image) {
            if (this.content.contentId) {
                await this._contentService.deleteImage(this.content as Content);
            }
            imageUrl = await this._contentService.uploadImage(this.content as Content, this.image as File);
        }

        // Update content interests
        const newContent: IVideoContent = await lastValueFrom(this._contentService.merge(removeEmpty({
            contentId   : this.content.contentId,
            info        : this.content.info,
            interests   : this.content.interests,
            splashScreen: imageUrl,
            thumbnail   : imageUrl,
        } as Content)));

        if (!this.content.contentId) {
            this.content = newContent;
        }

        if (this._file == null) {
            return true;
        }

        // Create upload token, save and return upload instance.
        this._muxUpload = await lastValueFrom(this._contentService.createUploadToken({
            contentId: this.content.contentId,
            fileName : this.content.fileName,
        } as UploadContent).pipe(
            map((_upload: { data: Upload }) => new MuxUpload(_upload.data, this._file as File)),
        ));

        this._muxUpload.uploadProgress$.subscribe(
            (progress: MuxUploadStatus) =>
            {
                switch (progress.event) {
                    case 'completed':
                    case 'error':
                        this._muxUpload = null;
                        break;
                }
            });
        return this._muxUpload;
    }

    public async cancelUpload(): Promise<void>
    {
        if (this._muxUpload != null) {
            this._muxUpload.abort();
            this._muxUpload = null;
        }

        if (this.content.contentId != null && this.content.contentId > 0) {
            await this._contentService.deleteImage(this.content as Content);
            await lastValueFrom(this._contentService.deleteByKey(this.content.contentId));
            this.content = cloneDeep(VideoRecordService.emptyContent);
        }
    }
}

export class VideoRecordServiceException extends Exception
{
    public constructor(
        message: TemplateString,
        messageTemplateValues?: TemplateStringValues,
        innerException?: Error | undefined)
    {
        super(message, messageTemplateValues, innerException);

        VideoRecordServiceException.setPrototype(this);
    }
}
