import {
    Attribute,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    OnDestroy,
    OnInit,
    Optional,
    ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ConfirmDialogData, SwanConfirmDialogComponent } from '@swan/lib/components';
import { MuxUpload, MuxUploadProgress, MuxUploadStatus } from '@swan/lib/content/mux';
import { SwanVideoFile } from '@swan/lib/content/video';
import { CordovaService, StatusBar } from '@swan/lib/cordova';
import { ImageService, InterestInfo, InterestInfoService } from '@swan/lib/profile';
import { AppInjector, EmojiService, SwanAlertService } from '@swan/lib/shared';
import { Nullable } from 'simplytyped';
import { VideoRecordService, VideoSavedResult } from '../video-record.service';
import { VideoTagsComponent } from './tags/video-tags.component';


export interface IVideoDetailsDialogData
{
    videoFile: SwanVideoFile;
}

@Component({
    selector   : 'app-video-details',
    templateUrl: './video-details.component.html',
    styleUrls  : ['./video-details.component.scss'],
})
export class VideoDetailsComponent implements OnInit, OnDestroy
{
    @ViewChild('titleInput')
    titleInput!: ElementRef<HTMLTextAreaElement>;

    @ViewChild('descriptionInput')
    descriptionInput!: ElementRef<HTMLTextAreaElement>;

    public image: Nullable<string> = null;
    public detailsForm!: FormGroup;
    private _uploadProgress!: MuxUploadProgress;

    constructor(
        @Attribute('class') private readonly _classNames: string,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _router: Router,
        private readonly _formBuilder: FormBuilder,
        private readonly _dialog: MatDialog,
        private readonly _imageService: ImageService,
        private readonly _interestInfoService: InterestInfoService,
        private readonly _videoRecordService: VideoRecordService,
        private readonly _swanAlertService: SwanAlertService,
        private readonly _translocoService: TranslocoService,
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _emojiService: EmojiService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: IVideoDetailsDialogData,
        @Optional() private readonly _dialogRef: MatDialogRef<VideoDetailsComponent>,
    )
    {
    }

    public get isDialog(): boolean
    {
        return this._dialogRef != null;
    }

    get statusBarPadding(): boolean
    {
        return AppInjector.get(CordovaService).onCordova && (AppInjector.get(StatusBar).overlays ?? false);
    }

    get tags(): FormArray
    {
        return this.detailsForm.get('tags') as FormArray;
    }

    get isValid(): boolean
    {
        return this.detailsForm && this.detailsForm.valid && this.tags.length > 0;
    }

    get uploadStatus(): Nullable<MuxUpload>
    {
        return this._videoRecordService.muxUpload;
    }

    get uploadActive(): boolean
    {
        return this._videoRecordService.muxUpload != null;
    }

    public get uploadProgress(): MuxUploadProgress
    {
        return this._uploadProgress;
    }

    public get videoFile(): Nullable<SwanVideoFile>
    {
        return this.data.videoFile;
    }

    async ngOnInit(): Promise<void>
    {
        if (this._videoRecordService.interests.length === 0) {
            await this._videoRecordService.loadInterests();
        }

        this.detailsForm = this._formBuilder.group({
            title      : [this._emojiService.colonsToNative(this._videoRecordService.content.info?.name || ''), Validators.required],
            description: [this._emojiService.colonsToNative(this._videoRecordService.content.info?.desc || ''), Validators.required],
            tags       : this._formBuilder.array(this._videoRecordService.selectedInterests),
        });

        if (this._videoRecordService.image) {
            this.setImage(this._videoRecordService.image);
        }
        else if (this.data) {
            this.image = this.data.videoFile.fileInfo.splashScreen;
        }

        this._changeDetector.detectChanges();

        this._emojiService.transformColonToNative(
            this.detailsForm.get('title') as FormControl,
            this.titleInput.nativeElement,
        ).subscribe(value => this._videoRecordService.content.info.name = value);

        this._emojiService.transformColonToNative(
            this.detailsForm.get('description') as FormControl,
            this.descriptionInput.nativeElement,
        ).subscribe(value => this._videoRecordService.content.info.desc = value);
    }

    public ngOnDestroy(): void
    {
        this._uploadProgress?.dispose();
    }

    imageChanged(fileList: HTMLInputElement): void
    {
        // Skip if canceled
        if (!fileList.value.length) {
            return;
        }

        const imageFile = (fileList.files as FileList)[0];

        this._videoRecordService.image = imageFile;
        this.setImage(imageFile);

        fileList.value = '';
    }

    setImage(imageFile: File): void
    {
        this._imageService.readAsDataURL(imageFile).then((data: string) =>
        {
            // Update the image
            this.image = data;
        });
    }

    removeTag(interest: InterestInfo): void
    {
        interest.selected = false;
        const interests   = this.tags.value;
        const index       = interests.indexOf(interest);

        if (index >= 0) {
            interests.splice(index, 1);
        }
    }

    public async tagListClick(): Promise<void>
    {
        if (this._dialogRef != null) {
            this._dialog.open(VideoTagsComponent, {
                autoFocus : false,
                width     : '100%',
                maxWidth  : '100vw',
                height    : '100vh',
                panelClass: 'video-tags',
            }).afterClosed().subscribe(() =>
            {
                const interestInfos  = this.tags.value as Array<InterestInfo>;
                interestInfos.length = 0;
                interestInfos.push(...this._videoRecordService.selectedInterests);
            });
        }
        else {
            await this._router.navigate(['tags'], { relativeTo: this._activatedRoute });
        }
    }

    public async saveVideo(): Promise<void>
    {
        this.detailsForm.disable();
        let result: VideoSavedResult;

        try {
            this._videoRecordService.content.info.name = this._emojiService.nativeEmojiToColons(this._videoRecordService.content.info.name?.trim() || '');
            this._videoRecordService.content.info.desc = this._emojiService.nativeEmojiToColons(this._videoRecordService.content.info.desc?.trim() || '');
            result                                     = await this._videoRecordService.saveFile();
        }
        catch (error) {
            console.error(error);

            this.detailsForm.enable();
            return;
        }
        if (result instanceof MuxUpload) {
            this._uploadProgress = new MuxUploadProgress(result, this.onUploadStatus.bind(this));
        }
        else if (this._dialogRef) {
            if (result) {
                this._swanAlertService.show('global', {
                    type      : 'success',
                    appearance: 'soft',
                    message   : this._translocoService.translate('VIDEO.EDITED'),
                });
            }
            this._dialogRef.close(result);
        }
    }

    async onUploadStatus(status: MuxUploadStatus): Promise<void>
    {
        switch (status.event) {
            case 'error':
                this._dialog.closeAll();
                console.error(status.message);
                await this._videoRecordService.cancelUpload();
                break;
            case 'completed':
                this._dialog.closeAll();
                this._swanAlertService.show('global', {
                    type      : 'success',
                    appearance: 'soft',
                    message   : this._translocoService.translate('VIDEO.RECORD.SAVED'),
                });
                if (this._dialogRef) {
                    this._dialogRef.close(true);
                    return;
                }
                await this._router.navigate(['/video']);
                break;
        }
        this._changeDetector.detectChanges();
    }

    public async navigateBack(): Promise<void>
    {
        if (this.uploadActive) {
            this._videoRecordService.muxUpload?.pause();
            this._dialog.open(SwanConfirmDialogComponent, {
                data: {
                    title         : 'VIDEO.RECORD.UPLOAD.CANCEL.TITLE',
                    confirmMessage: 'VIDEO.RECORD.UPLOAD.CANCEL.MESSAGE',
                    yesButton     : 'BUTTON.CANCEL',
                    noButton      : 'BUTTON.CONTINUE',
                } as ConfirmDialogData,
            }).afterClosed().subscribe(async (result: boolean) =>
            {
                if (result) {
                    await this._videoRecordService.cancelUpload();
                    this._swanAlertService.show('global', {
                        type      : 'info',
                        appearance: 'soft',
                        message   : this._translocoService.translate('VIDEO.RECORD.UPLOAD.CANCELED'),
                    });
                    this.navigateBack();
                    return;
                }
                this._videoRecordService.muxUpload?.resume();
            });
            return;
        }
        else if (this._dialogRef) {
            this._dialogRef.close(false);
            return;
        }
        else {
            await this._videoRecordService.cancelUpload();
        }
        await this._router.navigate(['..'], { relativeTo: this._activatedRoute });
    }
}
