import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Upload } from '@mux/mux-node/types/interfaces/Upload';
import { ContentFilter, DownloadSize } from '@swan/lib/domain';
import { Image, ImageService } from '@swan/lib/profile';
import { ConfigService, RepoAspectRest } from '@yukawa/chain-base-angular-client';
import { Info } from '@yukawa/chain-base-angular-domain/chain/base/core/info';
import moment from 'moment';
import { firstValueFrom, lastValueFrom, Observable } from 'rxjs';
import { Content, UploadContent } from './content.model';


export interface PlaybackToken
{
    storyBoard: string;
    videoSrc: string;
    expiry: string;
}

@Injectable()
export class ContentService extends RepoAspectRest<number, Content, ContentFilter>
{
    constructor(
        http: HttpClient,
        configService: ConfigService,
        private _imageService: ImageService,
    )
    {
        super(http, configService, 'contentUrl');
    }

    playback(content: Content): Observable<PlaybackToken>
    {
        return this.http.get<PlaybackToken>(`${this.formatServiceUrl('/playback')}?key=${content.contentId}`);
    }

    createUploadToken(content: UploadContent): Observable<{ data: Upload }>
    {
        return this.http.post<{ data: Upload }>(this.formatServiceUrl('/upload/token'), content);
    }

    /**
     * Upload image for provided content
     *
     * @param content
     * @param file
     * @return The image url
     */
    public async uploadImage(content: Content, file: File): Promise<string>
    {
        const allowedTypes = ['image/jpeg', 'image/png'];

        // Return if the file is not allowed
        if (!allowedTypes.includes(file.type)) {
            return '';
        }

        const image = new Image({
            category: '',
            related : {
                entity: 'Content',
                id    : String(content.contentId),
                module: 'swan',
            },
        });
        image.info  = content.info as Info;

        await lastValueFrom(this._imageService.upload(image, file));

        return this._imageService.downloadUrl(image, {
            size: DownloadSize.medium,
        });
    }

    /**
     * Delete image of provided content
     *
     * @param content
     */
    public async deleteImage(content: Content): Promise<void>
    {
        await firstValueFrom(this._imageService.deleteAll({
            related: {
                id    : String(content.contentId),
                entity: 'Content',
                module: 'swan',
            },
        }));
    }

    public async deleteContent(content: Content): Promise<Content>
    {
        await this.deleteImage(content);
        return await lastValueFrom(this.deleteByKey(content.contentId));
    }

    public override create(entity: Content): Observable<Content>
    {
        this.applyUtcTimezoneFix(entity);
        return super.create(entity);
    }

    public override edit(entity: Content): Observable<Content>
    {
        this.applyUtcTimezoneFix(entity);
        return super.edit(entity);
    }

    public override merge(entity: Content): Observable<Content>
    {
        this.applyUtcTimezoneFix(entity);
        return super.merge(entity);
    }

    public override put(entity: Content): Observable<Content>
    {
        this.applyUtcTimezoneFix(entity);
        return super.put(entity);
    }

    /**
     * Backend workaround for converting the recording date to a backend safe representation (time zone shift).
     * TODO fix(microservices/content): handle date timezones (utc dates) and remove lib service workaround
     *
     * @param entity
     */
    private applyUtcTimezoneFix(entity: Content): void
    {
        if (entity.recordingDate) {
            const recordingDate  = moment(entity.recordingDate);
            entity.recordingDate = moment({
                    year : recordingDate.year(),
                    month: recordingDate.month(),
                    day  : recordingDate.date(),
                    hour : 12,
                },
            ).toDate();
        }
    }
}
