import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { swanAnimations } from '@swan/lib/animations';
import { VideoBackgroundComponent } from '@swan/lib/components';
import { interval, Subscription } from 'rxjs';
import { Chapter, ChapterListModel, HeadingOverlayContent, TooltipOverlayContent } from './chapter-list.model';


@Component({
    selector   : 'app-intro',
    templateUrl: './video-tutorial.component.html',
    styleUrls  : ['./video-tutorial.component.scss'],
    animations : [swanAnimations],
})
export class VideoTutorialComponent implements OnInit, OnDestroy
{
    @ViewChild('video', { static: true })
    public readonly video!: VideoBackgroundComponent;
    public chapters!: ChapterListModel;

    private _timerInterval!: Subscription;
    private _playbackStarted: boolean = false;

    constructor(
        private readonly _httpClient: HttpClient,
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _router: Router,
        private readonly _translocoService: TranslocoService,
    )
    {
    }

    static get skip(): boolean
    {
        return Boolean(localStorage.getItem('skip-video-tutorial'));
    }

    static set skip(value: boolean)
    {
        if (value) {
            localStorage.setItem('skip-video-tutorial', String(value));
        }
        else {
            localStorage.removeItem('skip-video-tutorial');
        }
    }

    //region Public Properties
    /* eslint-disable @typescript-eslint/member-ordering */

    get videoPosition(): number
    {
        return this.video.videoPosition;
    }

    set videoPosition(value: number)
    {
        this.video.videoPosition = value;
    }

    get dontShowAgain(): boolean
    {
        return VideoTutorialComponent.skip;
    }

    set dontShowAgain(value: boolean)
    {
        VideoTutorialComponent.skip = value;
    }

    get headingOverlayContent(): HeadingOverlayContent
    {
        return this.chapters.currentChapter.overlayContent as HeadingOverlayContent;
    }

    public get tooltips(): Array<Required<Chapter<Required<TooltipOverlayContent>>>>
    {
        return this.chapters.items
            .filter(item => item.overlayContent?.type === 'tooltip' && item.overlayContent != null) as Array<Required<Chapter<Required<TooltipOverlayContent>>>>;
    }

    public get playbackStarted(): boolean
    {
        return this._playbackStarted;
    }

    /* eslint-enable @typescript-eslint/member-ordering */

    //endregion

    async ngOnInit(): Promise<void>
    {
        this.chapters = new ChapterListModel();

        this.chapters.add('tips', 6, {
            type    : 'heading',
            fontSize: 48,
            text    : this._translocoService.translate('VIDEO.RECORD.TUTORIAL.TIPS'),
            position: 'bottom',
            color   : '#FFEFEC',
        });

        this.chapters.add('face centered', 6, {
            type       : 'tooltip',
            toolTipIcon: 'swan:misc-face',
            text       : this._translocoService.translate('VIDEO.RECORD.TUTORIAL.TIP_CENTERED_CAMERA'),
            classes    : '',
            left       : '65%',
            top        : '30%',
            direction  : 'bottom-left',
        });

        this.chapters.add('light conditions', 6, {
            type       : 'tooltip',
            toolTipIcon: 'swan:misc-sun',
            text       : this._translocoService.translate('VIDEO.RECORD.TUTORIAL.TIP_GOOD_LIGHT'),
            classes    : '',
            left       : '65%',
            top        : '15%',
            direction  : 'bottom-left',
        });

        this.chapters.add('talk clearly', 6, {
            type       : 'tooltip',
            toolTipIcon: 'swan:video-actions-volume',
            text       : this._translocoService.translate('VIDEO.RECORD.TUTORIAL.TIP_TALK_CLEARLY'),
            classes    : '',
            left       : '30%',
            top        : '65%',
            direction  : 'top-right',
        });

        this.chapters.add('smile', 6, {
            type       : 'tooltip',
            toolTipIcon: 'swan:misc-swan',
            text       : this._translocoService.translate('VIDEO.RECORD.TUTORIAL.TIP_BE_YOURSELF'),
            classes    : '',
            left       : '30%',
            top        : '30%',
            direction  : 'bottom-right',
        });

        this._timerInterval = interval(100).subscribe({
            next: () =>
            {
                this.chapters.update(this.videoPosition);
            },
        });
    }

    public ngOnDestroy(): void
    {
        this._timerInterval.unsubscribe();
    }

    public toggle(): void
    {
        this.dontShowAgain = !this.dontShowAgain;
    }

    public skipChapter(): void
    {
        this.videoPosition = this.chapters.seekTo(this.chapters.currentChapter, true);
    }

    public async previousChapter(): Promise<void>
    {
        this.videoPosition = this.chapters.seekTo(this.video.paused &&
        this.chapters.currentChapter === this.chapters.lastChapter
            ? this.chapters.currentChapter
            : this.chapters.previousChapter as Chapter);

        if (this.video.paused) {
            await this.video.play();
        }
    }

    public async seekToChapter(_chapter: Chapter): Promise<void>
    {
        const position = this.chapters.seekTo(_chapter);

        if (this.video.paused) {
            await this.video.play();
        }

        if (position === 0 || this.isSeekable(position)) {
            //this.video.nativeElement.pause();
            this.videoPosition = position;
        }
    }

    isSeekable(time: number): boolean
    {
        for (let i = 0; i < this.video.seekable.length; i++) {
            const start = this.video.seekable.start(i);
            const end   = this.video.seekable.end(i);
            if (time > start && time < end) {
                return true;
            }
        }
        return false;
    }

    public close(): void
    {
        this._router.navigate(['/video/record']);
    }

    public async videoPlayed(): Promise<void>
    {
        this._playbackStarted = true;
        await this.seekToChapter(this.chapters.items[0]);
    }
}
