import { enableProdMode, Type } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LogService } from '@swan/lib/shared';
import 'hammerjs';

// noinspection JSUnusedLocalSymbols
const logService = LogService.instance;

export const run = (module: Type<unknown>, production: boolean): void =>
{
    if (production) {
        enableProdMode();
    }

    platformBrowserDynamic().bootstrapModule(module)
        .then(() =>
        {
            document.dispatchEvent(new Event('swan-loaded'));
        })
        .catch((error) =>
        {
            document.dispatchEvent(new Event('swan-loaded'));
            console.error(error);
        });
};
