import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ProfileService } from '@swan/lib/profile';
import { InitialData } from 'app/app.types';
import { forkJoin, from, map, Observable } from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class InitialDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _profileService: ProfileService,
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Use this resolver to resolve initial data for the application
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData>
    {
        // Fork join multiple API endpoint calls to wait all of them to finish
        return forkJoin([
            // TODO: Restore Session
            this._httpClient.get<any>('api/common/navigation'),
            this._httpClient.get<any>('api/common/user'),
            from(this._profileService.init()),
        ]).pipe(
            map(([navigation, user]) => ({
                    navigation: {
                        compact   : navigation.compact,
                        default   : navigation.default,
                        futuristic: navigation.futuristic,
                        horizontal: navigation.horizontal,
                    },
                    user,
                }),
            ),
        );
    }
}
