import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { EntityFilter, QueryResult } from '@yukawa/chain-base-angular-domain';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PlainObject } from 'simplytyped';
import { Entity } from './entity';


export interface HttpOptions
{
    headers?:
        | HttpHeaders
        | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?:
        | HttpParams
        | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
    /**
     * Use the alternate end point format to override the end point used.
     */
    alternateEndpointFormat?: string;
}

/**
 * ResourceService, responsible for the CRUD actions for all resources which inherit form it.
 * This is used to interact with the server, and create Entity objects that are returned for
 * use within the application.
 */
export abstract class RestEntityService<T extends Entity> extends RestAspect
{
    abstract entityName: string;

    /**
     * Provide a string template for the endpoint URLs in the format
     * 'path/to/:id1:/other/:id2:' where ':id1:' and ':id2:' are placeholders for id values
     * passed into the CRUD methods.
     *
     * Use :id for simple cases eg: 'users/:id:'. This can then be shortcut to provide just the
     * value without needing to indicate the key to replace. eg UserService.get(1) instead of
     * UserService.get({id: 1}])
     *
     * @returns The endpoint string format
     */
    protected abstract readonly endpointFormat: string;

    constructor(
        http: HttpClient,
        private _configService: ConfigService,
        serviceUrl?: string,
    )
    {
        super(http, _configService, serviceUrl);
    }

    public get serverKey(): string
    {
        return this.entityName
            .replace(/(.)([A-Z][a-z]+)/, '$1_$2')
            .replace(/([a-z0-9])([A-Z])/, '$1_$2')
            .toLowerCase();
    }

    /**
     * Make a get request to the end point, using the supplied parameters to determine path.
     *
     * @param pathIds Either the id, if a number and maps simple to ':id', otherwise an object
     *                with keys the match the placeholders within the endpointFormat string.
     * @param other   Any other data that is needed to be passed to the creation of entities
     *                resulting from this get request.
     * @param options Optional http options
     */
    public get(pathIds: number | object, other?: any, options?: HttpOptions): Observable<T>;
    public get(pathIds: any, other?: any, options?: HttpOptions): Observable<T>
    {
        const object = { ...pathIds };
        if (typeof pathIds === 'number') {
            object['id'] = pathIds;
        }
        const path = this.buildEndpoint(options?.alternateEndpointFormat || this.endpointFormat, object);

        return this.http.get<T>(path, options).pipe(map(rawData => this.createInstanceFrom(rawData, other))); // Turn
        // the raw JSON returned into the object T
    }

    /**
     * Make a query request (get all) to the end point, using the supplied parameters to determine path.
     *
     * @param pathIds An object with keys which match the placeholders within the endpointFormat string.
     * @param other   Any other data that is needed to be passed to the creation of entities
     *                resulting from this get request.
     * @param options Optional http options
     * @returns a new cold observable
     */
    public override query<E = T>(pathIds?: object, other?: object, options?: HttpOptions): Observable<QueryResult<E>>;
    public override query(url: string, filter?: EntityFilter, options?: HttpOptions): Observable<QueryResult<T>>;
    public override query(
        pathIds?: string | object,
        other?: object | EntityFilter,
        options?: HttpOptions): Observable<QueryResult<T>>
    {
        let observable: Observable<QueryResult<T>>;
        if (typeof pathIds === 'string') {
            observable = super.query(pathIds, other || {}, options);
        }
        else {
            const path = this.buildEndpoint(options?.alternateEndpointFormat || this.endpointFormat, pathIds);
            observable = this.http.post<QueryResult<T>>(path, options);
        }

        return observable.pipe(map((rawData) =>
        {
            rawData.items = this.convertCollection(rawData.items, other);
            return rawData;
        }));
    }

    /**
     * Make an update request to the endpoint, using the supplied object to identify which id to update.
     *
     * @param pathIds
     * @param obj An object with keys which match the placeholders within the endpointFormat string.
     * @param options Optional http options
     */
    public update(pathIds: object | T, obj?: T, options?: HttpOptions): Observable<T>;
    public update(pathIds: any, obj?: T, options?: HttpOptions): Observable<T>
    {
        if (obj === undefined) {
            obj = pathIds as T;
        }
        // need to pass object through as path id and form data
        return this.put<T>(pathIds, obj.toJson(), options).pipe(
            map((rawData) =>
            {
                obj?.updateFromJson(rawData);
                return obj as T;
            }),
        );
    }

    /**
     * Make an put request to the endpoint, indicating the type of data to be returned from the endpoint.
     * The supplied object identifies the endpoint url and data.
     *
     * @typeparam S The type of the data to be returned
     * @param pathIds An object with keys which match the placeholders within the endpointFormat string.
     * @param data    A FormData or object with the values to pass up in the body of the update/put request.
     * @param options Optional http options
     */
    public put<S>(pathIds: object, data?: FormData | object, options?: HttpOptions): Observable<S>;
    public put<S>(pathIds: any, data?: FormData | object, options?: HttpOptions): Observable<S>
    {
        const object = { ...pathIds };
        const json   = data ? data : typeof pathIds.toJson === 'function' ? pathIds.toJson() : pathIds;
        const path   = this.buildEndpoint(options?.alternateEndpointFormat || this.endpointFormat, object);

        return this.http.put(path, json, options) as Observable<S>;
    }

    /**
     * Make a create request to the endpoint, using the supplied parameters to determine the path.
     *
     * @param pathIds An object with keys which match the placeholders within the endpointFormat string.
     * @param data    A FormData or object with the values to pass up in the body of the create request.
     * @param other   Any other data needed to be passed to the entity on creation
     * @param options Optional http options
     * @returns {Observable} a new cold observable with the newly created @type {T}
     */
    public create(pathIds?: object, data?: FormData | object, other?: any, options?: HttpOptions): Observable<T>;
    public create(pathIds?: any, data?: FormData | object, other?: any, options?: HttpOptions): Observable<T>
    {
        const object = { ...pathIds };
        const json   = data ? data : typeof pathIds.toJson === 'function' ? pathIds.toJson() : pathIds;
        const path   = this.buildEndpoint(options?.alternateEndpointFormat || this.endpointFormat, object);
        return this.http.post<T>(path, json, options).pipe(map(rawData => this.createInstanceFrom(
            rawData,
            other,
        )));
    }

    /**
     * Make a delete request to the end point, using the supplied parameters to determine path.
     *
     * @param pathIds Either the id, if a number and maps simple to ':id', otherwise an object
     *                with keys the match the placeholders within the endpointFormat string.
     * @param options Optional http options
     */
    public delete(pathIds: number | object, options?: HttpOptions): Observable<object>;
    public delete(pathIds: any, options?: HttpOptions): Observable<object>
    {
        const object = { ...pathIds };
        if (typeof pathIds === 'number') {
            object['id'] = pathIds;
        }
        const path = this.buildEndpoint(options?.alternateEndpointFormat || this.endpointFormat, object);

        return this.http.delete(path, options);
    }

    /**
     * Helper function to convert end point format strings to final path
     *
     * @param path the end point format string with id placeholders
     * @param params the object to get id values from for the placeholder.
     *
     * @returns The endpoint.
     */
    private buildEndpoint(path: string, params?: PlainObject): string
    {
        // Replace any keys with provided values
        if (params) {
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    // If the key is undefined, just replace with an empty string.
                    path = path.replace(`:${key}:`, params[key] ? params[key] : '');
                }
            }
        }

        // Strip any missed keys
        path = path.replace(/:[\w-]*?:/, '');
        return `${this.serviceUrl}/${path}`;
    }

    /**
     * Instantiates an array of elements as objects from the JSON returned
     * from the server.
     *
     * @returns The array of Objects
     */
    private convertCollection(collection: Array<any>, other?: PlainObject): T[]
    {
        return collection.map((data: any) => this.createInstanceFrom(data, other));
    }

    /**
     * Gets the unique key for an entity of type @class Entity.
     * This is used to identify the object within a cache.
     *
     * @param json The json object to get the key from
     * @returns string containing the unique key value
     */
    public abstract keyForJson(json: T): string;

    /**
     * Convert accepted data to @class Entity object
     *
     * @param json The json data to convert to T
     * @param other
     */
    protected abstract createInstanceFrom(json: PlainObject, other?: PlainObject): T;
}
