import { ArgumentException, Exception, TemplateString } from '@swan/mvvm/model';
import { Role } from './Role';


export class UnknownUserRoleException extends ArgumentException
{
    public constructor(role: Role | string, paramName: string, innerException?: Exception)
    {
        super(
            TemplateString`Unknown user role '${'role'}'.`({
                role,
            }),
            paramName,
            innerException,
        );
    }
}
