<div class="flex flex-col w-full h-full max-h-screen p-6" [ngClass]="{
    'pt-14': isDialog && statusBarPadding
}">
    <div class="flex items-center h-5 mb-5 -ml-2">
        <button mat-icon-button (click)="navigateBack()">
            <mat-icon svgIcon="swan:navigation-arrow-left" color="primary" class="block icon-size-5"></mat-icon>
        </button>
        <h3 transloco="{{ isDialog
            ? 'VIDEO.RECORD.DETAILS.DIALOG_TITLE_EDIT'
            : 'VIDEO.RECORD.DETAILS.DIALOG_TITLE_CREATE' }}"></h3>
    </div>
    <div class="mt-4 relative h-full">
        <div class="absolute left-0 top-0 right-0 bottom-0 overflow-y-auto">
            <div id="preview-image-container" class="relative">
                <img id="preview-image" width="150" height="200" class="object-cover bg-gray-100 rounded-lg"
                     [src]="image"
                     defaultImg="/assets/images/default.jpg" alt=""
                     (click)="imageFileInput.click();$event.stopPropagation()">
                <input hidden type="file" #imageFileInput
                       [multiple]="false"
                       [accept]="'image/jpeg, image/png'"
                       (change)="imageChanged(imageFileInput)">
                <button id="select-picture-button" mat-icon-button [disabled]="uploadActive"
                        class="ml-2 h-5 absolute bottom-2 right-2"
                        [matTooltip]="'Select Picture'"
                        (click)="imageFileInput.click();$event.stopPropagation()">
                    <mat-icon svgIcon="swan:actions-photo-add" class="text-white"></mat-icon>
                </button>
            </div>
            <form *ngIf="this.detailsForm" [formGroup]="detailsForm" class="mt-10 space-y-4">

                <!-- Title -->
                <mat-form-field class="w-full">
                    <mat-label class="font-bold" transloco="VIDEO.RECORD.DETAILS.TITLE"></mat-label>
                    <textarea matInput #titleInput type="text" formControlName="title" maxlength="68" autocomplete="off"
                              placeholder="{{ 'VIDEO.RECORD.DETAILS.TITLE_PLACEHOLDER' | transloco }}"
                              cdkAutosizeMinRows="1" cdkTextareaAutosize></textarea>
                    <mat-hint matSuffix align="end">{{titleInput.value?.length || 0}}
                        /{{titleInput.maxLength}}</mat-hint>
                    <mat-error *ngIf="detailsForm.get('title')?.hasError('required')">
                        {{ "AUTH.REQUIRED" | transloco }}
                    </mat-error>
                </mat-form-field>

                <!-- Description -->
                <mat-form-field class="w-full">
                    <mat-label class="font-bold" transloco="VIDEO.RECORD.DETAILS.DESCRIPTION"></mat-label>
                    <textarea matInput type="text" #descriptionInput formControlName="description" maxlength="169"
                              placeholder="{{ 'VIDEO.RECORD.DETAILS.DESCRIPTION_PLACEHOLDER' | transloco }}"
                              autocomplete="off" cdkAutosizeMinRows="1" cdkTextareaAutosize></textarea>
                    <mat-hint matSuffix align="end">{{descriptionInput.value?.length || 0}}
                        /{{descriptionInput.maxLength}}</mat-hint>
                    <mat-error *ngIf="detailsForm.get('description')?.hasError('required')">
                        {{ "AUTH.REQUIRED" | transloco }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="w-full" appearance="fill" (click)="!uploadActive && tagListClick()">
                    <mat-label class="font-bold" transloco="VIDEO.RECORD.DETAILS.TAGS"></mat-label>
                    <mat-chip-list formArrayName="tags" required label="Tag Selection" class="mt-2 mb-2"
                                   [disabled]="uploadActive"
                                   placeholder="{{ 'VIDEO.RECORD.DETAILS.TAGS_PLACEHOLDER' | transloco }}">
                        <mat-chip class="bg-gray-200"
                                  *ngFor="let _interest of tags.value; index as i"
                                  (removed)="removeTag(_interest)">{{ _interest.name }}
                            <button mat-icon-button matChipRemove>
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip>
                    </mat-chip-list>
                    <mat-error *ngIf="tags.hasError('required')">
                        {{ "AUTH.REQUIRED" | transloco }}
                    </mat-error>
                </mat-form-field>

            </form>
        </div>
    </div>
    <ng-container *ngIf="uploadStatus">
        <mat-progress-bar [value]="uploadProgress.progress" [mode]="uploadProgress.progressbarMode"
                          class="mt-4"></mat-progress-bar>
        <pre class="mt-2">{{ uploadProgress.message }}</pre>
    </ng-container>
    <div class="mt-auto flex flex-col w-full">
        <button mat-flat-button class="swan-mat-button-large mt-4 font-bold tracking-wide" color="primary"
                [disabled]="!isValid && !uploadActive" (click)="!uploadActive ? saveVideo() : navigateBack()"
                title=""
                [transloco]="!uploadActive
                    ? (isDialog ? 'BUTTON.SAVE' : 'VIDEO.RECORD.UPLOAD.UPLOAD_VIDEO')
                    : 'VIDEO.RECORD.UPLOAD.CANCEL_UPLOAD'">
        </button>
    </div>
</div>
