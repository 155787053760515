import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    SkipSelf,
    ViewEncapsulation,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { swanAnimations } from '@swan/lib/animations';
import { ISwanAlertConfig, SwanAlertAppearance, SwanAlertService, SwanAlertType } from '@swan/lib/shared';
import { SwanUtilsService } from '@swan/services/utils/utils.service';
import { filter, Subject, takeUntil } from 'rxjs';


@Component({
    selector       : 'swan-alert',
    templateUrl    : './alert.component.html',
    styleUrls      : ['./alert.component.scss'],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations     : swanAnimations,
    exportAs       : 'swanAlert',
})
export class SwanAlertComponent implements OnChanges, OnInit, OnDestroy, ISwanAlertConfig
{
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_dismissible: BooleanInput;
    static ngAcceptInputType_dismissed: BooleanInput;
    static ngAcceptInputType_showIcon: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() appearance: SwanAlertAppearance                   = 'soft';
    @Input() dismissed: boolean                                = false;
    @Input() dismissible: boolean                              = false;
    @Input() name: string;
    @Input() showIcon: boolean                                 = true;
    @Input() type: SwanAlertType                               = 'primary';
    @Input() message: string;
    @Output() readonly dismissedChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        @SkipSelf() private _parentChangeDetectorRef: ChangeDetectorRef,
        private _swanAlertService: SwanAlertService,
        private _swanUtilsService: SwanUtilsService,
        private _translocoService: TranslocoService,
    )
    {
        this.name = this._swanUtilsService.randomId();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    @HostBinding('@expandCollapse') get slideIn(): string
    {
        return this.dismissed ? 'collapsed' : 'expanded';
    }

    /**
     * Host binding for component classes
     */
    @HostBinding('class') get classList(): any
    {
        return {
            'swan-alert-appearance-border' : this.appearance === 'border',
            'swan-alert-appearance-fill'   : this.appearance === 'fill',
            'swan-alert-appearance-outline': this.appearance === 'outline',
            'swan-alert-appearance-soft'   : this.appearance === 'soft',
            'swan-alert-dismissed'         : this.dismissed,
            'swan-alert-dismissible'       : this.dismissible,
            'swan-alert-show-icon'         : this.showIcon,
            'swan-alert-type-primary'      : this.type === 'primary',
            'swan-alert-type-accent'       : this.type === 'accent',
            'swan-alert-type-warn'         : this.type === 'warn',
            'swan-alert-type-basic'        : this.type === 'basic',
            'swan-alert-type-info'         : this.type === 'info',
            'swan-alert-type-success'      : this.type === 'success',
            'swan-alert-type-warning'      : this.type === 'warning',
            'swan-alert-type-error'        : this.type === 'error',
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On changes
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void
    {
        // Dismissed
        if ('dismissed' in changes) {
            // Coerce the value to a boolean
            this.dismissed = coerceBooleanProperty(changes.dismissed.currentValue);

            // Dismiss/show the alert
            this._toggleDismiss(this.dismissed);
        }

        // Dismissible
        if ('dismissible' in changes) {
            // Coerce the value to a boolean
            this.dismissible = coerceBooleanProperty(changes.dismissible.currentValue);
        }

        // Show icon
        if ('showIcon' in changes) {
            // Coerce the value to a boolean
            this.showIcon = coerceBooleanProperty(changes.showIcon.currentValue);
        }
    }

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to the dismiss calls
        this._swanAlertService.onDismiss
            .pipe(
                filter(name => this.name === name),
                takeUntil(this._unsubscribeAll),
            )
            .subscribe(() =>
            {
                // Dismiss the alert
                this.dismiss();
            });

        // Subscribe to the show calls
        this._swanAlertService.onShow
            .pipe(
                filter(name => typeof name === 'object' ? this.name === name.name : this.name === name),
                takeUntil(this._unsubscribeAll),
            )
            .subscribe((name) =>
            {
                if (typeof name === 'object') {
                    Object.assign(this, name.config);
                }
                // Show the alert
                this.show();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Dismiss the alert
     */
    dismiss(self?: boolean): void
    {
        if (self) {
            this._swanAlertService.dismiss(this.name);
            return;
        }
        // Return if the alert is already dismissed
        if (this.dismissed) {
            return;
        }

        // Dismiss the alert
        this._toggleDismiss(true);
    }

    /**
     * Show the dismissed alert
     */
    show(): void
    {
        // Return if the alert is already showing
        if (!this.dismissed) {
            return;
        }

        // Show the alert
        this._toggleDismiss(false);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Dismiss/show the alert
     *
     * @param dismissed
     * @private
     */
    private _toggleDismiss(dismissed: boolean): void
    {
        // Return if the alert is not dismissible
        if (!this.dismissible) {
            return;
        }

        // Set the dismissed
        this.dismissed = dismissed;

        // Execute the observable
        this.dismissedChanged.next(this.dismissed);

        // Notify the change detector
        this._changeDetectorRef.detectChanges();
        this._parentChangeDetectorRef.detectChanges();
    }
}
