import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionStoreService } from '@swan/session';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { UserFilter } from '@yukawa/chain-main-angular-core';
import { User as IUser } from '@yukawa/chain-main-angular-core/gen/chain/main/user';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
import { Nullable } from 'simplytyped';
import { User } from './user.model';


@Injectable()
export class UserService extends RestAspect
{
    active: boolean                    = false;
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    constructor(
        http: HttpClient,
        configService: ConfigService,
        private _sessionStoreService: SessionStoreService,
    )
    {
        super(http, configService, configService.formatUrl('userUrl'));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Returns the current session user. (use in apps)
     */
    get user(): Nullable<User>
    {
        return this._sessionStoreService.getJSON('user');
    }

    /**
     * Returns the user retrieved by {@see UserService.get()} (use in admin)
     */
    get user$(): Observable<User>
    {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    queryUser(filter: UserFilter): Observable<QueryResult<User>>
    {
        return this.query<any>(this.formatServiceUrl('/query'), filter);
    }

    loadUser(username: string): Observable<User>
    {
        return this.http.get<any>(this.formatServiceUrl(`/${username}`));
    }

    deleteUser(username: string): Observable<User>
    {
        return this.http.delete<any>(this.formatServiceUrl(`/${username}`));
    }

    saveUser(user: User, method: 'put' | 'post' = 'post'): Observable<User>
    {
        return this.http[method]<any>(this.formatServiceUrl(), user);
    }

    updateUser(user: User): Observable<User>
    {
        return this.http.put<any>(this.formatServiceUrl(), user);
    }

    merge(user: IUser): Observable<User>
    {
        return this.http.post<User>(this.formatServiceUrl('/merge'), user);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ UserService.user$ Members
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<User>
    {
        return this.http.get<User>(this.formatServiceUrl()).pipe(
            tap((user) =>
            {
                this._user.next(user);
            }),
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any>
    {
        return this.http.patch<User>(this.formatServiceUrl(), { user }).pipe(
            map((response) =>
            {
                this._user.next(response);
            }),
        );
    }

}
