/* eslint-disable no-console */
import { ObjectBase } from '@awesome-nodes/object/data';
import { enumKeys } from '@swan/lib/content/utils';
import { EventArgs, EventDelegate } from '@swan/mvvm/model';
import { PlainObject } from 'simplytyped';


export enum LogTypes
{
    'log'   = 'log',
    'info'  = 'info',
    'warn'  = 'warn',
    'error' = 'error',
    'debug' = 'debug'
}

export class LoggedEventArgs extends EventArgs
{
    private readonly _data: Array<unknown>;

    constructor(
        readonly type: LogTypes,
        readonly message: string,
        ...data: Array<unknown>
    )
    {
        super();
        this.type    = type;
        this.message = message;
        this._data   = data;
    }

    public get data(): Array<unknown>
    {
        return this._data;
    }
}

export class LogService extends ObjectBase
{
    static #instance: LogService;

    public readonly logged = new EventDelegate<LogService, LoggedEventArgs>(this);

    private _logs = new Array<string>();

    private _trace: boolean = false;

    private _consoleLogFunctions = new Map<LogTypes, typeof console[ LogTypes ]>();

    constructor()
    {
        super('Swan Log Service');
        Object.defineProperty(window, 'logService', {
            get: (): LogService => LogService.instance,
        });
    }

    static get instance(): LogService
    {
        return LogService.#instance || (LogService.#instance = new LogService());
    }

    public get logs(): string[]
    {
        return this._logs;
    }

    public get trace(): boolean
    {
        return this._trace;
    }

    public set trace(value: boolean)
    {
        this._trace = value;
    }

    public extendLogging(logType: LogTypes): void
    {
        const logFn: typeof console[ LogTypes ] = console[logType];
        this._consoleLogFunctions.set(logType, logFn);
        (console as PlainObject)[logType] = (...data: Array<unknown>): void =>
        {
            const message = data.map((_data) =>
            {
                switch (typeof _data) {
                    case 'object':
                        if (_data) {
                            return _data instanceof Error ? _data.toString() : JSON.safeStringify(_data, 4);
                        }
                        else {
                            return null;
                        }
                    default:
                        return data;
                }
            }).join('\n');
            this.addLog(logType, message);
            logFn.apply(console, data);
            if (this._trace) {
                console.trace(data);
            }
            this.saveLogs();
            this.logged.invoke(new LoggedEventArgs(logType, message, ...data.splice(1)));
        };
    }

    public save(filename?: string): void
    {
        const blob = new Blob([this._logs.join('\r\n')], { type: 'text/json' });

        const a                  = document.createElement('a');
        a.download               = filename ?? 'console-logs.txt';
        a.href                   = window.URL.createObjectURL(blob);
        a.dataset['downloadurl'] = ['text/json', a.download, a.href].join(':');

        const e = new MouseEvent('click');
        a.dispatchEvent(e);
    }

    public restoreLogs(): void
    {
        const storedLogs = sessionStorage.getItem('console-log-saver-logs');
        if (storedLogs) {
            this._logs = JSON.parse(storedLogs);
        }
    }

    private addLog(type: LogTypes, log: string): void
    {
        this._logs.push(type + '\t' + log);
    }

    private saveLogs(): void
    {
        try {
            sessionStorage.setItem('console-log-saver-logs', JSON.stringify(this._logs));
        }
        catch (error) {
            const quotaExceededError = error as {
                code: number;
                name: string;
                message: string;
            };
            let quotaExceeded        = false;
            switch (quotaExceededError.code) {
                case 22: // storage full
                    quotaExceeded = true;
                    break;
                case 1014:
                    // Firefox
                    if (quotaExceededError.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
                        quotaExceeded = true;
                    }
                    break;
            }
            if (quotaExceeded) {
                const arrayLength = this._logs.length;
                if (arrayLength == 1) {
                    this._logs[0] = this._logs[0].substring(0, Math.floor(this._logs[0].length / 2));
                }
                else {
                    // eslint-disable-next-line no-bitwise
                    this._logs = this._logs.splice(Math.floor(arrayLength / 2), arrayLength);
                }
                this.saveLogs();
                return;
            }

            if (this._consoleLogFunctions.has(LogTypes.error)) {
                (this._consoleLogFunctions.get(LogTypes.error) as typeof console[ LogTypes ])(error);
            }
            else {
                console.error(error);
            }
        }
    }
}

for (const _logType of enumKeys(LogTypes)) {
    LogService.instance.extendLogging(_logType as LogTypes);
}

declare global
{
    interface Window
    {
        // @ts-ignore
        logService: LogService;
    }
}
