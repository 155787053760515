<lib-video-background #video class="rounded-2xl" videoClass="rounded-2xl"
                      videoUrl="assets/videos/ugc_onboarding_2k.mp4"
                      poster="assets/videos/ugc_onboarding_2k.jpg"
                      (played)="videoPlayed()" [autoplay]="true">

    <div id="video-backdrop-top" class="absolute w-full h-90"></div>

    <div class="absolute bottom-6 w-full">
        <mat-icon svgIcon="swan:logo" class="block ml-auto mr-auto text-white w-15 h-5"></mat-icon>
    </div>

    <ng-container *ngIf="chapters.currentChapter.overlayContent && playbackStarted"
                  [ngSwitch]="chapters.currentChapter.overlayContent.type">

        <span *ngSwitchCase="'heading'" @fadeIn @fadeOut
              class="absolute font-bossa font-medium leading-15 {{ chapters.currentChapter.overlayContent.classes }}"
              [ngClass]="{
                  'left-6 top-18 bottom-18' : headingOverlayContent.position === 'left',
                  'top-18 left-6 right-6' : headingOverlayContent.position === 'top',
                  'right-6 top-18 bottom-18' : headingOverlayContent.position === 'right',
                  'bottom-18 left-6 right-6' : headingOverlayContent.position === 'bottom'
              }"
              [ngStyle]="{
                  color: headingOverlayContent.color,
                  'font-size': headingOverlayContent.fontSize + 'px'
              }">{{ headingOverlayContent.text }}</span>

    </ng-container>

    <ng-container *ngFor="let _tooltip of tooltips">
        <lib-tooltip *ngIf="chapters.currentChapter === _tooltip" @fadeIn @fadeOut
                     class="absolute {{ _tooltip.overlayContent.classes }}"
                     [ngStyle]="{
                         left: _tooltip.overlayContent.left,
                         top: _tooltip.overlayContent.top,
                         right: _tooltip.overlayContent.right,
                         bottom: _tooltip.overlayContent.bottom
                     }"
                     [icon]="_tooltip.overlayContent.toolTipIcon"
                     [text]="_tooltip.overlayContent.text"
                     [direction]="_tooltip.overlayContent.direction"
        ></lib-tooltip>
    </ng-container>

</lib-video-background>

<div class="z-10 p-5 h-full w-full flex flex-col overflow-hidden">
    <div class="flex flex-row space-x-2">
        <mat-progress-bar *ngFor="let _chapter of chapters.items" [value]="_chapter.progress"
                          class="rounded-lg white" (click)="seekToChapter(_chapter)"
        ></mat-progress-bar>
    </div>
    <div id="actionButtons" class="mt-5 flex flex-col-2 justify-between">
        <div>
            <lib-close-button class="" routerLink="../.."></lib-close-button>
        </div>
        <a transloco="VIDEO.RECORD.TUTORIAL.DONT_SHOW_AGAIN" (click)="toggle()" class="font-bold text-sm" [ngClass]="{
            'text-white': !this.dontShowAgain,
            'text-gray-500': this.dontShowAgain
        }"></a>
    </div>
    <div class="h-full flex flex-row">
        <div class="w-6/12 h-full" (click)="previousChapter()" (swipeup)="close()"></div>
        <div class="w-6/12 h-full" (click)="skipChapter()" (swipeup)="close()"></div>
    </div>
    <div class="flex flex-col">
        <button mat-flat-button class="mt-4 max-h-12 h-12 font-bold tracking-wide bg-white text-black text-lg"
                title="" @slideInBottom @fadeIn *ngIf="chapters.lastChapter === chapters.currentChapter"
                transloco="VIDEO.RECORD.TUTORIAL.LETS_GO" (click)="close()">
        </button>
    </div>
</div>
