import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthInterceptor } from './services';
import { AuthGuardService, NoAuthGuardService, SessionService } from './services/';


@NgModule({
    declarations: [],
    providers   : [
        SessionService,
        NoAuthGuardService,
        AuthGuardService,
        {
            provide    : HTTP_INTERCEPTORS,
            useExisting: AuthInterceptor,
            multi      : true,
        },
    ],
    imports     : [],
    exports     : [],
})
export class SessionModule
{
}
