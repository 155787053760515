import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceBase } from '@swan/mvvm/services';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Credentials, User } from '../../model/user';
//noinspection ES6PreferShortImport
import { AuthServiceProvider } from '../../providers/AuthServiceProvider';
import { UserService } from '../user';
import { AuthServiceBase } from './AuthServiceBase';
import { AuthRequestEndpoints, IAuthResponse, IAuthService, IAuthTokenResponse } from './IAuthService';


export const authServiceFactory = (provider: AuthServiceProvider): ServiceBase<User> => provider.provide(AuthService);

@Injectable({
    providedIn: 'root',
    useFactory: authServiceFactory,
    deps      : [AuthServiceProvider],
})
export class AuthService extends AuthServiceBase implements IAuthService
{
    public constructor(
        _httpClient: HttpClient,
        _userInfoService: UserService,
        _configService: ConfigService)
    {
        super(_userInfoService, _configService, _httpClient);
    }

    public login(credentials: Credentials): Observable<User>
    {
        return this.createWorker(
            'login',
            this._httpClient?.post<IAuthTokenResponse>(
                this._configService?.formatUrl('securityUrl') + AuthRequestEndpoints.createToken,
                credentials.toJSON(),
            ),
        );
    }

    public refresh(sessionToken: IAuthTokenResponse): Observable<IAuthTokenResponse>
    {
        return this._httpClient?.get<IAuthResponse>(
            this._configService?.formatUrl('securityUrl') + AuthRequestEndpoints.refreshToken,
            {
                headers: new HttpHeaders({
                    Authorization: `Bearer ${sessionToken.refresh_token}`,
                }),
            },
        ).pipe(
            // update existing token with server response
            map(refreshResponse => ({
                ...sessionToken,
                ...refreshResponse,
            })),
        ) as Observable<IAuthTokenResponse>;
    }
}
