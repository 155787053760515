import { ObjectBase } from '@awesome-nodes/object/data';
import { Change, Person as IPerson } from '@yukawa/chain-base-angular-domain';
import { Address } from './Address';


export class Person extends ObjectBase implements IPerson
{
    private _addresses: Address[];
    private _change: Change;
    private _companyName: string;
    private _created: Change;
    private _email: string;
    private _firstName: string;
    private _lang: string;
    private _lastName: string;
    private _mobile: string;
    private _phoneNumber: string;
    private _role: string;
    private _shortName: string;
    private _title: string;
    private _vatNumber: string;

    public constructor(person: IPerson)
    {
        super();

        this._addresses   = person.addresses ? person.addresses.map(a => new Address(a)) : [];
        this._change      = person.change as Change;
        this._companyName = person.companyName as string;
        this._created     = person.created as Change;
        this._email       = person.email as string;
        this._firstName   = person.firstName as string;
        this._lang        = person.lang as string;
        this._lastName    = person.lastName as string;
        this._mobile      = person.mobile as string;
        this._phoneNumber = person.phoneNumber as string;
        this._role        = person.role as string;
        this._shortName   = person.shortName as string;
        this._title       = person.title as string;
        this._vatNumber   = person.vatNumber as string;
    }

    //region Public Properties

    public get addresses(): Address[]
    {
        return this._addresses;
    }

    public set addresses(value: Address[])
    {
        this._addresses = value;
    }

    public get change(): Change
    {
        return this._change;
    }

    public set change(value: Change)
    {
        this._change = value;
    }

    public get companyName(): string
    {
        return this._companyName;
    }

    public set companyName(value: string)
    {
        this._companyName = value;
    }

    public get created(): Change
    {
        return this._created;
    }

    public set created(value: Change)
    {
        this._created = value;
    }

    public get email(): string
    {
        return this._email;
    }

    public set email(value: string)
    {
        this._email = value;
    }

    public get firstName(): string
    {
        return this._firstName;
    }

    public set firstName(value: string)
    {
        this._firstName = value;
    }

    public get lang(): string
    {
        return this._lang;
    }

    public set lang(value: string)
    {
        this._lang = value;
    }

    public get lastName(): string
    {
        return this._lastName;
    }

    public set lastName(value: string)
    {
        this._lastName = value;
    }

    public get mobile(): string
    {
        return this._mobile;
    }

    public set mobile(value: string)
    {
        this._mobile = value;
    }

    public get phoneNumber(): string
    {
        return this._phoneNumber;
    }

    public set phoneNumber(value: string)
    {
        this._phoneNumber = value;
    }

    public get role(): string
    {
        return this._role;
    }

    public set role(value: string)
    {
        this._role = value;
    }

    public get shortName(): string
    {
        return this._shortName;
    }

    public set shortName(value: string)
    {
        this._shortName = value;
    }

    public get title(): string
    {
        return this._title;
    }

    public set title(value: string)
    {
        this._title = value;
    }

    public get vatNumber(): string
    {
        return this._vatNumber;
    }

    public set vatNumber(value: string)
    {
        this._vatNumber = value;
    }

    //endregion

    public override toJSON<T = this>(): IPerson
    {
        return super.toJSON(
            'addresses',
            'change',
            'companyName',
            'created',
            'email',
            'firstName',
            'lang',
            'lastName',
            'mobile',
            'phoneNumber',
            'role',
            'shortName',
            'title',
            'vatNumber',
        );
    }
}
