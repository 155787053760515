import { InjectionToken } from '@angular/core';
import { IEnvironment } from './IEnvironment';


export * from './IEnvironment';
export * from './IQRAuth';
export * from './i18n';
export * from './init.module';
export * from './init.service';

export const APP_ENVIRONMENT_TOKEN = new InjectionToken<IEnvironment>('APP_ENVIRONMENT_TOKEN');
