import { Credentials, GroupContext } from '@yukawa/chain-security-angular-core';
import { Observable } from 'rxjs';
import { Nullable } from 'simplytyped';
import { Session } from '../../model';
import { IJWTPayload } from '../../model/jwt';
import { User } from '../../model/user';


export type TokenType = 'Bearer';

//region Token Auth Response

export interface IAuthDetails
{
    groups: Array<string>;
    groupContexts: Array<GroupContext>;
    defaultOrgId: string;
    orgId: string;
    organisationIds: Array<string>;
}

export interface IAuthTokenPayload
{
    details: IAuthDetails | IAuthRefreshDetails;
    scope: Array<string>;
}

export interface IWebTokenPayload extends IAuthTokenPayload, IJWTPayload
{

}

export interface IAuthResponse extends IAuthTokenPayload
{
    access_token: string;
    token_type: TokenType;
    expires_in: number;
    access_expires: string;
    username: string;
}

export interface IAuthTokenResponse extends IAuthResponse
{
    refresh_token: string;
    refresh_expires: string;
}

//endregion

//region Refresh Token Response

export interface IAuthRefreshDetails
{
    groupContexts: Array<GroupContext>;
    orgId: string;
    organisationIds: Array<string>;
}

export interface IAuthRefreshResponse extends IAuthResponse
{
    details: IAuthRefreshDetails;
}

//endregion

export interface IAuthServiceAspects
{
    token(credentials: Credentials): Observable<IAuthTokenResponse>;

    refreshToken(): Observable<IAuthRefreshResponse>;
}

export enum AuthRequestEndpoints
{
    loadAccount  = '/account',
    switchOrg    = '/org',
    refreshToken = '/refresh',
    revokeToken  = '/refresh',
    createToken  = '/token',
}

export interface IAuthService
{
    login(credentials: Credentials): Observable<User>;

    refresh(sessionToken: IAuthTokenResponse): Observable<IAuthRefreshResponse>;

    restore(sessionToken?: Nullable<IAuthTokenResponse>): Observable<Session>;

    logout(): void;
}
