import { NgModule } from '@angular/core';
import { SwanMediaWatcherService } from '@swan/services/media-watcher/media-watcher.service';

@NgModule({
    providers: [
        SwanMediaWatcherService
    ]
})
export class SwanMediaWatcherModule
{
    /**
     * Constructor
     */
    constructor(private _swanMediaWatcherService: SwanMediaWatcherService)
    {
    }
}
