import { Injectable } from '@angular/core';
import { Keyboard as CapacitorKeyboard } from '@capacitor/keyboard';
import { EventArgs, EventDelegate, ObjectModel } from '@swan/mvvm/model';


export class KeyboardShowEventArgs extends EventArgs
{
    public constructor(
        private readonly _shown: boolean,
        private readonly _keyboardHeight: number,
    )
    {
        super();
    }

    public get shown(): boolean
    {
        return this._shown;
    }

    public get keyboardHeight(): number
    {
        return this._keyboardHeight;
    }
}

export class KeyboardHideEventArgs extends EventArgs
{
    public constructor(
        private readonly _hidden: boolean,
    )
    {
        super();
    }

    public get hidden(): boolean
    {
        return this._hidden;
    }
}

@Injectable({
    providedIn: 'root',
})
export class Keyboard extends ObjectModel
{
    public show = new EventDelegate<Keyboard, KeyboardShowEventArgs>(this);
    public hide = new EventDelegate<Keyboard, KeyboardHideEventArgs>(this);

    public constructor()
    {
        super();

        CapacitorKeyboard.addListener('keyboardWillShow', (info) =>
        {
            this.show.invoke(new KeyboardShowEventArgs(false, info.keyboardHeight));
        });

        CapacitorKeyboard.addListener('keyboardDidShow', (info) =>
        {
            this.show.invoke(new KeyboardShowEventArgs(true, info.keyboardHeight));
        });

        CapacitorKeyboard.addListener('keyboardWillHide', () =>
        {
            this.hide.invoke(new KeyboardHideEventArgs(false));
        });

        CapacitorKeyboard.addListener('keyboardDidHide', () =>
        {
            this.hide.invoke(new KeyboardHideEventArgs(true));
        });
    }

    public async showKeyboard(): Promise<void>
    {
        return CapacitorKeyboard.show();
    }

    public async hideKeyboard(): Promise<void>
    {
        return CapacitorKeyboard.hide();
    }
}
