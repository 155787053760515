import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityRepository } from '@swan/lib/content/utils';
import { InterestFilter } from '@swan/lib/domain';
import { ConfigService, RepoAspectRest } from '@yukawa/chain-base-angular-client';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { BehaviorSubject, map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { Nullable } from 'simplytyped';
import { Interest } from './interest.entity';
import { IInterest, IInterestInfo } from './interests.types';


@Injectable()
export class InterestsService extends RepoAspectRest<string, Interest, InterestFilter>
{
    // Private
    private readonly _interest         = new BehaviorSubject<Interest>(null as never);
    private readonly _interests        = new BehaviorSubject<Array<IInterest>>(null as never);
    private readonly _interestEntities = new BehaviorSubject<Array<Interest>>(null as never);

    private readonly _repository = new EntityRepository<Interest>({
        createInstanceFrom: this.createInstanceFrom.bind(this),
    });

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        http: HttpClient,
        configService: ConfigService)
    {
        super(http, configService, 'interestUrl');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for interests
     */
    get interests$(): Observable<Array<Interest>>
    {
        return this._interestEntities.asObservable();
    }

    public get interests(): Nullable<Array<Interest>>
    {
        return this._interestEntities.value;
    }

    /**
     * Getter for interest
     */
    get interest$(): Observable<Interest>
    {
        return this._interest.asObservable();
    }

    get interest(): Interest
    {
        return this._interest.value;
    }

    public get repository(): EntityRepository<Interest>
    {
        return this._repository;
    }

// -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Query interests
     */
    queryInterests(filter: InterestFilter = {}): Observable<QueryResult<IInterest>>
    {
        return this.query(filter).pipe(tap(response => this._interests.next(response.items)));
    }

    /**
     * Get interests
     */
    getInterests(filter: InterestFilter = {}): Observable<Array<Interest>>
    {
        filter = {
            orderBy : 'name',
            orderDir: 'ASC',
            ...filter,
        };
        return this.queryInterests(filter).pipe(
            switchMap(response => this._repository.add(
                this._httpClient.get<Array<IInterestInfo>>(this.formatServiceUrl('/tree'))) as Observable<Array<Interest>>),
        ).pipe(
            tap((interests) =>
            {
                this._interestEntities.next(interests);
            }),
        );
    }

    /**
     * Get interest by id
     */
    getInterestById(id: string): Observable<IInterest>
    {
        return this._interests.pipe(
            take(1),
            map((interests) =>
            {
                const interest = interests.find(value => value.interestId === id) || null;

                if (interest) {
                    // Update the interest
                    const entity = this._repository.get(id);
                    if (entity == null) {
                        return null;
                    }
                    this._interest.next(entity);
                }

                // Return the interest
                return interest;
            }),
            switchMap((interest) =>
            {
                if (!interest) {
                    return throwError(() => new Error('Could not found the interest with id of ' + id + '!'));
                }

                return of(interest);
            }),
        );
    }

    /**
     * Create interest
     *
     * @param interest
     */
    createInterest(interest: Interest): Observable<IInterest>
    {
        return this.create(interest.toJson() as Interest).pipe(
            switchMap(response => this.getInterests().pipe(
                switchMap(() => this.getInterestById(response.interestId),
                ))));
    }

    /**
     * Update the interest
     *
     * @param interest
     */
    updateInterest(interest: Interest): Observable<Interest>
    {
        return this._repository.update(this.update(interest.toJson() as Interest), interest).pipe(
            tap((response) =>
            {
                // Update the interests
                this.getInterests().subscribe();
            }),
        );
    }

    /**
     * Delete the interest
     *
     * @param interest
     */
    deleteInterest(interest: Interest): Observable<Interest>
    {
        return this.delete(interest.toJson() as Interest).pipe(
            map((deletedInterest) =>
            {
                this._repository.remove(interest.key);
                interest.updateFromJson(deletedInterest);
                if (interest.parent) {
                    interest.parent.children.splice(interest.parent.children.indexOf(interest), 1);
                }
                else {
                    this._interestEntities.value.splice(this._interestEntities.value.indexOf(interest), 1);
                }

                this._interest.next(undefined as never);
                this._interestEntities.next(this._interestEntities.value);

                // Return the deleted status
                return deletedInterest;
            }),
        );
    }

    protected createInstanceFrom(interestInfo: IInterestInfo): Interest
    {
        const findInterest = (id: string): IInterest =>
            this._interests.value.find(_interest => _interest.interestId === id) as IInterest;

        // Map children and their parents using interest info tree
        const iInterest       = findInterest(interestInfo.id);
        const mapInterestTree = (info: IInterestInfo): IInterest =>
        {
            const foundInterest = findInterest(info.id);
            if (iInterest.parent) {
                foundInterest.parent = findInterest(iInterest.parent.interestId as string);
            }
            foundInterest.children = info.interests.map(mapInterestTree);
            return foundInterest;
        };
        iInterest.children    = interestInfo.interests.map(mapInterestTree);

        // Create and cache interest entities
        const interest = new Interest(iInterest);
        this._repository.add(interest.key, interest);

        const cacheEntityTree = (entity: Interest): void =>
        {
            this._repository.add(entity.key, entity);
            entity.children.forEach(cacheEntityTree);
        };
        cacheEntityTree(interest);

        return interest;
    }
}
