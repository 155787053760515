import { Credentials } from '@yukawa/chain-security-angular-core';
import { Observable } from 'rxjs';
import { AuthToken } from '../../model/auth';
import { ChangePassword, UserInfo } from '../../model/user';


export interface IChangePasswordResponse
{
    fqn: string;
    key: number;
    messages: [
        {
            code: string;
            level: number;
            text: string;
            type: string;
        }
    ];
    op: string;
    stamp: string;
    took: 0;
    userId: string; // email
}

export enum UserRestEndpoints
{
    createUser       = '/',
    updateUser       = '/',
    loadUser         = '/',
    deleteUser       = '/',
    synchronizeRoles = '/admin/synchronizeRoles',
    exportUsers      = '/export',
    merge            = '/merge',
    changePassword   = '/password/change',
    resetPassword    = '/password/reset',
    queryUsers       = '/query',
    registerUser     = '/register',
}

export interface IUserService
{
    loadUser(token: AuthToken): Observable<UserInfo>;

    changePassword(credentials: Credentials): Observable<ChangePassword>;

    resetPassword(username: string): Observable<ChangePassword>;
}
