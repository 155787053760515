import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { IServiceProvider, ServiceProviderBase } from '@swan/mvvm/providers';
import { ServiceBase } from '@swan/mvvm/services';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import { User } from '../model/user';
import { AuthDesignService } from '../services/auth/AuthDesignService';
import { UserService } from '../services/user';
import { UserDesignService } from '../services/user/UserDesignService';


@Injectable({
    providedIn: 'root',
})
export class AuthServiceProvider extends ServiceProviderBase<ServiceBase<User>>
{
    protected constructor(_parentInjector: Injector)
    {
        super(_parentInjector);
    }

    public get designFactory(): IServiceProvider
    {
        return {
            provide: AuthDesignService,
            deps   : [UserDesignService],
        };
    }

    public get developmentFactory(): IServiceProvider
    {
        return this.productionFactory;
    }

    public get productionFactory(): IServiceProvider
    {
        return {
            deps: [
                HttpClient,
                UserService,
                ConfigService,
            ],
        };
    }

}
