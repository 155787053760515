import { IEnvironment } from '@swan/lib/config';


export const environment: IEnvironment = {
    production: false,
    wsEndpoint: 'wss://ws.swan.dev.cloud.yukawa.de:443/socket/',

    _wsEndpoint: 'wss://swan.dev.cloud.yukawa.de/api/signalling-service/socket',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    RTCPeerConfiguration: {
        iceServers: [
            {
                urls: [
                    'stun:stun.l.google.com:19302',
                    'stun:stun1.l.google.com:19302',
                    'stun:stun2.l.google.com:19302',
                    'stun:stun3.l.google.com:19302',
                    'stun:stun4.l.google.com:19302',
                ],
            },
        ],
    },

    mirror: false,
};
