/* tslint:disable:max-line-length */
import { SwanNavigationItem } from '@swan/components/navigation';

export const defaultNavigation: SwanNavigationItem[] = [
    {
        id   : 'room',
        title: 'Meeting Room',
        type : 'basic',
        icon : 'meeting_room',
        link : '/room'
    },
    {
        id   : 'video_library',
        title: 'Video Library',
        type : 'basic',
        icon : 'video_library',
        link : '/video'
    }
];
export const compactNavigation: SwanNavigationItem[] = [
    {
        id   : 'room',
        title: 'Meeting Room',
        type : 'basic',
        icon : 'meeting_room',
        link : '/room'
    },
    {
        id   : 'video_library',
        title: 'Video Library',
        type : 'basic',
        icon : 'video_library',
        link : '/video'
    }
];
export const futuristicNavigation: SwanNavigationItem[] = [
  
];
export const horizontalNavigation: SwanNavigationItem[] = [
   
];
