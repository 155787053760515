import { NgModule } from '@angular/core';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import {
    AutocompleteOptionsDirective,
    BackButtonDirective,
    ElapsedDateDirective,
    EmojiTooltipDirective,
    MatMultilineTabDirective,
    RenderIfVisibleDirective,
} from './directive';
import {
    DateFormatPipe,
    DateSuffixPipe,
    ElapsedDatePipe,
    EmojiPipe,
    HumanizedDatePipe,
    LikesPipe,
    SwanFindByKeyPipe,
} from './pipe';
import { MarkedPipe } from './pipe/marked.pipe';


@NgModule({
    imports     : [
        PickerModule,
    ],
    declarations: [
        AutocompleteOptionsDirective,
        BackButtonDirective,
        DateFormatPipe,
        DateSuffixPipe,
        ElapsedDateDirective,
        ElapsedDatePipe,
        EmojiPipe,
        EmojiTooltipDirective,
        HumanizedDatePipe,
        LikesPipe,
        MarkedPipe,
        MatMultilineTabDirective,
        RenderIfVisibleDirective,
        SwanFindByKeyPipe,
    ],
    exports     : [
        AutocompleteOptionsDirective,
        BackButtonDirective,
        DateFormatPipe,
        DateSuffixPipe,
        ElapsedDateDirective,
        ElapsedDatePipe,
        EmojiPipe,
        EmojiTooltipDirective,
        HumanizedDatePipe,
        LikesPipe,
        MarkedPipe,
        MatMultilineTabDirective,
        RenderIfVisibleDirective,
        SwanFindByKeyPipe,
    ],
})
export class SharedModule
{
}
