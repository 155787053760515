import { ObjectModel } from '@swan/mvvm/model';
import { IJWTHeader } from './IJWTHeader';
import { IJWTPayload } from './IJWTPayload';
import { WebTokenException } from './WebTokenException';


export class WebToken<T extends IJWTPayload = IJWTPayload> extends ObjectModel
{
    private readonly _header: IJWTHeader;

    private readonly _payload: T;

    private readonly _signature: string;

    public constructor(private _token: string)
    {
        super();

        if (!/^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/.test(_token)) {
            throw new WebTokenException(`The format of the provided jwt web token is invalid. WebToken: ${_token}`);
        }

        const parts = _token.split('.');

        try {
            this._header  = JSON.parse(atob(parts[0]));
            this._payload = JSON.parse(atob(parts[1]));
        }
        catch (error) {
            if (error instanceof SyntaxError) {
                throw new WebTokenException(
                    'Invalid web token content. Probably no base64 encoded json payload provided.',
                    undefined,
                    error,
                );
            }
            throw error;
        }
        this._signature = parts[2];
    }

    //region Public Properties

    public get header(): IJWTHeader
    {
        return this._header;
    }

    public get payload(): T
    {
        return this._payload;
    }

    public get signature(): string
    {
        return this._signature;
    }

    public get subject()
    {
        return this._payload.sub;
    }

    public get issuedAt(): Date
    {
        return new Date(this._payload.iat * 1000);
    }

    public get expirationTime(): Date
    {
        return new Date(this._payload.exp * 1000);
    }

    public get isExpired(): boolean
    {
        return new Date().getTime() > this.expirationTime.getTime();
    }

    //endregion

    public override toString(): string
    {
        return this._token;
    }
}

