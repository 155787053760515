import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StaticConstructable } from '@awesome-nodes/object';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import { lastValueFrom, Observable, Subscriber } from 'rxjs';
import { PlainObject } from 'simplytyped';


export interface InitConfig
{
    /**
     * @type Environments
     */
    'config.base.json': false;
    /**
     * @type Environments
     */
    'config.json': true;
}

@Injectable({
    providedIn: 'root',
})
@StaticConstructable
export class InitService
{
    static _configOverrides: Map<string, keyof InitConfig>;
    static readonly configOverridesKey = 'CONFIG_OVERRIDES';

    private static readonly configs: InitConfig = {
        'config.base.json': false,
        'config.json'     : true,
    };

    private _configs = new Map<keyof InitConfig, object>();

    public constructor(
        private readonly _configService: ConfigService,
        private readonly _http: HttpClient,
    )
    {
    }

    public static get configOverrides(): Map<string, keyof InitConfig>
    {
        return InitService._configOverrides;
    }

    public get configOverrides(): Map<string, keyof InitConfig>
    {
        return InitService._configOverrides;
    }

    public static construct(): void
    {
        const item            = localStorage.getItem(this.configOverridesKey);
        this._configOverrides = new Map<string, keyof InitConfig>(item ? Object.entries(JSON.parse(item)) : null);
    }

    private static saveLocalStorage(): void
    {
        if (this._configOverrides.size === 0) {
            localStorage.removeItem(this.configOverridesKey);
        }
        else {
            localStorage.setItem(
                this.configOverridesKey,
                JSON.stringify(Object.fromEntries(InitService._configOverrides)),
            );
        }
    }

    public initConfigs(): Observable<void>
    {
        return new Observable<void>((subscriber: Subscriber<void>) =>
        {
            this._configService.initConfigs(Object.keys(InitService.configs), true).then(async (config) =>
            {
                await this.overrideConfig(config as object);
                console.log('Loaded config', config);

                subscriber.complete();
            }).catch((reason) =>
            {
                // reject(false);
                console.warn('Load config failed', reason);
                alert('Page is not working, please try again later.');
                setTimeout(() =>
                {
                    location.reload();
                }, 10000);
            });
        });
    }

    public addConfigOverride(key: string, config: keyof InitConfig): void
    {
        InitService._configOverrides.set(key, config);
        InitService.saveLocalStorage();
    }

    public removeConfigOverride(key: string): void
    {
        InitService._configOverrides.delete(key);
        InitService.saveLocalStorage();
    }

    private async overrideConfig(config: PlainObject): Promise<void>
    {
        if (InitService._configOverrides.size > 0) {
            for (const _override of InitService._configOverrides.keys()) {
                const configFile = InitService._configOverrides.get(_override) as keyof InitConfig;

                if (!this._configs.has(configFile)) {
                    this._configs.set(configFile, await lastValueFrom(this._http.get<object>(configFile, {})));
                }

                config[_override] = (this._configs.get(configFile) as PlainObject)[_override];
                if (_override === 'baseUrl') {
                    config['sessionStoreKey'] = 'overridden';
                }
            }
        }
    }
}
