<div class="flex flex-col w-full p-6" [ngClass]="{
    'pt-14': isDialog && statusBarPadding
}">
    <div class="flex items-center h-5 mb-5 -ml-2">
        <button mat-icon-button (click)="navigateBack()">
            <mat-icon svgIcon="swan:navigation-arrow-left" color="primary" class="block icon-size-5"></mat-icon>
        </button>
        <h3 transloco="VIDEO.RECORD.TAGS.DIALOG_TITLE"></h3>
    </div>
    <div class="mt-4">
        <mat-form-field class="w-full">
            <mat-label class="font-bold" transloco="VIDEO.RECORD.TAGS.LABEL" [translocoParams]="{
                selectedTagsCount: selectedTagsCount,
                maxTagCount: maxTagCount
            }"></mat-label>
            <input matInput #tagInput type="text"
                   placeholder="{{ 'VIDEO.RECORD.TAGS.PLACEHOLDER' | transloco: {maxTagCount: maxTagCount} }}"
                   autocomplete="off"
                   (input)="searchTextChanged(tagInput.value)"
                   maxlength="196" [cdkTrapFocusAutoCapture]="true" [cdkTrapFocus]="true">
            <mat-hint matSuffix align="end">{{tagInput.value?.length || 0}}/{{tagInput.maxLength}}</mat-hint>
        </mat-form-field>
    </div>
    <div class="space-y-2 text-sm">
        <ng-container *ngFor="let _interest of tags">
            <div class="rounded-full max-w-max pl-3 pt-2 pr-3 pb-2" @fadeIn @fadeOut
                 (click)="selectTag(_interest)" [ngClass]="{
            'bg-gray-100': !_interest.selected,
            'bg-black text-white': _interest.selected
        }">
                {{ _interest.name }}
            </div>
        </ng-container>
    </div>
</div>
