import {Change, ChangeFilter, Changed, Created, DataRange, EntityFilter, Info} from '@yukawa/chain-base-angular-domain';
import {ProfileFilter, PublicProfile} from './profile';


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.Comment <br/>
*/
export interface Comment extends  Changed, Created{
author?: PublicProfile;
change?: Change;
children?: Comment[];
commentId?: number;
contentId?: number;
created?: Change;
parentCommentId?: number;
text?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.CommentFilter <br/>
*/
export interface CommentFilter extends  EntityFilter{
authorFilter?: ProfileFilter;
changeFilter?: ChangeFilter;
commentId?: number;
commentIds?: number[];
contentId?: number;
createdFilter?: ChangeFilter;
enrichChildren?: boolean;
parentCommentId?: number;
parentCommentIds?: number[];
withParent?: boolean;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.Content <br/>
*/
export interface Content extends  Changed, Created{
author?: PublicProfile;
change?: Change;
contentId?: number;
created?: Change;
duration?: number;
extAssetId?: string;
extPlaybackId?: string;
fileName?: string;
fileSize?: number;
info?: Info;
interests?: ContentInterest[];
language?: string;
mime?: string;

/**
*	@example mux
*/
provider?: string;
recordingDate?: Date;
splashScreen?: string;
stats?: ContentStatistics;
status?: ContentStatus;
thumbnail?: string;

/**
*	@example video
*/
type?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.ContentFilter <br/>
*/
export interface ContentFilter extends  EntityFilter{
authorFilter?: ProfileFilter;
contentId?: number;
contentIds?: number[];
contentInterestFilter?: ContentInterestFilter;
excludedContentId?: number;
excludedContentIds?: number[];
extAssetId?: string;
fileName?: string;
info?: Info;
languages?: string[];
statsFilter?: ContentStatisticsFilter;
status?: ContentStatus;
statuses?: ContentStatus[];
stream?: ContentStreamType;
types?: string[];
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.ContentInterest <br/>
*/
export interface ContentInterest {
contentId?: number;
interestId?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.ContentInterestFilter <br/>
*/
export interface ContentInterestFilter extends  EntityFilter{
contentId?: number;
contentIds?: number[];
excludedContentIds?: number[];
excludedInterestIds?: string[];
interestId?: string;
interestIds?: string[];
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.ContentStatistics <br/>
*/
export interface ContentStatistics {
contentId?: number;
likes?: number;
ticks?: number;
trendWeekly?: number;
trendWeeklyChange?: string;
viewed?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.ContentStatisticsFilter <br/>
*/
export interface ContentStatisticsFilter extends  EntityFilter{
contentId?: number;
likesRange?: DataRange<number> ;
ticksRange?: DataRange<number> ;
trendWeeklyChangeRange?: DataRange<string> ;
trendWeeklyRange?: DataRange<number> ;
viewedRange?: DataRange<number> ;
}

export enum ContentStatus {
NEW = "NEW",
UPLOADING = "UPLOADING",
UPLOADED = "UPLOADED",
PUBLISHED = "PUBLISHED"
}

export enum ContentStreamType {
TRENDING = "TRENDING",
FAVORITE = "FAVORITE",
RECOMMENDED = "RECOMMENDED",
VIEWED = "VIEWED",
RELATED = "RELATED",
FOLLOWED = "FOLLOWED"
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.ContentView <br/>
*/
export interface ContentView {
content?: Content;
favorite?: boolean;
liked?: boolean;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.FavoriteContent <br/>
*/
export interface FavoriteContent extends  Changed{
change?: Change;
contentId?: number;
username?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.FavoriteContentFilter <br/>
*/
export interface FavoriteContentFilter extends  EntityFilter{
changeFilter?: ChangeFilter;
contentIds?: number[];
username?: string;
usernames?: string[];
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.LikedContent <br/>
*/
export interface LikedContent extends  Changed{
change?: Change;
contentId?: number;
username?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.LikedContentFilter <br/>
*/
export interface LikedContentFilter extends  EntityFilter{
changeFilter?: ChangeFilter;
contentIds?: number[];
username?: string;
usernames?: string[];
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.PlayerPosition <br/>
*/
export interface PlayerPosition extends  Changed{
change?: Change;
contentId?: number;
second?: number;
username?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.PlayerPositionFilter <br/>
*/
export interface PlayerPositionFilter extends  EntityFilter{
changeFilter?: ChangeFilter;
contentIds?: number[];
usernames?: string[];
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.PlayerTick <br/>
*/
export interface PlayerTick extends  Created{
contentId?: number;
created?: Change;
duration?: number;
second?: number;
totalSecondsViewed?: number;
}

