import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { EmojiEvent, EmojiService, NativeElementFormControl } from '@swan/lib/shared';
import { PlainObject } from 'simplytyped';
import { IQueryTableEntryDetail } from '../types';


export interface FormFieldConfig
{
    detail: Required<IQueryTableEntryDetail>;
    control: FormControl;
}

@Component({
    selector   : 'lib-query-table-details',
    templateUrl: './details.component.html',
    styleUrls  : ['./details.component.scss'],
})
export class QueryTableDetailsComponent implements OnChanges
{
    @Input()
    entryDetails!: Array<IQueryTableEntryDetail>;

    @Output() inputChanged = new EventEmitter<boolean>();
    @Output() selectTags   = new EventEmitter<void>();

    formFieldConfig = new Array<FormFieldConfig>();

    detailsForm!: FormGroup;

    private _emojiTextInput!: HTMLInputElement | HTMLTextAreaElement;

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _emojiService: EmojiService,
    )
    {
    }

    get isValid(): boolean
    {
        return !this.detailsForm.invalid;
    }

    init(): void
    {
        this.formFieldConfig.length = 0;

        const formControls: PlainObject = {};
        for (const _detail of this.entryDetails.values()) {
            if (!_detail.canEdit) {
                continue;
            }

            let validators: Array<ValidatorFn> | undefined;
            if (_detail.required) {
                validators = [Validators.required];
            }

            let formState = _detail.value;
            if (formState && _detail.emojiSupport) {
                formState = this._emojiService.colonsToNative(formState);
            }
            const formControl = new FormControl(formState, validators);
            this.formFieldConfig.push({
                detail : _detail as Required<IQueryTableEntryDetail>,
                control: formControl,
            });

            formControls[_detail.key] = formControl;
        }

        this.detailsForm = new FormGroup(formControls);
        this.detailsForm.valueChanges.subscribe(() =>
        {
            this.inputChanged.emit(!this.detailsForm.invalid);
        });

        this._changeDetector.detectChanges();
        this.inputChanged.emit(!this.detailsForm.invalid);
    }

    afterViewInit(): void
    {
        for (const _detail of this.entryDetails) {
            if (_detail.emojiSupport) {
                const abstractControl = this.detailsForm.get(_detail.key) as NativeElementFormControl;
                if (abstractControl.nativeElement) {
                    this._emojiService.transformColonToNative(
                        abstractControl,
                        abstractControl.nativeElement as any,
                    ).subscribe();
                }
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (changes['entryDetails']) {
            this.init();
            this._changeDetector.detectChanges();
            this.afterViewInit();
        }
    }

    save<T>(): T
    {
        const fileInfo = {} as PlainObject;
        for (const _config of this.formFieldConfig) {
            if (!_config.detail.canEdit) {
                continue;
            }
            else if (!_config.detail.required && _config.control?.value == null || _config.control?.value === '') {
                continue;
            }

            let value = _config.control?.value;

            if (typeof _config.control?.value === 'string') {
                if (_config.detail.emojiSupport) {
                    value = this._emojiService.nativeEmojiToColons(_config.control.value);
                }
                value = value.trim();
            }

            if (_config.detail.options?.some(option => option.objectValue && !value.some((_value: any) => _value === option.objectValue))) {
                value = Array.isArray(value)
                    ? value.map(option => _config.detail.options?.find(_option => _option.value === option)?.objectValue)
                    : _config.detail.options?.find(_option => _option.value === value)?.objectValue;
            }

            if (_config.detail.group == null) {
                fileInfo[_config.detail.key] = value;
            }
            else {
                if (!fileInfo[_config.detail.group]) {
                    fileInfo[_config.detail.group] = {};
                }
                fileInfo[_config.detail.group][_config.detail.key] = value;
            }
            _config.detail.value = value;
        }

        return fileInfo as T;
    }

    public addEmoji($event: EmojiEvent): void
    {
        this._emojiService.insert($event, this._emojiTextInput);
    }

    public selectEmoji(textInput: HTMLInputElement | HTMLTextAreaElement): void
    {
        this._emojiTextInput = textInput;
    }

    public handleError(error: Error): boolean
    {
        if (!(error instanceof HttpErrorResponse)) {
            return false;
        }

        for (const _config of this.formFieldConfig) {
            if (!_config.detail.entityName) {
                continue;
            }
            if (error.error?.text.indexOf(`${_config.detail.entityName}.${_config.detail.key}`) > -1) {
                _config.control.setErrors({
                    'error': error.error?.text,
                }, {
                    emitEvent: true,
                });

                return true;
            }
        }

        return false;
    }

    public getErrorMessage(errors: ValidationErrors | null): string
    {
        return errors ? Object.values(errors).join('\n ') : '';
    }
}
