import { HttpClient } from '@angular/common/http';
import { EventDelegate } from '@swan/mvvm/model';
import { ServiceBase, ServiceException } from '@swan/mvvm/services';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import { map, Observable, of, switchMap } from 'rxjs';
import { Nullable } from 'simplytyped';
import { Session } from '../../model';
import { AuthToken } from '../../model/auth/AuthToken';
import { Credentials, User } from '../../model/user';
import { LoginRequestEventArgs } from '../auth';
import { AUTH_INTERCEPTOR_OPTIONS } from '../AuthInterceptiorOptions';
import { UserService } from '../user';
import { IAuthRefreshResponse, IAuthService, IAuthTokenResponse } from './IAuthService';


export abstract class AuthServiceBase extends ServiceBase<User> implements IAuthService
{
    private _loginRequest = new EventDelegate<this, LoginRequestEventArgs>(this);

    protected constructor(
        protected readonly _userService: UserService,
        protected readonly _configService: Nullable<ConfigService>,
        protected readonly _httpClient: Nullable<HttpClient>)
    {
        super();
    }

    public get loginRequest(): EventDelegate<this, LoginRequestEventArgs>
    {
        return this._loginRequest;
    }

    public get isAuthenticated(): boolean
    {
        return Session.instance instanceof Session.SessionConnected;
    }

    public restore(sessionToken?: IAuthTokenResponse): Observable<Session>
    {
        if (sessionToken == null) {
            return of(new Session.SessionDisconnected());
        }

        const authToken = sessionToken instanceof AuthToken
            ? sessionToken
            : new AuthToken(sessionToken);

        if (authToken.refresh_expired) {
            return of(new Session.SessionDisconnected());
        }

        let user$: Observable<User>;

        if (authToken.access_expired) {
            user$ = this.createWorker('restore', this.refresh(sessionToken));
        }
        else if (!this.isAuthenticated) {
            user$ = this.createWorker('restore', of(sessionToken));
        }
        else {
            user$ = of(Session.instance?.user as User);
        }

        return user$.pipe(
            map(user => new Session.SessionConnected(user)),
        );
    }

    public logout(): void
    {
        return undefined;
    }

    protected doWork(workerName: string, observable: Observable<IAuthTokenResponse>): Observable<User>
    {
        switch (workerName) {
            case 'login':
            case 'restore':
                let authToken: AuthToken;

                return observable.pipe(
                    map(response => authToken = new AuthToken(response)),
                    switchMap((token) =>
                    {
                        AUTH_INTERCEPTOR_OPTIONS.refreshEnabled = false;

                        const userInfoObservable = this._userService.loadUser(token);

                        AUTH_INTERCEPTOR_OPTIONS.refreshEnabled = true;

                        return userInfoObservable;
                    }),
                    map(userInfo => new User(authToken, userInfo)),
                );

            default:
                throw new ServiceException(`Unknown service worker name: ${workerName}`);
        }
    }

    public abstract login(credentials: Credentials): Observable<User>;

    public abstract refresh(sessionToken: IAuthTokenResponse): Observable<IAuthRefreshResponse>;
}
