import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { SwanDrawerModule } from '@swan/components/drawer';
import { LayoutComponent } from 'app/layout/layout.component';
import { EmptyLayoutModule } from 'app/layout/layouts/empty/empty.module';
import { CompactLayoutModule } from 'app/layout/layouts/vertical/compact/compact.module';
import { DenseLayoutModule } from 'app/layout/layouts/vertical/dense/dense.module';
import { ThinLayoutModule } from 'app/layout/layouts/vertical/thin/thin.module';
import { SharedModule } from 'app/shared/shared.module';


const layoutModules = [
    // Empty
    EmptyLayoutModule,

    // Vertical navigation
    CompactLayoutModule,
    DenseLayoutModule,
    ThinLayoutModule,
];

@NgModule({
    declarations: [
        LayoutComponent,
    ],
    imports     : [
        MatIconModule,
        MatTooltipModule,
        SwanDrawerModule,
        SharedModule,
        RouterModule,
        ...layoutModules,
    ],
    exports     : [
        LayoutComponent,
        ...layoutModules,
    ],
})
export class LayoutModule
{
}
