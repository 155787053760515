import { HttpClient } from '@angular/common/http';
import { InterceptedRepoAspectsRest } from '@yukawa/chain-base-angular-client';
import { ConfigService } from '@yukawa/chain-base-angular-client/client/config/config.service';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { EntityFilter } from '@yukawa/chain-base-angular-domain/chain/base/core/entity';
import { isEqual } from 'lodash-es';
import { MonoTypeOperatorFunction, Observable, Subject } from 'rxjs';
import { delay, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { Entity } from './entity';
import { EntityKey } from './entity-repository';


export class CachedRepoAspectRest<K extends EntityKey, V extends Entity, F extends EntityFilter> extends InterceptedRepoAspectsRest<K, V, F>
{
    private readonly _filterCache = new Map<F, { subject: Subject<null>; observable: Observable<QueryResult<V>> }>();
    private readonly _keyExtractor: (entity: V) => K;
    private readonly _idCache: {
        [key: string]: Observable<V>;
    };
    private readonly _idCacheRefreshSubjects: {
        [key: string]: Subject<any>;
    };

    public constructor(
        http: HttpClient,
        configService: ConfigService,
        urlKey: string,
        keyExtractor: (entity: V) => K,
    )
    {
        super(http, configService, urlKey);
        this._keyExtractor           = keyExtractor;
        this._idCache                = {};
        this._idCacheRefreshSubjects = {};
    }

    protected findQueryCacheKey(filter: F): F
    {
        return Array.from(this._filterCache.keys()).find(_filter => isEqual(_filter, filter)) || filter;
    }

    protected removeQueryCache(filter: F): boolean
    {
        const queryCacheKey = this._filterCache.get(this.findQueryCacheKey(filter));

        if (queryCacheKey) {
            queryCacheKey.subject.complete();
            this._filterCache.delete(filter);
            return true;
        }

        return false;
    }

    public override query(filter: F): Observable<QueryResult<V>>
    {
        const cacheKey = this.findQueryCacheKey(filter);

        if (!this._filterCache.has(cacheKey)) {
            const subject = new Subject<null>();
            this._filterCache.set(filter, {
                subject   : subject,
                observable: subject
                    .pipe(delay(1500))
                    .pipe(startWith(''))
                    .pipe(switchMap(() => super.query(filter)))
                    .pipe(shareReplay(1)),
            });
        }
        return this._filterCache.get(cacheKey)?.observable as Observable<QueryResult<V>>;
    }

    public override load(key: K): Observable<V>
    {
        if (!this.getRefreshSubject(key)) {
            this._idCacheRefreshSubjects[key.toString()] = new Subject();
        }
        if (!this.getCachedEntityObservable(key)) {
            this._idCache[key.toString()] = this.getRefreshSubject(key)
                .pipe(delay(1500))
                .pipe(startWith(''))
                .pipe(switchMap(() => super.load(key)))
                .pipe(shareReplay(1));
        }
        return this.getCachedEntityObservable(key);
    }

    public override update(entity: V): Observable<V>
    {
        return super.update(entity).pipe(this.refreshOp(entity));
    }

    public override merge(entity: V): Observable<V>
    {
        return super.merge(entity).pipe(this.refreshOp(entity));
    }

    public override put(entity: V): Observable<V>
    {
        return super.put(entity).pipe(this.refreshOp(entity));
    }

    public override edit(entity: V): Observable<V>
    {
        return super.edit(entity).pipe(this.refreshOp(entity));
    }

    public refreshOp(entity: V): MonoTypeOperatorFunction<V>
    {
        return tap(() =>
        {
            this.refreshCaches(entity);
        });
    }

    public refresh(): void
    {
    }

    public refreshCaches(entity: V): void
    {
        this.refreshEntityCache(this._keyExtractor(entity));
        this.refreshQueryCache();
    }

    public getCachedEntityObservable(key: K): Observable<V>
    {
        return this._idCache[key.toString()];
    }

    public getRefreshSubject(key: K): Subject<void>
    {
        return this._idCacheRefreshSubjects[key.toString()];
    }

    public refreshEntityCache(key: K): void
    {
        if (this.getRefreshSubject(key)) {
            this.getRefreshSubject(key).next();
        }
    }

    public refreshQueryCache(filter?: F): void
    {
        if (filter) {
            const queryCacheKey = this.findQueryCacheKey(filter);
            this._filterCache.get(queryCacheKey)?.subject.next(null);
        }
        else {
            this._filterCache.forEach((cache, list) =>
            {
                cache.subject.next(null);
            });
        }
    }
}
