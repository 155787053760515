import { EventArgs } from '@swan/mvvm/model';
import { Nullable } from 'simplytyped';


export class LoginRequestEventArgs extends EventArgs
{
    private readonly _redirectUrl: string;
    private _signInUrl: Nullable<string>;

    constructor(redirectUrl: string)
    {
        super();

        this._redirectUrl = redirectUrl;
    }

    public get redirectUrl(): string
    {
        return this._redirectUrl;
    }

    public get signInUrl(): Nullable<string>
    {
        return this._signInUrl;
    }

    public set signInUrl(value: Nullable<string>)
    {
        this._signInUrl = value;
    }
}
