import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Resource } from '@angular/compiler-cli/src/ngtsc/metadata';
import { Injectable } from '@angular/core';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { FileInfo as IFileInfo, QueryResult } from '@yukawa/chain-base-angular-domain';
import { FileInfoFilter } from '@yukawa/chain-base-angular-domain/chain/base/core/file';
import { lastValueFrom, map, Observable } from 'rxjs';


export interface FileInfo extends IFileInfo
{
    fileName: string;
}

export type ThumbnailDimensions = 'SMALL' | 'MEDIUM' | 'LARGE';

@Injectable()
export class DocsService extends RestAspect
{
    constructor(
        http: HttpClient,
        configService: ConfigService,
    )
    {
        super(http, configService, configService.formatUrl('docsUrl'));
    }

    queryVideos(filter: FileInfoFilter): Observable<QueryResult<FileInfo>>
    {
        return this.query<FileInfo>(this.formatServiceUrl('/docs/files/query'), filter);
    }

    loadVideo(fileId: string): Observable<FileInfo>
    {
        return this.load<FileInfo>(`${this.formatServiceUrl(`/docs/files/${fileId}`)}`);
    }

    getThumbnail(fileId: string, dimensions: ThumbnailDimensions): Observable<FileInfo>
    {
        return this.http.get<FileInfo>(`${this.formatServiceUrl('/thumbnail')}${fileId}?dimension=${dimensions}`);
    }

    downloadVideo(fileId: string): Observable<Resource>
    {
        return this.load<Resource>(`${this.formatServiceUrl(`/docs/download/${fileId}`)}`);
    }

    merge(video: any): Observable<IFileInfo>
    {
        return this.http.post<IFileInfo>(this.formatServiceUrl('/docs/files/merge'), video);
    }

    uploadFile(data: FormData): Observable<any>
    {
        return this.http.post<any>(this.formatServiceUrl('/docs/upload'), data);
    }

    downloadFile(id: string, mime: string): Observable<Blob>
    {
        return this.downloadPDF(this.formatServiceUrl('/docs/download') + id, mime);
    }

    downloadPDF(url: string, mime: string = 'application/pdf'): Observable<Blob>
    {
        let headers = new HttpHeaders();
        headers     = headers.set('Accept', mime);
        return this.http.get(url, { headers: headers, responseType: 'blob' });
    }

    getImageThumbnail(fileId: string, dimensions: ThumbnailDimensions = 'MEDIUM'): Promise<string>
    {
        return lastValueFrom(this.getThumbnail(fileId, dimensions).pipe(
            map(_thumbnail => `data:image/jpg;base64,${_thumbnail.fileContent}`),
        ));
    }
}
