import { EventArgs } from '@swan/mvvm/model';
import { Nullable } from 'simplytyped';
import { Session } from './Session';


export interface ISessionChangeOptions
{
    /**
     * Redirect delay in ms.
     */
    redirectDelay: number;
}

export class SessionChangedEventArgs extends EventArgs
{
    public constructor(
        private _session: Session,
        private _options?: ISessionChangeOptions,
    )
    {
        super();
    }

    public get session(): Session
    {
        return this._session;
    }

    get options(): Nullable<ISessionChangeOptions>
    {
        return this._options;
    }
}
