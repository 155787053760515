import { Exception, TemplateString, TemplateStringValues } from '@awesome-nodes/object';


/**
 * Represents the default exception domain for services.
 */
export class ServiceException extends Exception
{
    public constructor(
        message: TemplateString,
        messageTemplateValues: TemplateStringValues = {},
        innerException?: Error,
        private _responseBody?: JSON)
    {
        super(message);

        ServiceException.setPrototype(this);
    }

    public get responseBody(): JSON | undefined
    {
        return this._responseBody;
    }
}
