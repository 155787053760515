import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ArgumentNullException } from '@awesome-nodes/object';
import { CachedRepoEntityService } from '@swan/lib/content/utils';
import { DownloadParams, ImageFilter } from '@swan/lib/domain';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import moment from 'moment';
import { Observable, switchMap } from 'rxjs';
import { PlainObject } from 'simplytyped';
import { Image } from './image.entity';


@Injectable()
export class ImageService extends CachedRepoEntityService<Image, ImageFilter, number>
{
    constructor(
        http: HttpClient,
        configService: ConfigService)
    {
        super(http, configService, 'mediaUrl', (entity: Image) => entity.imageId);
    }

    /**
     * Read the given file for demonstration purposes
     *
     * @param file
     */
    readAsDataURL(file: File): Promise<string>
    {
        // Return a new promise
        return new Promise((resolve, reject) =>
        {

            // Create a new reader
            const reader = new FileReader();

            // Resolve the promise on success
            reader.onload = (): void =>
            {
                resolve(reader.result as string);
            };

            // Reject the promise on error
            reader.onerror = (e): void =>
            {
                reject(e);
            };

            // Read the file as the
            reader.readAsDataURL(file);
        });
    }

    /**
     * Add new File
     */
    upload(image: Image, file?: File): Observable<Image>
    {
        const formData = new FormData();
        if (file) {
            formData.append('file', file, file.name);
        }

        const json = JSON.stringify(image.toJson());
        const blob = new Blob([json], {
            type: 'application/json',
        });
        formData.append('image', blob);

        if (file) {
            const uploadObservable: Observable<Image> = this.repository.add(this.http.post<Image>(
                this.formatServiceUrl('/upload'),
                formData,
            ), image);

            return image.imageId
                ? this.delete(image).pipe(switchMap(() => uploadObservable))
                : uploadObservable;
        }
        else {
            return this.repository.update(this.http.post<Image>(this.formatServiceUrl('/update'), formData), image);
        }

    }

    public uploadAvatar(image: Image, file: File): Observable<Image>
    {
        if (image.imageId) {
            this.repository.remove(image.imageId);
        }

        const formData = new FormData();
        if (!file) {
            throw new ArgumentNullException('', file);
        }

        formData.append('file', file, file.name);

        return this.repository.update(this.http.post<Image>(this.uploadUrl(image), formData), image);
    }

    public uploadUrl(image: Image): string
    {
        return image.toUrl(`${this.formatServiceUrl()}/uploadMainImageByRelated`).toString();
    }

    /**
     * Returns a browser cache resistant download url for the specified image.
     *
     * @param image
     * @param params
     */
    public downloadUrl(image: Image, params?: DownloadParams): string
    {
        return image.toUrl(`${this.formatServiceUrl()}/download`, {
            ...params,
            timestamp: moment(image.change.date).unix(),
        }).toString();
    }

    protected createInstanceFrom(json: PlainObject, other?: PlainObject): Image
    {
        return new Image(json);
    }

    protected override keyForEntityCache(json: Image): string
    {
        return json.key;
    }
}
