import { Injectable } from '@angular/core';
import { TemplateString } from '@swan/mvvm/model';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import { Nullable } from 'simplytyped';


export type SessionTokenItems = 'token' | 'user';

@Injectable({ providedIn: 'root' })
export class SessionStoreService
{
    public constructor(
        private _configService: ConfigService,
    )
    {
    }

    public get(item: SessionTokenItems, defaultValue?: string): Nullable<string>
    {
        return localStorage.getItem(this.getKey(item)) ?? defaultValue;
    }

    public set(item: SessionTokenItems, value: string): void
    {
        return localStorage.setItem(this.getKey(item), value);
    }

    public getJSON<T extends object>(item: SessionTokenItems): Nullable<T>
    {
        const tokenJSON = this.get(item);

        return tokenJSON ? JSON.parse(tokenJSON) : null;
    }

    public setJSON<T extends object>(item: SessionTokenItems, value: T): void
    {
        this.set(item, JSON.safeStringify(value, 0));
    }

    public remove(item: SessionTokenItems): void
    {
        localStorage.removeItem(this.getKey(item));
    }

    public clear(): void
    {
        this.remove('token');
        this.remove('user');
    }

    private getKey(item: SessionTokenItems): string
    {
        return TemplateString`${'store'}=${'item'}`({
            store: this._configService.getValue('sessionStoreKey'),
            item : this._configService.getValue('sessionStorePrefix') + item,
        });
    }
}
