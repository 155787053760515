import { TooltipDirection } from '@swan/lib/components';
import { Nullable } from 'simplytyped';


export interface OverlayContent
{
    type: 'heading' | 'tooltip';
    text: string;
    classes?: string;
}

export interface HeadingOverlayContent extends OverlayContent
{
    // Heading
    color?: string;
    fontSize?: number;
    position?: 'left' | 'top' | 'right' | 'bottom';
}

export interface TooltipOverlayContent extends OverlayContent
{
    direction: TooltipDirection;
    toolTipIcon: string;
    left?: string;
    top?: string;
    right?: string;
    bottom?: string;
}

export interface Chapter<T = OverlayContent>
{
    duration: number;
    name: string;
    progress: number;
    overlayContent?: T;
}

export class ChapterListModel
{
    private readonly _items = new Array<Chapter>();

    constructor(...items: Chapter[])
    {
        this._items = items;
    }

    public get items(): Chapter[]
    {
        return this._items;
    }

    public get currentChapter(): Chapter
    {
        let chapter = this._items.find(item => item.progress > 0 && item.progress < 100);
        if (!chapter) {
            chapter = this._items.find(item => item.progress === 0);
        }
        if (!chapter) {
            chapter = this._items[this._items.length - 1];
        }
        return chapter;
    }

    public get previousChapter(): Nullable<Chapter>
    {
        const index = this._items.indexOf(this.currentChapter);
        return index > 0 ? this._items[index - 1] : null;
    }

    public get lastChapter(): Chapter
    {
        return this._items[this._items.length - 1];
    }

    public add(name: string, duration: number, overlayContent?: HeadingOverlayContent | TooltipOverlayContent): void
    {
        this._items.push({
            name,
            duration,
            progress: 0,
            overlayContent,
        });
    }

    public update(time: number): void
    {
        let chapterTime = 0;
        for (const _chapter of this._items) {
            if (time < chapterTime) {
                _chapter.progress = 0;
                continue;
            }

            chapterTime += _chapter.duration;

            if (time > chapterTime) {
                _chapter.progress = 100;
            }
            else {
                const start       = chapterTime - _chapter.duration;
                const position    = time - start;
                _chapter.progress = Math.round(position / _chapter.duration * 100);
            }
        }
    }

    public seekTo(chapter: Chapter, end: boolean = false): number
    {
        const chapterIndex = this._items.indexOf(chapter);
        return this._items.reduce((previous, next, index) =>
        {
            if (end ? index <= chapterIndex : index < chapterIndex) {
                previous += next.duration;
            }
            return previous;
        }, 0);
    }
}
