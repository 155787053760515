import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { SwanTooltipDirective } from './swan-tooltip.directive';
import { TooltipComponent } from './tooltip.component';


@NgModule({
    declarations   : [
        TooltipComponent,
        SwanTooltipDirective,
    ],
    imports        : [
        CommonModule,
        MatIconModule,
        TranslocoModule,
    ],
    exports        : [
        TooltipComponent,
        SwanTooltipDirective,
    ],
    entryComponents: [TooltipComponent],
})
export class TooltipModule
{
}
