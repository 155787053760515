import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { VideoRecordService } from '../video-record.service';


@Injectable()
export class VideoDetailsGuardService implements CanActivate
{
    public constructor(
        private readonly _videoRecordService: VideoRecordService,
    )
    {
    }

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        return this._videoRecordService.file != null;
    }

}
