import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityRepository } from '@swan/lib/content/utils';
import { PlayerPosition as IPlayerPosition } from '@swan/lib/domain';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { map, Observable } from 'rxjs';
import { Content } from './content.model';
import { PlayerPosition } from './player.position.entity';


@Injectable()
export class PlayerPositionService extends RestAspect
{
    private readonly _repository = new EntityRepository<PlayerPosition>({
        createInstanceFrom: (json: IPlayerPosition): PlayerPosition => new PlayerPosition(json),
        keyForEntityCache : (json: PlayerPosition): string => json.key,
    });

    constructor(
        http: HttpClient,
        configService: ConfigService,
    )
    {
        super(http, configService, configService.formatUrl('playerPositionUrl'));
    }

    public get repository(): EntityRepository<PlayerPosition>
    {
        return this._repository;
    }

    public getPosition(content: Content): Observable<PlayerPosition>
    {
        return this._repository.get(this.http.get<PlayerPosition>(
            this.formatServiceUrl(`/position/${content.contentId}`)).pipe(
            map((response) =>
            {
                if (!response) {
                    return {
                        change   : {},
                        contentId: content.contentId,
                        second   : 0,
                        username : '',
                    } as PlayerPosition;
                }

                return response;
            }),
        ), content.contentId.toString());
    }
}
