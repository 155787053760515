import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree,
} from '@angular/router';
import { Nullable } from 'simplytyped';
import { LoginRequestEventArgs } from './auth/LoginRequestEventArgs';
import { SessionService } from './SessionService';


@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild, CanLoad
{
    protected _loginRequestEventArgs: Nullable<LoginRequestEventArgs>;

    public constructor(protected _sessionService: SessionService)
    {
    }

    public get lastForbiddenRoute(): Nullable<string>
    {
        return this._loginRequestEventArgs?.redirectUrl;
    }

    public async canActivate(
        routeSnapshot: ActivatedRouteSnapshot,
        statesSnapShot: RouterStateSnapshot): Promise<boolean>
    {
        return await this.validate(statesSnapShot.url);
    }

    public async canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean | UrlTree>
    {
        return this.validate(state.url);
    }

    public canLoad(
        route: Route,
        segments: UrlSegment[]): Promise<boolean | UrlTree>
    {
        return this.validate('/');
    }

    protected async validate(redirectUrl: string): Promise<boolean>
    {
        if (!await this._sessionService.validate()) {
            if (this.lastForbiddenRoute === redirectUrl) {

                if (redirectUrl.startsWith(this._loginRequestEventArgs?.signInUrl as string)) {
                    return true;
                }
            }

            this._loginRequestEventArgs = new LoginRequestEventArgs(redirectUrl);
            await this._sessionService.auth.loginRequest.invoke(this._loginRequestEventArgs);

            return false;
        }

        return true;
    }
}
