import {
    Content as IContent,
    ContentInterest,
    ContentStatistics,
    ContentStatus,
    ContentStreamType,
    ContentView as IContentView,
    PublicProfile,
} from '@swan/lib/domain';
import { Change, Info } from '@yukawa/chain-base-angular-domain';


export interface CreateInfo extends Info
{
    name: string;
    desc?: string; // Text Area
    shortName?: string;
}

export interface Content extends IContent
{
    author: PublicProfile;
    change: Required<Change>;
    contentId: number;
    created: Required<Change>;
    duration: number;
    extAssetId: string;
    extPlaybackId: string;
    fileName?: string;
    fileSize: number;
    info: CreateInfo;
    interests: Required<ContentInterest>[];
    language: string;
    mime: string;
    provider: string;
    recordingDate: Date;
    splashScreen: string;
    stats: Required<ContentStatistics>;
    status: ContentStatus;
    thumbnail: string;
    type: string;
}

export interface ContentView extends IContentView
{
    content: Content;
    favorite: boolean;
    liked: boolean;
}

export interface UploadContent extends IContent
{
    contentId: number;
    fileName: string;
}

/**
 * Enumerates video list types using the corresponding {@link ContentStreamType} as value.
 */
export enum VideoListTypes
{
    trending    = 'TRENDING',
    watchlist   = 'VIEWED',
    favorites   = 'FAVORITE',
    uploaded    = 'UPLOADED',
    recommended = 'RECOMMENDED',
    followed    = 'FOLLOWED',
    related     = 'RELATED',
    user        = 'USER',
    search      = 'SEARCH',
    playback    = 'PLAYBACK',
    interest    = 'INTEREST'
}
