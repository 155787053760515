import { Entity } from '@swan/lib/content/utils';
import { DownloadSize } from '@swan/lib/domain';
import { AppInjector } from '@swan/lib/shared';
import { Change, Info } from '@yukawa/chain-base-angular-domain';
import { cloneDeep } from 'lodash-es';
import { Nullable } from 'simplytyped';
import { Image } from './image.entity';
import { ImageService } from './image.service';
import { IInterest } from './interests.types';


export class Interest extends Entity<IInterest> implements IInterest
{
    public static readonly i18nDefaults: Record<string, Info> = {
        de: { id: '', name: '', shortName: '', desc: '' },
        en: { id: '', name: '', shortName: '', desc: '' },
    };

    public change: Change             = null as never;
    public children: Interest[]       = null as never;
    public contentAssignments: number = null as never;
    public created: Change            = null as never;
    public i18n: Record<string, Info> = null as never;
    public interestId: string         = null as never;
    public parent?: Interest;
    public profileAssignments: number = null as never;
    public position: number = null as never;

    public selected: boolean = false;

    private _desc: string      = null as never;
    private _name: string      = null as never;
    private _shortName: string = null as never;

    constructor(initialData: IInterest, parent?: Interest)
    {
        super();

        this.parent = parent;

        if (!initialData.i18n) {
            initialData.i18n = cloneDeep(Interest.i18nDefaults);
        }
        else {
            for (const _key in Interest.i18nDefaults) {
                if (!Interest.i18nDefaults.hasOwnProperty(_key)) {
                    continue;
                }

                if (!initialData.i18n.hasOwnProperty(_key)) {
                    initialData.i18n[_key] = cloneDeep(Interest.i18nDefaults[_key]);
                }
            }
        }

        this.i18n = initialData.i18n;

        this.updateFromJson(initialData);
    }

    //region Public Properties
    /* eslint-disable @typescript-eslint/member-ordering */

    public get key(): string
    {
        return this.interestId;
    }

    public get desc(): string
    {
        return this._desc;
    }

    public set desc(value: string)
    {
        this._desc           = value;
        this.i18n['en'].desc = value;
    }

    public get name(): string
    {
        return this._name;
    }

    public set name(value: string)
    {
        this._name           = value;
        this.i18n['en'].name = value;
    }

    public get shortName(): string
    {
        return this._shortName;
    }

    public set shortName(value: string)
    {
        this._shortName           = value;
        this.i18n['en'].shortName = value;
    }

    public get image(): Nullable<Image>
    {
        return Array.from(AppInjector.get(ImageService).repository.store.values())
            .find(_interest => _interest.related.id === this.interestId) as Image;
    }

    public get imageUrl(): Nullable<string>
    {
        const image = this.image;

        if (!image) {
            return null;
        }

        return AppInjector.get(ImageService).downloadUrl(image, {
            size: DownloadSize.medium,
        });
    }

    /* eslint-enable @typescript-eslint/member-ordering */

    //endregion

    public static new(parent?: Interest): Interest
    {
        return new Interest({
            change            : {
                user : '',
                date : undefined,
                notes: '',
            },
            children          : [],
            contentAssignments: 0,
            created           : {
                user : '',
                date : undefined,
                notes: '',
            },
            desc              : '',
            i18n              : cloneDeep(Interest.i18nDefaults),
            interestId        : '',
            name              : '',
            profileAssignments: 0,
            shortName         : '',
            position          : 100
        }, parent);
    }

    public toJson(): IInterest
    {
        return this.toJsonWithKeys([
            'children',
            'desc',
            'i18n',
            'interestId',
            'name',
            'parent',
            'shortName',
            'position',
        ], {
            parent  : () => (this.parent ? { interestId: this.parent.interestId } : undefined),
            children: () => this.children.map(child => child.toJson()),
        });
    }

    public updateFromJson(data: IInterest): void
    {
        this.setFromJson(data, [
                'change',
                'children',
                'contentAssignments',
                'created',
                'desc',
                'i18n',
                'interestId',
                'name',
                'profileAssignments',
                'shortName',
                'position',
            ],
            undefined, {
                children: (children: Array<IInterest>) => children
                    ? children.map(child => new Interest(child, this))
                    : [],
            },
        );
    }
}
