import { Directive, ElementRef, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { Subject, takeUntil } from 'rxjs';


@Directive({
    selector: '[matMultilineTab]',
})
export class MatMultilineTabDirective implements OnInit, OnDestroy
{
    @HostBinding('class.mat-tab-multiline') class = true;

    private readonly onDestroy = new Subject();
    private readonly resizeObserver: ResizeObserver;
    private resizeTimeout!: number;

    constructor(
        private elementRef: ElementRef,
        private matTabGroup: MatTabGroup,
    )
    {
        this.matTabGroup.selectedTabChange.pipe(
            takeUntil(this.onDestroy),
        ).subscribe(() => this.updateInkBarPosition());

        this.resizeObserver = new ResizeObserver(() =>
        {
            if (this.resizeTimeout) {
                clearTimeout(this.resizeTimeout);
            }
            this.resizeTimeout = setTimeout(() => this.updateInkBarPosition(), 100);
        });
    }

    ngOnInit(): void
    {
        this.resizeObserver.observe(
            this.elementRef.nativeElement.querySelector('.mat-tab-header:first-child'),
        );
    }

    ngOnDestroy(): void
    {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.resizeObserver && this.resizeObserver.disconnect();

        this.onDestroy.next(null);
        this.onDestroy.complete();
    }

    private updateInkBarPosition(): void
    {
        const headerElement: HTMLElement = this.elementRef.nativeElement.querySelector(
            '.mat-tab-header:first-child',
        );
        const activeTabElement           = headerElement.querySelector(
            '.mat-tab-label-active',
        ) as HTMLElement;

        const inkBarElement = headerElement.querySelector('.mat-ink-bar') as HTMLElement;
        const listElement   = headerElement.querySelector('.mat-tab-list') as HTMLElement;
        const bottom        = listElement.offsetHeight - (activeTabElement.offsetTop + activeTabElement.offsetHeight);

        inkBarElement.style.bottom = bottom + 'px';
        inkBarElement.style.left   = activeTabElement.offsetLeft + 'px';
    }
}
