import { Injector } from '@angular/core';
import { ContentFilter, ContentStreamType } from '@swan/lib/domain';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { FileInfoFilter } from '@yukawa/chain-base-angular-domain/chain/base/core/file';
import { lastValueFrom, map } from 'rxjs';
import { ContentVideoFile } from './content-video-file.model';
import { ContentViewService } from './content-view.service';
import { Content } from './content.model';
import { FileInfo } from './docs.service';
import { FavoriteService } from './favorite.service';
import { IVideoFile, VideoList } from './video-list.model';


export class FavoriteVideoList<TInfo extends FileInfo | Content, TFile extends IVideoFile<TInfo>>
    extends VideoList<TInfo, FileInfoFilter | ContentFilter, TFile>
{
    public constructor(
        injector: Injector,
        filter: FileInfoFilter | ContentFilter = {},
    )
    {
        super(injector, { ...filter, stream: ContentStreamType.FAVORITE });
    }

    public async query(): Promise<QueryResult<TFile>>
    {
        const favoritesResult = await lastValueFrom(this.injector.get(FavoriteService).query({
            orderDir: 'DESC',
            orderBy : 'change.date',
        }));
        const content         = await lastValueFrom(this.injector.get(ContentViewService).queryEnriched({
            ...this.filter,
            stream: (this.filter as ContentFilter).stream,
        }));

        let items = content.items.filter(item => favoritesResult.items.findIndex(favItem => item.id === favItem.contentId) > -1);
        items     = favoritesResult.items.map(favorite => items.find(item => item.id === favorite.contentId) as ContentVideoFile);
        items     = items.filter(item => item != null);

        return {
            ...content,
            items,
            rows: items.length,
        } as unknown as QueryResult<TFile>;
    }

    public override async add(file: TFile): Promise<void>
    {
        await lastValueFrom(this.injector.get(FavoriteService).add(file.fileInfo).pipe(
            map((response) =>
            {
                this.items.splice(0, 0, file);
                return response;
            }),
        ));
    }

    public override async remove(file: TFile): Promise<void>
    {
        await lastValueFrom(this.injector.get(FavoriteService).remove(file.fileInfo).pipe(
            map((response) =>
            {
                const index = this.items.findIndex(item => item.fileId === file.fileId);
                this.items.splice(index, 1);

                return response;
            }),
        ));
    }
}
