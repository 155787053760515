import { Nullable } from 'simplytyped';
import { Entity } from './entity';


export class EntityCache<T extends Entity>
{
    private globalCache: Map<string, T> = new Map<string, T>();
    private _store: Map<string, T>      = this.globalCache;

    public get store(): Map<string, T>
    {
        return this._store;
    }

    /**
     * This allows you to specify the source of a cache, which may change from a global source to
     * a cache contained within another entity (for example, a cache of comments in a video).
     *
     * @param value  the new source to be used for the cache. The global cache will be used if
     *                this is null or undefined.
     */
    public set store(value: Nullable<Map<string, T>>)
    {
        if (value) {
            this._store = value;
        }
        else {
            this._store = this.globalCache;
        }
    }

    /**
     * Checks if an entity exists for a given key within the current cache.
     *
     * @param key Key of entity to check for.
     * @returns true if the entity with key is in the cache
     */
    public has(key: string): boolean
    {
        return this._store.has(key);
    }

    /**
     * Store an entity in the cache
     *
     * @param key key of the entity to store
     * @param entity the entity object to store in the cache
     */
    public add(key: string, entity: T): void
    {
        this._store.set(key, entity);
    }

    /**
     * Read a given entity from the cache.
     *
     * @param key key of entity to read from cache
     */
    public get(key: string): Nullable<T>
    {
        return this._store.get(key);
    }

    /**
     * Returns an entity for the given key.
     *
     * @param key key of entity to store in cache
     * @returns `true` if an element in the Map object existed and has been removed, or `false` if the element does not exist.
     */
    public remove(key: string): boolean
    {
        return this._store.delete(key);
    }

    /**
     * Removes all elements from the cache.
     */
    public clear(): void
    {
        this._store.clear();
    }
}
