import { Injector, Type } from '@angular/core';
import { FactoryProviderConfig } from '@awesome-nodes/injection-factory';
import { ServiceProviderBase as Base } from '@awesome-nodes/mvvm/providers';
import { ServiceBase } from '@awesome-nodes/mvvm/services';


export abstract class ServiceProviderBase<T extends ServiceBase<unknown>> extends Base<T>
{
    protected constructor(protected _parentInjector: Injector)
    {
        super();
    }

    public inject(provider: FactoryProviderConfig<T>): T
    {
        const injector = Injector.create({
            providers: [
                {
                    provide   : provider.provide,
                    useFactory: provider.useFactory,
                    deps      : provider.deps,
                },
            ],
            parent   : this._parentInjector,
        });

        return injector.get(provider.provide as Type<T>);
    }
}
