import { Injector } from '@angular/core';
import { timeFormat } from '@swan/lib/content/utils';
import { Profile } from '@swan/lib/domain';
import { InterestInfo, InterestInfoService } from '@swan/lib/profile';
import { AppInjector } from '@swan/lib/shared';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { FileInfoFilter } from '@yukawa/chain-base-angular-domain/chain/base/core/file';
import { lastValueFrom } from 'rxjs';
import { DocsService, FileInfo } from './docs.service';
import { FavoriteVideoList } from './favorite-video-list.model';
import { IVideoFile, VideoList } from './video-list.model';


export class DocsVideoList<TFile extends IVideoFile<FileInfo>>
    extends VideoList<FileInfo, FileInfoFilter, TFile>
{
    public constructor(
        injector: Injector,
        filter: FileInfoFilter = {
            parentId: 'ccfaee4f-ed55-4fb6-931f-7b6bba5337d9',
            orderDir: 'ASC',
            orderBy : 'fileName',
        },
    )
    {
        super(
            injector,
            filter,
            new FavoriteVideoList<FileInfo, TFile>(
                injector,
                // filter,
            ),
        );
    }

    public override get favorites(): FavoriteVideoList<FileInfo, TFile>
    {
        return super.favorites as FavoriteVideoList<FileInfo, TFile>;
    }

    protected override async query(): Promise<QueryResult<TFile>>
    {
        await this.favorites.load();
        const fileInfoQueryResult = await lastValueFrom(this.injector.get(DocsService).queryVideos(this.filter));
        return {
            ...fileInfoQueryResult,
            items: fileInfoQueryResult.items.map(this.mapFileInfo.bind(this)),
            rows : fileInfoQueryResult.rows,
        } as unknown as QueryResult<TFile>;
    }

    protected mapFileInfo(
        fileInfo: FileInfo,
        index: number,
        array: Array<FileInfo>,
        isFavorite = this.favorites && !!this.favorites.items.find(f => f.fileId === fileInfo.fileId),
    ): TFile
    {
        const file: IVideoFile<FileInfo> = {
            id       : 0,
            fileId   : fileInfo.fileId as string,
            duration : timeFormat(fileInfo.duration as number),
            author   : fileInfo.author
                ? { displayName: fileInfo.author.name, username: fileInfo.author.name } as Profile
                : { displayName: 'admin', username: 'admin' } as Profile,
            info     : {
                name: fileInfo.fileName.indexOf('.')
                    ? fileInfo.fileName.substring(0, fileInfo.fileName.indexOf('.'))
                    : fileInfo.fileName,
                desc: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.`,
            },
            interests: (fileInfo.tags
                ? fileInfo.tags.map(interest => AppInjector.get(InterestInfoService)
                    .findInterest(interest))
                : []) as Array<InterestInfo>,
            fileInfo,
            favorite : isFavorite,
            liked    : false,
        };

        return file as TFile;
    }
}
