import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { IEnvironment } from '@swan/lib/config';
import { Observable, ReplaySubject } from 'rxjs';
import { ISwanAlert, ISwanAlertConfig } from './alert.types';


@Injectable({
    providedIn: 'root',
})
export class SwanAlertService
{
    private readonly _onDismiss = new ReplaySubject<string>(1);
    private readonly _onShow    = new ReplaySubject<string | { name: string; config: ISwanAlertConfig }>(1);

    /**
     * Constructor
     */
    constructor(
        private _translocoService: TranslocoService,
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for onDismiss
     */
    get onDismiss(): Observable<any>
    {
        return this._onDismiss.asObservable();
    }

    /**
     * Getter for onShow
     */
    get onShow(): Observable<string | { name: string; config: ISwanAlertConfig }>
    {
        return this._onShow.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Generates an alert configuration from the provided error object.
     * Uses translation keys `ERROR.NO_INTERNET_CONNECTION` and `ERROR.UNKNOWN`.
     *
     * @param error
     * @param  environment
     */
    generateErrorAlert(error: Error, environment: IEnvironment): ISwanAlert
    {
        let message = this._translocoService.translate(
            !window.navigator.onLine
                ? 'ERROR.NO_INTERNET_CONNECTION'
                : 'ERROR.UNKNOWN',
        );

        if (!environment.production) {
            message += `\n\n${error.message}`;

            if (error instanceof HttpErrorResponse) {
                message += `\n\n${error.error.text}`;
            }
        }

        return {
            type: 'error',
            message,
        };
    }

    /**
     * Dismiss the alert
     *
     * @param name
     */
    dismiss(name: string): void
    {
        // Return if the name is not provided
        if (!name) {
            return;
        }

        // Execute the observable
        this._onDismiss.next(name);
    }

    /**
     * Show the dismissed alert
     *
     * @param name
     * @param config
     */
    show(name: string, config?: ISwanAlertConfig): void
    {
        // Return if the name is not provided
        if (!name) {
            return;
        }

        // Execute the observable
        this._onShow.next(!config ? name : { name, config });
    }
}
