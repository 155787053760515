import { ObjectBase } from '@awesome-nodes/object/data';
import { Account as IAccount, RoleContext } from '@yukawa/chain-security-angular-core';
import { AccountStatus } from './AccountStatus';
import { Credentials } from './Credentials';


export class Account extends ObjectBase implements IAccount
{
    private _credentials: Credentials;
    private _details: { [p: string]: object };
    private _roleContexts: RoleContext[];
    private _status: AccountStatus;

    public constructor(account: IAccount)
    {
        super();

        this._credentials  = new Credentials(account.credentials as Credentials);
        this._details      = account.details ?? {};
        this._roleContexts = account.roleContexts ?? [];
        this._status       = new AccountStatus(account.status as AccountStatus);
    }

    //region Public Properties

    public get credentials(): Credentials
    {
        return this._credentials;
    }

    public set credentials(value: Credentials)
    {
        this._credentials = value;
    }

    public get details(): { [p: string]: object }
    {
        return this._details;
    }

    public set details(value: { [p: string]: object })
    {
        this._details = value;
    }

    public get roleContexts(): RoleContext[]
    {
        return this._roleContexts;
    }

    public set roleContexts(value: RoleContext[])
    {
        this._roleContexts = value;
    }

    public get status(): AccountStatus
    {
        return this._status;
    }

    public set status(value: AccountStatus)
    {
        this._status = value;
    }

    //endregion

    public override toJSON<T = this>(): IAccount
    {
        return super.toJSON(
            'credentials',
            'details',
            'roleContexts',
            'status',
        );
    }
}
