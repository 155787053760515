import { ObjectBase } from '@awesome-nodes/object/data';
import { IAuthDetails, IAuthRefreshDetails, IAuthTokenResponse, IWebTokenPayload, TokenType } from '../../services';
import { DateUtils } from '../../services/DateUtils';
import { WebToken } from '../jwt';


export class AuthToken extends ObjectBase implements IAuthTokenResponse
{
    private readonly _access_expires: string;
    private readonly _access_token: string;
    private readonly _details: IAuthDetails | IAuthRefreshDetails;
    private readonly _expires_in: number;
    private readonly _refresh_expires: string;
    private readonly _refresh_token: string;
    private readonly _scope: Array<string>;
    private readonly _token_type: TokenType;
    private readonly _username: string;

    private readonly _accessToken: WebToken<IWebTokenPayload>;
    private readonly _refreshToken: WebToken<IWebTokenPayload>;

    public constructor(authTokenResponse: IAuthTokenResponse)
    {
        super();

        this._access_expires  = authTokenResponse.access_expires;
        this._access_token    = authTokenResponse.access_token;
        this._details         = authTokenResponse.details;
        this._expires_in      = authTokenResponse.expires_in;
        this._refresh_expires = authTokenResponse.refresh_expires;
        this._refresh_token   = authTokenResponse.refresh_token;
        this._scope           = authTokenResponse.scope;
        this._token_type      = authTokenResponse.token_type;
        this._username        = authTokenResponse.username;

        this._accessToken  = new WebToken<IWebTokenPayload>(this._access_token);
        this._refreshToken = new WebToken<IWebTokenPayload>(this._refresh_token);
    }

    //region IAuthTokenResponse Members

    public get access_expires(): string
    {
        return this._access_expires;
    }

    public get access_token(): string
    {
        return this._access_token;
    }

    public get details(): IAuthDetails | IAuthRefreshDetails
    {
        return this._details;
    }

    public get expires_in(): number
    {
        return this._expires_in;
    }

    public get refresh_expires(): string
    {
        return this._refresh_expires;
    }

    public get refresh_token(): string
    {
        return this._refresh_token;
    }

    public get scope(): Array<string>
    {
        return this._scope;
    }

    public get token_type(): TokenType
    {
        return this._token_type;
    }

    public get username(): string
    {
        return this._username;
    }

    //endregion

    //region Public Properties

    public get accessToken(): WebToken<IWebTokenPayload>
    {
        return this._accessToken;
    }

    public get refreshToken(): WebToken<IWebTokenPayload>
    {
        return this._refreshToken;
    }

    public get access_expired(): boolean
    {
        const now           = new Date().getTime();
        const accessExpires = DateUtils.parseDateString(this._access_expires).getTime();
        return now > accessExpires;
    }

    public get refresh_expired(): boolean
    {
        const now            = new Date().getTime();
        const refreshExpires = DateUtils.parseDateString(this._refresh_expires).getTime();
        return now > refreshExpires;
    }

    //endregion

    public override toJSON(): IAuthTokenResponse
    {
        return super.toJSON(
            'access_expires',
            'access_token',
            'details',
            'expires_in',
            'refresh_expires',
            'refresh_token',
            'scope',
            'token_type',
            'username',
        ) as IAuthTokenResponse;
    }
}
