import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
    ComponentRef,
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { TooltipComponent } from './tooltip.component';


@Directive({
    selector: '[swanTooltip]',
    exportAs: 'swanTooltip',
})
export class SwanTooltipDirective implements OnInit, OnDestroy
{
    @Input('swanTooltip') text = '';

    @Input()
    toggleTooltip = new EventEmitter<boolean>();

    @Input()
    tooltipIcon!: string;

    private _overlayRef!: OverlayRef;
    private _timeout: number | null = null;
    private _mouseX: number         = 0;
    private _mouseY: number         = 0;

    constructor(
        private overlayPositionBuilder: OverlayPositionBuilder,
        private elementRef: ElementRef,
        private overlay: Overlay,
    )
    {
        document.addEventListener('mousemove', this.onMouseUpdate, false);
        document.addEventListener('mouseenter', this.onMouseUpdate, false);
    }

    get elementHovered(): boolean
    {
        return document.elementsFromPoint(this._mouseX, this._mouseY)
            .some(element => element === this.elementRef.nativeElement);
    }

    onMouseUpdate = (e: MouseEvent): void =>
    {
        this._mouseX = e.pageX;
        this._mouseY = e.pageY;
        if (this._timeout == null) {
            this._timeout = setInterval(this.update.bind(this), 100);
        }
    };

    @HostListener('mouseover')
    public show(): void
    {
        if (this._overlayRef.hasAttached()) {
            return;
        }
        // Create tooltip portal
        const tooltipPortal = new ComponentPortal(TooltipComponent);

        // Attach tooltip portal to overlay

        this._overlayRef.detach();
        const tooltipRef: ComponentRef<TooltipComponent> = this._overlayRef.attach(tooltipPortal);

        // Pass content to tooltip component instance
        tooltipRef.instance.text = this.text;
        tooltipRef.instance.icon = this.tooltipIcon;

        clearTimeout(this._timeout as number);
        this._timeout = null;
    }

    //@HostListener('hover')
    public hide(): void
    {
        if (!this._overlayRef.hasAttached() && !this.elementHovered) {
            return;
        }
        this._overlayRef.detach();
        clearTimeout(this._timeout as number);
        this._timeout = null;
    }

    ngOnInit(): void
    {
        const positionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(this.elementRef)
            .withPositions([
                {
                    originX : 'start',
                    originY : 'center',
                    overlayX: 'end',
                    overlayY: 'top',
                },
            ]);

        this._overlayRef = this.overlay.create({ positionStrategy });

        this.toggleTooltip.subscribe(this.toggle.bind(this));

        this._timeout = setInterval(this.update.bind(this), 100);
    }

    public ngOnDestroy(): void
    {
        if (this._timeout) {
            clearTimeout(this._timeout);
        }

        document.removeEventListener('mousemove', this.onMouseUpdate, false);
        document.removeEventListener('mouseenter', this.onMouseUpdate, false);
    }

    private update(): void
    {
        if (this.elementHovered) {
            this.show();
        }
        else {
            this.hide();
        }
    }

    private toggle(toggle: boolean): void
    {
        if (toggle) {
            this.show();
        }
        else {
            this.hide();
        }
    }
}
