/**
 * A mapping function used to map data into/out of an entity.
 *
 * @param object  The entity object or json to get the data from.
 * @returns       The data used to represent the keyed value from object.
 */
import { Optional, PlainObject, StringKeys } from 'simplytyped';


type KeyMaps<T> = Record<StringKeys<T>, (value: string) => unknown>;
type KeyMap<T> = Optional<KeyMaps<T>, keyof KeyMaps<T>>;
type ValueMaps<T> = Record<StringKeys<T>, object | boolean | number | string | symbol | ((value: unknown) => unknown)>;
type ValueMap<T> = Optional<ValueMaps<T>, keyof ValueMaps<T>>;

/**
 * Entity class is used to represent an object within the main application model. This
 * entity can be transferred to/from the server, and there are functions to assist with
 * copying the object to a data transfer format to send to the server, and functions to
 * assist with updating the object based upon responses from the server.
 *
 * When implementing an Entity you need to:
 * 1. Override @method toJson to convert the entity to json. This can use
 *    method {@link toJsonWithKeys} to indicate the values to copy.
 * 2. Override @method updateFromJson to update the entity with data from a json object.
 *    This is the inverse of @method toJson and can be @method setFromJson.
 * 3. Override @method key to indicate a unique value used to cache the entity
 *    (if/when cached)
 * 4. Implement a EntityService to handle transmission of this object to the server
 *    for CRUD operations.
 */
export abstract class Entity<T extends PlainObject = PlainObject, TView = T>
{
    /**
     * Construct an Entity object
     *
     * @param initialData An optional object storing the data to initialise the Entity with, calls @method updateFromJson with the data.
     */
    protected constructor(initialData?: TView)
    {
        if (initialData) {
            this.updateFromJson(initialData);
        }
    }

    /**
     * Gets the unique key which represents the Entity
     * For example, an id: number, or for Task Entity, project ID and task Definition ID.
     *
     * @returns string containing the unique key value
     */
    public abstract get key(): string;

    /**
     * Update the entity with data from the passed in json object. This is used when updated
     * details are fetched from the server. This method takes care of copying data by key
     * from the json data to the entity itself.
     *
     * @param data  the new data to be stored within the entity
     * @param keys  the keys of the data to map
     * @param ignoredKeys
     * @param maps  an optional map of functions that are called to translate
     *              specific values from the json where a straight data copy is not
     *              appropriate/possible.
     */
    protected setFromJson<TData = T>(
        data: TData,
        keys: StringKeys<TData>[],
        ignoredKeys?: StringKeys<TData>[],
        maps?: ValueMap<TData>): void
    {
        keys.forEach((key) =>
        {
            if (maps && maps[key]) {
                const map                  = maps[key];
                (this as PlainObject)[key] = typeof map === 'function' ? map(data[key]) : map;
            }
            else if ((ignoredKeys && ignoredKeys.indexOf(key) < 0) || !ignoredKeys) {
                (this as PlainObject)[key] = data[key];
            }
        });
    }

    /**
     * Copy the data within the entity into a json object and return. This is used when
     * data needs to be copied from the entity and sent to the server. Data is copied from
     * the entity for each of the @param keys which are directly copied from the entity
     * into the json. Where data cannot be directly copied, the @param maps can be
     * used to provide key based mapping functions to translate the data.
     *
     * @param keys  an optional map of functions that are called to translate
     *              specific values from the entity where a straight data copy is not
     *              appropriate/possible.
     * @param maps
     */
    protected toJsonWithKeys(keys: StringKeys<T>[], maps?: KeyMap<T>): T
    {
        const json: PlainObject = {};
        keys.forEach((key) =>
        {
            if (maps && maps[key]) {
                json[key] = maps[key](key);
            }
            else {
                json[key] = (this as PlainObject)[key];
            }
        });
        return json as T;
    }

    /**
     * Convert the entity object to json.
     *
     * @returns A json representation of the entity.
     */
    public abstract toJson(): T;

    /**
     * Update the current entity from information within the passed in json object.
     *
     * @param data The json object containing the data to copy into the entity.
     * @param other
     */
    public abstract updateFromJson(data: TView, ...other: Array<unknown>): void;
}
