import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { SWAN_MOCK_API_DEFAULT_DELAY } from './mock-api.constants';
import { SwanMockApiInterceptor } from './mock-api.interceptor';


@NgModule({
    providers: [
        {
            provide : HTTP_INTERCEPTORS,
            useClass: SwanMockApiInterceptor,
            multi   : true,
        },
    ],
})
export class SwanMockApiModule
{
    /**
     * SwanMockApi module default configuration.
     *
     * @param mockApiServices - Array of services that register mock API handlers
     * @param config - Configuration options
     * @param config.delay - Default delay value in milliseconds to apply all responses
     */
    static forRoot(mockApiServices: any[], config?: { delay?: number }): ModuleWithProviders<SwanMockApiModule>
    {
        return {
            ngModule : SwanMockApiModule,
            providers: [
                {
                    provide   : APP_INITIALIZER,
                    deps      : [...mockApiServices],
                    useFactory: () => (): any => null,
                    multi     : true
                },
                {
                    provide : SWAN_MOCK_API_DEFAULT_DELAY,
                    useValue: config?.delay ?? 0
                }
            ]
        };
    }
}
