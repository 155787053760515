import { Pipe, PipeTransform } from '@angular/core';
import { marked } from 'marked';


@Pipe({
    name: 'marked',
})
export class MarkedPipe implements PipeTransform
{
    // Set the options for the markdown renderer
    constructor()
    {
        marked.setOptions({
            renderer   : new marked.Renderer(),
            mangle     : true,
            pedantic   : false,
            gfm        : true,
            breaks     : true,
            smartLists : true,
            smartypants: false,
            xhtml      : false,
            sanitize   : true,
        });
    }

    transform(value: string, ...args: any[]): string
    {
        if (value && value.length > 0) {
            return marked(value);
        }
        return value;
    }
}
