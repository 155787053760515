import { Enum } from '@awesome-nodes/object';


export class Role extends Enum<number>
{
    static readonly none          = Role.enum<Role>(0x0, 'None');
    static readonly admin         = Role.enum<Role>(0x2, 'ROLE_ADMIN');
    static readonly developer     = Role.enum<Role>(0x4, 'ROLE_DEVELOPER');
    static readonly tester        = Role.enum<Role>(0x8, 'ROLE_TESTER');
    static readonly user          = Role.enum<Role>(0x10, 'ROLE_USER');
    static readonly auth          = Role.enum<Role>(0x20, 'ROLE_AUTH');
    static readonly info          = Role.enum<Role>(0x40, 'ROLE_INFO');
    static readonly module        = Role.enum<Role>(0x80, 'ROLE_MODULE');
    static readonly man           = Role.enum<Role>(0x100, 'ROLE_MAN');
    static readonly profileReader = Role.enum<Role>(0x200, 'ROLE_PROF_R');
    static readonly contentEdit   = Role.enum<Role>(0x400, 'ROLE_CONT_E');
    static readonly interestEdit  = Role.enum<Role>(0x800, 'ROLE_INT_E');
    static readonly switchEnv     = Role.enum<Role>(0x1000, 'ROLE_SWITCH_ENV');
}

