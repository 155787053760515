import { Injectable } from '@angular/core';
import { EventDelegate } from '@swan/mvvm/model';
import { Observable } from 'rxjs';
import { Nullable } from 'simplytyped';
import { ISessionChangeOptions, ISessionService, Session, SessionChangedEventArgs } from '../model';
import { Credentials } from '../model/user';
import { AuthService } from './auth';
import { SessionStoreService } from './SessionStoreService';


@Injectable()
export class SessionService implements ISessionService
{
    public constructor(
        public readonly store: SessionStoreService,
        public readonly auth: AuthService,
    )
    {
    }

    public get sessionChanged(): EventDelegate<Session, SessionChangedEventArgs>
    {
        return Session.sessionChanged;
    }

    public get session(): Nullable<Session>
    {
        return Session.instance;
    }

    public async restore(): Promise<boolean>
    {
        return Session.restore(this);
    }

    public login(credentials: Credentials): Promise<Session>
    {
        return Session.instance.login(this, credentials);
    }

    public refresh(): Observable<Session>
    {
        return Session.refresh(this);
    }

    public logout(options?: ISessionChangeOptions): void
    {
        return Session.instance.logout(this, options);
    }

    public async validate(): Promise<boolean>
    {
        return this.auth.isAuthenticated;
    }
}
