<div class="w-full h-full flex flex-col relative bg-gray-800">
    <div id="actionButtons" class="p-6 flex flex-col-2 justify-between" [ngClass]="{'pt-12': statusBarVisible}">
        <div>
            <lib-close-button class="fixed z-10" routerLink=".." [autoMargin]="false"></lib-close-button>
        </div>
        <div class="flex flex-col space-y-2 hidden">
            <button mat-icon-button class="h-2" disabled>
                <mat-icon svgIcon="swan:system-question" class="text-white"></mat-icon>
            </button>
            <button mat-icon-button class="h-2" disabled>
                <mat-icon svgIcon="swan:mirror-charge" class="text-white"></mat-icon>
            </button>
            <button mat-icon-button class="h-2" disabled>
                <mat-icon svgIcon="swan:video-actions-stopwatch" class="text-white"></mat-icon>
            </button>
        </div>
    </div>
    <div class="mt-auto flex flex-col gap-4 items-center pb-20 z-10">
        <div id="notImplementedMsgContainer"
             class="flex justify-center items-center gap-4 p-4 w-8/12 sm:w-8/12 md:w-4/12 lg:w-3/12 h-fit bg-red-50">
            <mat-icon class="flex-none text-red-900" svgIcon="swan:misc-test"></mat-icon>
            <span class="inline-block font-sans font-semibold text-sm text-red-900 leading-3.5"
                  transloco="VIDEO.RECORD.NOT_IMPLEMENTED"></span>
        </div>
        <div class="flex flex-row items-center space-x-12">
            <input hidden type="file" #fileDropRef id="fileDropRef" (change)="fileSelected($event)"
                   accept="video/*">
            <div class="w-15 h-15 flex content-center">
                <button mat-fab (click)="openPhotoLibrary()" class="bg-white">
                    <ng-container [ngSwitch]="lastVideoImage !== null">
                        <img *ngSwitchCase="true" [src]="lastVideoImage" alt="">
                        <mat-icon *ngSwitchCase="false" class="m-auto text-black"
                                  svgIcon="swan:video-actions-video-lib"></mat-icon>
                    </ng-container>
                </button>
            </div>
            <div class="rounded-full border-2 border-gray-500 flex p-2">
                <button mat-fab disabled id="playButton" class="bg-gray-500"></button>
            </div>
            <button mat-fab id="toggle-camera">
                <mat-icon svgIcon="swan:video-actions-mirror" class="text-white"></mat-icon>
            </button>
        </div>
    </div>
    <video #video autoplay webkit-playsinline playsinline class="absolute w-full h-full"></video>
</div>
