<ng-container [ngSwitch]="displayMenu">

    <ng-container *ngSwitchCase="true">

        <button mat-flat-button [matMenuTriggerFor]="userActions">
            <ng-container [ngTemplateOutlet]="userMenuTemplate"></ng-container>
        </button>

        <mat-menu [xPosition]="'before'" #userActions="matMenu">
            <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>Signed in as</span>
            <span class="mt-1.5 text-md font-medium">{{username}}</span>
        </span>
            </button>
            <mat-divider class="my-2"></mat-divider>
            <button [routerLink]="'/settings/personalize'" mat-menu-item>
                <mat-icon [svgIcon]="'swan:system-account-circle'"></mat-icon>
                <span>Profile</span>
            </button>

            <mat-divider class="my-2"></mat-divider>
            <button mat-menu-item (click)="signOut()">
                <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
                <span>Sign out</span>
            </button>
        </mat-menu>

    </ng-container>

    <ng-container *ngSwitchCase="false">

        <button mat-flat-button (click)="avatarClick()">
            <ng-container [ngTemplateOutlet]="userMenuTemplate"></ng-container>
        </button>

    </ng-container>

</ng-container>

<ng-template #userMenuTemplate>
    <div class="relative w-min h-min">
        <ng-container [ngSwitch]="showAvatar">
            <div *ngSwitchCase="false"
                 class="absolute top-5 left-1/2 transform -translate-x-1/2 -translate-y-1/2 overflow-hidden transition-all"
                 @fadeIn @fadeOut [ngClass]="{
                    'rounded-full': showAvatar
                 }">
                <mat-icon svgIcon="swan:icon" class="icon-size-10"></mat-icon>
            </div>

            <lib-profile-icon *ngSwitchCase="true" size="48" @zoomIn></lib-profile-icon>
        </ng-container>
    </div>
</ng-template>
