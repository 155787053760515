import { NgModule } from '@angular/core';
import { InitModule } from '@swan/lib/config';
import { SessionModule } from '@swan/session';
import { AuthService } from './auth.service';
import { ImageService } from './image.service';
import { InterestInfoService } from './interest-info.service';
import { InterestsService } from './interests.service';
import { ProfileFollowService } from './profile-follow.service';
import { ProfileService } from './profile.service';
import { PublicProfileService } from './public-profile.service';
import { UserService } from './user.service';


@NgModule({
    imports  : [
        InitModule,
        SessionModule,
    ],
    providers: [
        AuthService,
        ImageService,
        InterestInfoService,
        InterestsService,
        ProfileFollowService,
        ProfileService,
        PublicProfileService,
        UserService,
    ],
})
export class ProfileModule
{
}
