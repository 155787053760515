import { ModuleWithProviders, NgModule } from '@angular/core';
import { SwanConfigService } from '@swan/services/config/config.service';
import { SWAN_APP_CONFIG } from '@swan/services/config/config.constants';

@NgModule()
export class SwanConfigModule
{
    /**
     * Constructor
     */
    constructor(private _swanConfigService: SwanConfigService)
    {
    }

    /**
     * forRoot method for setting user configuration
     *
     * @param config
     */
    static forRoot(config: any): ModuleWithProviders<SwanConfigModule>
    {
        return {
            ngModule : SwanConfigModule,
            providers: [
                {
                    provide : SWAN_APP_CONFIG,
                    useValue: config
                }
            ]
        };
    }
}
