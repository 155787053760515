import { Route } from '@angular/router';
import { VideoDetailsComponent, VideoDetailsGuardService, VideoTagsComponent } from './details';
import { VideoTutorialComponent } from './tutorial';
import { VideoRecordComponent } from './video-record.component';


export const videoRecordRoutes: Route[] = [
    {
        path     : '',
        data     : {
            footer           : false,
            layout           : 'empty',
            scheme           : 'dark',
            statusBarOverlays: true,
            statusBarPadding : false,
        },
        component: VideoRecordComponent,
    },
    {
        path     : 'tutorial',
        component: VideoTutorialComponent,
        data     : {
            footer           : false,
            layout           : 'empty',
            scheme           : 'dark',
            statusBarOverlays: false,
        },
    },
    {
        path       : 'details',
        component  : VideoDetailsComponent,
        canActivate: [VideoDetailsGuardService],
        data       : {
            footer: false,
            layout: 'empty',
        },
    },
    {
        path       : 'details/tags',
        component  : VideoTagsComponent,
        canActivate: [VideoDetailsGuardService],
        data       : {
            footer: false,
            layout: 'empty',
        },
    },
];
