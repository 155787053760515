import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { QRCodeModule as QrCodeModule } from 'angularx-qrcode';
import { QRCodeDialogComponent } from 'app/modules/admin/video/qr-code/qr-code-dialog.component';
import { SharedModule } from 'app/shared/shared.module';


@NgModule({
    declarations: [
        QRCodeDialogComponent,
    ],
    imports     : [
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        QrCodeModule,
        SharedModule,
    ],
    exports     : [
        QRCodeDialogComponent,
    ],
})
export class QRCodeModule
{

}
