import { BooleanInput } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { swanAnimations } from '@swan/lib/animations';
import { AuthService, ProfileService, User, UserService } from '@swan/lib/profile';
import { Subject, takeUntil } from 'rxjs';


@Component({
    selector     : 'user-menu',
    templateUrl  : './user-menu.component.html',
    animations   : [swanAnimations],
    encapsulation: ViewEncapsulation.None,
    exportAs     : 'userMenu',
})
export class UserMenuComponent implements OnInit, OnDestroy
{
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean          = true;
    user: User;
    username: string                      = '';
    displayMenu                           = false;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private readonly _changeDetectorRef: ChangeDetectorRef,
        private readonly _router: Router,
        private readonly _auth: AuthService,
        private readonly _profileService: ProfileService,
        private readonly _userService: UserService,
    )
    {
        this.username = _profileService.profile
            ? _profileService.profile.displayName
            : (_userService.user ? _userService.user.username : '');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to user changes
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) =>
            {
                this.user = user;

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        setTimeout(() =>
        {

        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void
    {
        // Return if user is not available
        if (!this.user) {
            return;
        }

        // // Update the user
        // this._userService.update({
        //     ...this.user,
        //     status
        // }).subscribe();
    }

    /**
     * Sign out
     */
    signOut(): void
    {
        this._router.navigate(['/sign-out']);
    }

    public avatarClick(): void
    {
        this._router.navigate([
            this._profileService.profile?.personalized < 100
                ? '/settings/personalize'
                : '/profile',
        ]);
    }
}
