import { ArgumentException, ObjectModel } from '@swan/mvvm/model';
import { Nullable } from 'simplytyped';
import { AuthToken } from '../auth';
import { Role } from '../user/Role';
import { Roles } from '../user/Roles';
import { UnknownUserRoleException } from './UnknownUserRoleException';
import { UserInfo } from './UserInfo';


export class User extends ObjectModel
{
    static readonly roles = new Array<Role>(
        Role.admin,
        Role.developer,
        Role.tester,
        Role.user,
    );

    private readonly _role: Roles;

    public constructor(private _sessionToken: AuthToken, private _userInfo: UserInfo)
    {
        super(_userInfo.username);

        let role: Nullable<Roles> = Roles.none;

        const userRoles = _sessionToken.accessToken.payload.scope.map(_role => Role.parse<Role>(_role) || _role);
        if (!userRoles.some(_role => (typeof _role !== 'string') && User.roles.includes(_role))) {
            userRoles.push(Role.user);
        }

        if (userRoles.includes(Role.admin)) {
            role = Roles.admin;
        }
        else if (userRoles.includes(Role.developer)) {
            role = Roles.developer;
        }
        else if (userRoles.includes(Role.tester)) {
            role = Roles.tester;
        }
        else if (userRoles.includes(Role.user)) {
            role = Roles.user;
        }

        let tenantType: Roles;
        switch (this._sessionToken.accessToken.payload.details.orgId) {
            case 'yuk':
                tenantType = Roles.yukawa;
                break;
            case 'swan':
                tenantType = Roles.swan;
                break;
            default:
                tenantType = Roles.none;
                break;
        }

        //eslint-disable-next-line no-bitwise
        role = Roles.parse(role.value ^ tenantType.value);

        // Note: On implementations failure: check enumeration flags
        if (role == null) {
            throw new UnknownUserRoleException(
                this._sessionToken.accessToken.payload.details.orgId,
                '_sessionToken.accessToken.payload.details.orgId',
            );
        }

        if (role === Roles.none) {
            throw new ArgumentException(
                'User role could not be determined.',
                '_sessionToken.accessToken.payload.scope',
            );
        }

        this._role = role as Roles;
    }

    public get authToken(): AuthToken
    {
        return this._sessionToken;
    }

    public get info(): UserInfo
    {
        return this._userInfo;
    }

    public get role(): Roles
    {
        return this._role;
    }

    //region Role Types

    public get isAdmin(): boolean
    {
        return this._role.hasFlag(Roles.admin);
    }

    public get isDeveloper(): boolean
    {
        return this._role.hasFlag(Roles.developer);
    }

    public get isTester(): boolean
    {
        return this._role.hasFlag(Roles.tester);
    }

    public get isUser(): boolean
    {
        return this._role.hasFlag(Roles.user);
    }

    public get isYukawa(): boolean
    {
        return this._role.hasFlag(Roles.yukawa);
    }

    public get isSwan(): boolean
    {
        return this._role.hasFlag(Roles.swan);
    }

    public hasRole(role: Role): boolean
    {
        return this._sessionToken.scope.includes(role.name);
    }

    //endregion
}

