import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TemplateString } from '@awesome-nodes/object';
import { ObjectModel } from '@swan/mvvm/model';
import { ServiceBase, ServiceException } from '@swan/mvvm/services';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import { User as IUser } from '@yukawa/chain-main-angular-core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthToken } from '../../model/auth';
import { ChangePassword, Credentials, UserInfo } from '../../model/user';
//noinspection ES6PreferShortImport
import { UserServiceProvider } from '../../providers/UserServiceProvider';
import { IChangePasswordResponse, IUserService, UserRestEndpoints } from './IUserService';


export const UserInfoServiceFactory = (provider: UserServiceProvider): ServiceBase<ObjectModel> => provider.provide(
    UserService);

@Injectable({
    providedIn: 'root',
    useFactory: UserInfoServiceFactory,
    deps      : [UserServiceProvider],
})
export class UserService extends ServiceBase<ObjectModel> implements IUserService
{
    public constructor(
        private readonly _httpClient: HttpClient,
        private readonly _configService: ConfigService,
    )
    {
        super();
    }

    public loadUser(token: AuthToken): Observable<UserInfo>
    {
        return this.createWorker(
            UserRestEndpoints.loadUser,
            this._httpClient.get<IUser>(this._configService.formatUrl('userUrl')
                + `${UserRestEndpoints.loadUser}${token.username}`, {
                headers: new HttpHeaders({
                    Authorization: `Bearer ${token.accessToken}`,
                }),
            }),
        ) as Observable<UserInfo>;
    }

    public changePassword(credentials: Credentials): Observable<ChangePassword>
    {
        return this.createWorker(
            UserRestEndpoints.changePassword,
            this._httpClient.post<IChangePasswordResponse>(
                this._configService.formatUrl('userUrl') + UserRestEndpoints.changePassword,
                credentials.toJSON(),
            ),
        ) as Observable<ChangePassword>;
    }

    public resetPassword(username: string): Observable<ChangePassword>
    {
        return this.createWorker(
            UserRestEndpoints.changePassword,
            this._httpClient.post<IChangePasswordResponse>(
                this._configService.formatUrl('userUrl') + UserRestEndpoints.resetPassword,
                { username },
            ),
        ) as Observable<ChangePassword>;
    }

    protected doWork(workerName: string, workerObservable?: Observable<unknown>): Observable<ObjectModel>
    {
        switch (workerName) {
            case UserRestEndpoints.loadUser:
                return (workerObservable as Observable<IUser>).pipe(
                    map(response => new UserInfo(response)),
                );
            case UserRestEndpoints.changePassword:
                return (workerObservable as Observable<IChangePasswordResponse>).pipe(
                    map(response => new ChangePassword(response)),
                );

            default:
                throw new ServiceException(TemplateString`Request method for '${'workerName'}' not yet implemented.`, {
                    workerName: this._configService.formatUrl('userUrl') + workerName,
                });
        }

    }
}
