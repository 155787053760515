import { Injector } from '@angular/core';


/**
 * Allows for retrieving singletons using `AppInjector.get(MyService)`
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export let AppInjector: Injector;

/**
 * Helper to set the exported {@link AppInjector}
 */
export const setAppInjector = (injector: Injector): void =>
{
    if (AppInjector) {
        // Should not happen
        console.error('Programming error: AppInjector was already set');
    }
    else {
        AppInjector = injector;
    }
};
