import { Entity } from '@swan/lib/content/utils';
import { PlayerPosition as IPlayerPosition } from '@swan/lib/domain';
import { Change } from '@yukawa/chain-base-angular-domain';


export class PlayerPosition extends Entity<IPlayerPosition> implements IPlayerPosition
{
    public readonly change!: Change;
    public readonly contentId!: number;
    public readonly username!: string;

    private _second!: number;

    constructor(initialData: IPlayerPosition)
    {
        super(initialData);
    }

    public get key(): string
    {
        return this.contentId.toString();
    }

    public get second(): number
    {
        return this._second;
    }

    public set second(value: number)
    {
        this._second = value;
    }

    public updateFromJson(data: IPlayerPosition): void
    {
        this.setFromJson(data, [
            'change',
            'contentId',
            'username',
            'second',
        ]);
    }

    public toJson(): IPlayerPosition
    {
        return this.toJsonWithKeys([
            'contentId',
            'second',
        ]);
    }

}
