import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import moment, { MomentInput } from 'moment';


@Pipe({
    name: 'elapsedDate',
})
export class ElapsedDatePipe implements PipeTransform
{
    static _translationsInitialized = false;

    constructor(
        private _translocoService: TranslocoService,
    )
    {
        _translocoService.getAvailableLangs().forEach(
            (lang) =>
            {
                if (!ElapsedDatePipe._translationsInitialized) {
                    moment.updateLocale(typeof lang === 'string' ? lang : lang.id, {
                        relativeTime: {
                            /* eslint-disable @typescript-eslint/naming-convention */
                            future: _translocoService.translate('FORMAT.ELAPSED_DATE.FUTURE'),
                            past  : _translocoService.translate('FORMAT.ELAPSED_DATE.PAST'),
                            s     : _translocoService.translate('FORMAT.ELAPSED_DATE.SECOND'),
                            ss    : _translocoService.translate('FORMAT.ELAPSED_DATE.SECONDS'),
                            m     : _translocoService.translate('FORMAT.ELAPSED_DATE.MINUTE'),
                            mm    : _translocoService.translate('FORMAT.ELAPSED_DATE.MINUTES'),
                            h     : _translocoService.translate('FORMAT.ELAPSED_DATE.HOUR'),
                            hh    : _translocoService.translate('FORMAT.ELAPSED_DATE.HOURS'),
                            d     : _translocoService.translate('FORMAT.ELAPSED_DATE.DAY'),
                            dd    : _translocoService.translate('FORMAT.ELAPSED_DATE.DAYS'),
                            M     : _translocoService.translate('FORMAT.ELAPSED_DATE.MONTH'),
                            MM    : _translocoService.translate('FORMAT.ELAPSED_DATE.MONTHS'),
                            y     : _translocoService.translate('FORMAT.ELAPSED_DATE.YEAR'),
                            yy    : _translocoService.translate('FORMAT.ELAPSED_DATE.YEARS'),
                            /* eslint-enable @typescript-eslint/naming-convention */
                        },
                    });
                    ElapsedDatePipe._translationsInitialized = true;
                }
            },
        );
    }

    transform(value: MomentInput, ...args: unknown[]): string | undefined
    {
        if (value == null) {
            return;
        }

        const secondsElapsed = moment().diff(value, 'seconds');
        const dayStart       = moment(value).startOf('day').seconds(secondsElapsed);

        if (secondsElapsed > 300) {
            return moment(value).fromNow(true);
        }
        else if (secondsElapsed < 60) {
            return this._translocoService.translate('FORMAT.ELAPSED_DATE.SECOND').replace(
                '%d',
                dayStart.format('s'),
            );
        }
        else {
            return this._translocoService.translate('FORMAT.ELAPSED_DATE.MINUTE').replace(
                '%d',
                dayStart.format('m'),
            );
        }
    }
}
