import { Injectable } from '@angular/core';
import { SwanMockApiService } from '@swan/lib/mock-api';
import { cloneDeep } from 'lodash-es';


@Injectable({
    providedIn: 'root',
})
export class UserMockApi
{
    private _user: any = {};

    /**
     * Constructor
     */
    constructor(
        private _swanMockApiService: SwanMockApiService,
    )
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        // -----------------------------------------------------------------------------------------------------
        // @ User - GET
        // -----------------------------------------------------------------------------------------------------
        this._swanMockApiService
            .onGet('api/common/user')
            .reply(() => [200, cloneDeep(this._user)]);

    }
}
