import { ArgumentNullException } from '@awesome-nodes/object';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { map, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Nullable, PlainObject } from 'simplytyped';
import { Entity } from './entity';
import { EntityCache } from './entity-cache';


export type EntityKey = number | string | Entity;

export interface IEntityRepositoryService<TEntity extends Entity>
{
    /**
     * Convert accepted data to @class Entity object
     *
     * @param json The json data to convert to T
     * @param other
     */
    createInstanceFrom(json: PlainObject, other?: PlainObject): TEntity;

    /**
     * Gets the unique key for an entity of type {@link Entity}.
     * This is used to identify the object within a cache.
     *
     * @param json The json object to get the key from
     * @returns string containing the unique key value
     */
    keyForEntityCache?(json: TEntity): string;
}

export type EntityObservable<T extends PlainObject | Entity = PlainObject> = Observable<T | Array<T> | QueryResult<T>>;

export class EntityRepository<TEntity extends Entity> extends EntityCache<TEntity>
{
    public constructor(
        private _repositoryService: IEntityRepositoryService<TEntity>,
    )
    {
        super();
    }

    static isQueryResult<T extends PlainObject>(resultOrQuery: PlainObject | QueryResult<T>): resultOrQuery is QueryResult<T>
    {
        return resultOrQuery != null && Array.isArray(resultOrQuery['items']);
    }

    /** @inheritDoc */
    public override add(key: string, entity: TEntity): void
    public override add<E extends EntityObservable<TEntity>>(observable: EntityObservable, entity?: TEntity): E
    public override add<E extends EntityObservable<TEntity>>(
        observable: string | EntityObservable,
        entity?: TEntity): void | E
    {
        if (typeof observable === 'string') {
            if (entity == null) {
                throw new ArgumentNullException('', 'entity');
            }
            return super.add(observable, entity as TEntity);
        }

        let result = observable.pipe(
            map((response) =>
            {
                if (EntityRepository.isQueryResult(response)) {
                    response.items = this.map(response.items);
                    return response as QueryResult<TEntity>;
                }
                if (Array.isArray(response)) {
                    return this.map(response);
                }
                if (entity != null) {
                    entity.updateFromJson(response);
                    return entity;
                }
                return this._repositoryService.createInstanceFrom(response);
            }),
        );

        if (this._repositoryService.keyForEntityCache) {
            result = result.pipe(
                tap((_entity) =>
                {
                    if (EntityRepository.isQueryResult(_entity)) {
                        _entity.items.forEach(item => super.add(item.key, item));
                    }
                    else if (Array.isArray(_entity)) {
                        _entity.forEach(item => super.add(item.key, item));
                    }
                    else if (_entity) {
                        super.add(_entity.key, _entity);
                    }
                }),
            );
        }

        return result as E;
    }

    public override get(key: EntityKey): Nullable<TEntity>
    public override get(observable: Observable<TEntity>, key: string): Observable<TEntity>
    public override get(
        observable: EntityKey | Observable<TEntity>,
        key?: string): Nullable<TEntity> | Observable<TEntity>
    {
        if (typeof observable === 'number' || typeof observable === 'string') {
            return super.get(String(observable));
        }
        if (observable instanceof Entity) {
            return super.get(observable.key);
        }

        if (key == null || key === '') {
            throw new ArgumentNullException('', 'key');
        }

        if (this.has(key)) {
            return of(super.get(key)) as Observable<TEntity>;
        }

        let result = observable;

        if (this._repositoryService.keyForEntityCache != null) {
            result = this.add(observable);
        }

        return result;
    }

    public update(observable: Observable<TEntity>, entity: Entity): Observable<TEntity>
    {
        let result = observable.pipe(
            map((rawData) =>
            {
                entity?.updateFromJson(rawData);

                return entity as TEntity;
            }),
        );

        if (this._repositoryService.keyForEntityCache != null) {
            result = result.pipe(
                tap(updatedEntity => super.add(updatedEntity.key, updatedEntity)),
            );
        }

        return result;
    }

    public override remove(key: EntityKey): boolean
    public override remove<E extends EntityObservable>(observable: EntityObservable, entity?: TEntity): E
    public override remove<E extends EntityObservable>(
        observable: EntityKey | EntityObservable,
        entity?: TEntity): boolean | E
    {
        if (typeof observable === 'number' || typeof observable === 'string') {
            return super.remove(String(observable));
        }
        if (observable instanceof Entity) {
            return super.remove(observable.key);
        }

        let result = observable.pipe(
            map((response) =>
            {
                if (Array.isArray(response)) {
                    return this.map(response);
                }
                else if (entity != null) {
                    entity.updateFromJson(response);
                    return entity;
                }
                else {
                    return this._repositoryService.createInstanceFrom(response, entity);
                }
            }),
        );

        if (this._repositoryService.keyForEntityCache != null) {
            result = result.pipe(
                map((response) =>
                {
                    if (Array.isArray(response)) {
                        response.forEach(_entity => super.remove(_entity.key));
                    }
                    else {
                        super.remove(response.key);
                    }

                    return response;
                }),
            );
        }

        return result as E;
    }

    /**
     * Instantiates an array of elements as objects from the provided JSON.
     *
     * @returns The array of mapped entity objects
     */
    public map(collection: Array<any>, other?: PlainObject): TEntity[]
    {
        return collection.map((data: any) => this._repositoryService.createInstanceFrom(data, other)) as TEntity[];
    }
}
