import { ObjectModel } from '@swan/mvvm/model';
import { IChangePasswordResponse } from '../../services/user';


export class ChangePassword extends ObjectModel implements IChangePasswordResponse
{
    public readonly fqn: string;
    public readonly key: number;
    public readonly messages: [{ code: string; level: number; text: string; type: string }];
    public readonly op: string;
    public readonly stamp: string;
    public readonly took: 0;
    public readonly userId: string;

    public constructor(
        changePassword: IChangePasswordResponse)
    {
        super();

        this.fqn      = changePassword.fqn;
        this.key      = changePassword.key;
        this.messages = changePassword.messages;
        this.op       = changePassword.op;
        this.stamp    = changePassword.stamp;
        this.took     = changePassword.took;
        this.userId   = changePassword.userId;
    }
}
