import { IQueryTableEntryDetail, ISelectOption } from '@swan/lib/components';
import { PublicProfile } from '@swan/lib/domain';
import { AppInjector, AutocompleteSearchFunction } from '@swan/lib/shared';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { lastValueFrom, map, Subject } from 'rxjs';
import { Profile } from './profile.entity';
import { User } from './user.model';
import { UserService } from './user.service';


export const userAutocompleteSearch = (
    value: PublicProfile,
    detail: Partial<IQueryTableEntryDetail>): AutocompleteSearchFunction =>
{
    const subject               = new Subject<Array<ISelectOption<string, Profile>>>();
    detail.autocompleteOptions$ = subject;
    return async (filter: string | null): Promise<void> =>
    {
        subject.next(await lastValueFrom(AppInjector.get(UserService).queryUser({
            keyword: `*${filter}*`,
            pager  : {
                firstResult: 0,
                pageSize   : 10,
            },
        }).pipe(
            map((users: QueryResult<User>) =>
            {
                const autocompleteOptions = users.items.map((user: User) => ({
                    name       : user.username,
                    value      : user.username,
                    objectValue: user.username === value.username
                        ? value
                        : {
                            username: user.username,
                        } as PublicProfile,
                } as ISelectOption<string, Profile>));

                // Display also unavailable user items
                if (value && !autocompleteOptions.some(option => option.name === value)) {
                    autocompleteOptions.push({
                        name       : value.username as string,
                        value      : value.username as string,
                        objectValue: {
                            username: value,
                        } as Profile,
                    });
                }

                return autocompleteOptions;
            }),
        )));
    };
};
