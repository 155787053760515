import { Route } from '@angular/router';
import { AuthGuardService as AuthGuard, NoAuthGuardService as NoAuthGuard } from '@swan/session';
import { InitialDataResolver } from 'app/app.resolvers';
import { LayoutComponent } from 'app/layout/layout.component';
import { IntroComponent } from 'app/modules/auth/intro/intro.component';

// @formatter:off
// tslint:disable:max-line-length
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/home'
    { path: '', pathMatch: 'full', redirectTo: 'home' },

    // Redirect signed in user to the '/room'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'video' },

    // Auth routes for guests
    {
        path            : '',
        canActivate     : [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        canLoad         : [NoAuthGuard],
        component       : LayoutComponent,
        data            : {
            layout     : 'empty',
            footer     : false,
            errorAlerts: false,
        },
        children        : [
            {
                path        : 'confirmation-required',
                loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule),
            },
            {
                path        : 'forgot-password',
                loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule),
            },
            {
                path        : 'reset-password',
                loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule),
            },
            {
                path        : 'sign-in',
                loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule),
            },
            {
                path        : 'sign-up',
                loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path            : '',
        canActivate     : [AuthGuard],
        canActivateChild: [AuthGuard],
        canLoad         : [AuthGuard],
        component       : LayoutComponent,
        data            : {
            layout: 'empty',
            footer: false,
        },
        children        : [
            {
                path        : 'sign-out',
                loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule),
            },
            {
                path        : 'unlock-session',
                loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule),
            },
            {
                path     : 'welcome',
                component: IntroComponent,
                data     : {
                    footer          : false,
                    layout          : 'empty',
                    scheme          : 'dark',
                    statusBarPadding: false,
                },
            },
            {
                path        : 'setting',
                data        : {
                    footer: false,
                },
                loadChildren: () => import('app/modules/setting/setting.module').then(m => m.SettingModule),
            },

        ],
    },

    // Landing routes
    {
        path            : '',
        canActivate     : [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        canLoad         : [NoAuthGuard],
        component       : LayoutComponent,
        data            : {
            errorAlerts     : false,
            footer          : false,
            layout          : 'empty',
            scheme          : 'dark',
            statusBarPadding: false,
        },
        children        : [
            {
                path        : 'home',
                loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule),
            },
        ],
    },

    // Admin routes
    {
        path            : '',
        canActivate     : [AuthGuard],
        canActivateChild: [AuthGuard],
        canLoad         : [AuthGuard],
        component       : LayoutComponent,
        data            : {
            layout: 'empty',
        },
        resolve         : {
            initialData: InitialDataResolver,
        },
        children        : [
            {
                path        : 'profile',
                loadChildren: () => import('app/modules/profile/profile.module').then(m => m.ProfileModule),
            },
            {
                path        : 'settings',
                data        : {
                    footer: true,
                },
                loadChildren: () => import('app/modules/setting/setting.module').then(m => m.SettingModule),
            },
            {
                path        : 'video',
                loadChildren: () => import('app/modules/admin/video/video.module').then(m => m.VideoModule),
            },
        ],
    },
];
