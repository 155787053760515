import { ChangeDetectorRef, Injectable, OnDestroy } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { LoggedEventArgs, LogService, LogTypes, SwanAlertService, SwanAlertType } from '@swan/lib/shared';
import { Session } from '@swan/session';
import { environment } from 'environments/app';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { PlainObject } from 'simplytyped';


@Injectable()
export class ErrorAlertComponentBase implements OnDestroy
{
    public showAlert: boolean = false;
    public alertType: SwanAlertType;

    protected _unsubscribeAll: Subject<any> = new Subject<any>();
    private _loggedSubscription: Subscription;

    constructor(
        protected _changeDetector: ChangeDetectorRef,
        protected _swanAlertService: SwanAlertService,
        protected _translocoService: TranslocoService,
    )
    {
        this._loggedSubscription = window.logService.logged.subscribe(this.logged, this);

        _swanAlertService.onShow.pipe(takeUntil(this._unsubscribeAll)).subscribe(() =>
        {
            this.showAlert = true;
        });

        _swanAlertService.onDismiss.pipe(takeUntil(this._unsubscribeAll)).subscribe(() =>
        {
            this.showAlert = false;
        });
    }

    public ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        this._loggedSubscription.unsubscribe();
    }

    private logged(sender: LogService, ea: LoggedEventArgs): void
    {
        // Cancel dialog show to prevent possible event round trips.
        // TODO: feat(swan/lib): implement horizontal stacking notification messages
        if (this.showAlert) {
            return;
        }

        switch (ea.type) {
            case LogTypes.debug:
                this.alertType = 'basic';
                break;
            case LogTypes.info:
            case LogTypes.log:
                this.alertType = 'info';
                break;
        }

        switch (ea.type) {
            case LogTypes.error:
            case LogTypes.warn:
                this.alertType = ea.type;
                let message: string;

                if (!window.navigator.onLine) {
                    const onlineListener = (): void =>
                    {
                        this.showAlert = false;
                        window.removeEventListener('online', onlineListener);
                    };
                    window.addEventListener('online', onlineListener);
                    message = this._translocoService.translate('ERROR.NO_INTERNET_CONNECTION');
                }
                else if (environment.production || !Session.instance.user?.isTester) {
                    return;
                }
                else if (ea.data[0] instanceof Error) {
                    let error = ea.data[0];
                    if ((error as PlainObject).rejection) {
                        error = (error as PlainObject).rejection as Error;
                    }
                    message = error.message.split('\n')[0];
                }
                else {
                    message = ea.message;
                }
                this._swanAlertService.show('global', {
                    type: ea.type,
                    message,
                });
                this._changeDetector.detectChanges();
                break;
        }
    }
}
