import { ObjectBase } from '@awesome-nodes/object/data';
import { AccountStatus as IAccountStatus } from '@yukawa/chain-security-angular-core';


export class AccountStatus extends ObjectBase implements IAccountStatus
{
    private _accountNonExpired: boolean;
    private _accountNonLocked: boolean;
    private _credentialsNonExpired: boolean;
    private _enabled: boolean;

    public constructor(accountStatus: IAccountStatus)
    {
        super();

        this._accountNonExpired     = accountStatus.accountNonExpired ?? false;
        this._accountNonLocked      = accountStatus.accountNonLocked ?? false;
        this._credentialsNonExpired = accountStatus.credentialsNonExpired ?? false;
        this._enabled               = accountStatus.enabled ?? false;
    }

    //region Public Properties

    public get accountNonExpired(): boolean
    {
        return this._accountNonExpired;
    }

    public set accountNonExpired(value: boolean)
    {
        this._accountNonExpired = value;
    }

    public get accountNonLocked(): boolean
    {
        return this._accountNonLocked;
    }

    public set accountNonLocked(value: boolean)
    {
        this._accountNonLocked = value;
    }

    public get credentialsNonExpired(): boolean
    {
        return this._credentialsNonExpired;
    }

    public set credentialsNonExpired(value: boolean)
    {
        this._credentialsNonExpired = value;
    }

    public get enabled(): boolean
    {
        return this._enabled;
    }

    public set enabled(value: boolean)
    {
        this._enabled = value;
    }

    //endregion

    public override toJSON<T = this>(): IAccountStatus
    {
        return super.toJSON(
            'accountNonExpired',
            'accountNonLocked',
            'credentialsNonExpired',
            'enabled',
        );
    }
}
