<!-- Wrapper -->
<div class="flex flex-col h-full w-full">

    <!-- Content -->
    <div class="grow flex flex-col flex-auto overflow-y-auto" [ngStyle]="{
        'height': 'calc(100% - ' + bodyPaddingY + ')',
        'padding-top': bodyPaddingTop
    }">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Alert -->
    <swan-alert
        *ngIf="errorAlerts && showAlert"
        name="global"
        class="absolute left-2 bottom-20 right-2 min-h-15" style="z-index: 99999"
        [ngClass]="{
            'bottom-50': alertType === 'error'
        }"
        appearance="outline"
        showIcon="true"
        dismissible="true"
        dismissed="true"
        [@shake]="alertType === 'error'">
    </swan-alert>

    <div *ngIf="displayFooter" class="footer_menu flex items-center justify-between px-6">

        <button mat-icon-button (click)="navigateToHome()">
            <mat-icon svgIcon="swan:system-home" class="icon-size-6" [ngClass]="{
                'text-black': currentActiveRoute === 'video'
            }"></mat-icon>
        </button>
        <button mat-icon-button [routerLink]="'video/search'">
            <mat-icon svgIcon="swan:explore" class="icon-size-6" [ngClass]="{
                'text-black': currentActiveRoute === 'search'
            }"></mat-icon>
        </button>
        <button mat-fab *ngIf="routeSegmentActive('video') || routeSegmentActive('profile')"
                (click)="navigateToRecord()"
                class="min-h-14 min-w-14 bg-black -mt-13 text-white text-lg">
            <mat-icon svgIcon="swan:system-plus" class="text-white"></mat-icon>
        </button>
        <button mat-icon-button routerLink="/profile/notifications">
            <mat-icon svgIcon="swan:actions-heart" class="icon-size-6" [ngClass]="{
                'text-black': routeSegmentActive('notifications')
            }"></mat-icon>
        </button>
        <button mat-icon-button (click)="navigateTo('/profile')">
            <mat-icon svgIcon="swan:system-account-circle" class="icon-size-6" [ngClass]="{
                'text-black': routeSegmentActive('profile') && !routeSegmentActive('notifications')
            }"></mat-icon>
        </button>
    </div>
</div>


<style>
    /* ============= Footer CSS =========== */
    .footer_menu {
        min-height: 60px;
        z-index: 1;
        box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 12%);
    }
</style>
