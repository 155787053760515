import {
    HttpClient,
    HttpEvent,
    HttpEventType,
    HttpProgressEvent,
    HttpResponse,
    HttpResponseBase,
} from '@angular/common/http';
import { NgZone } from '@angular/core';
import { AppInjector } from '@swan/lib/shared';
import { Event, EventTarget } from 'event-target-shim';
import { catchError, last, lastValueFrom, of, tap, throwError } from 'rxjs';
import xhr, { XhrHeaders, XhrResponse, XhrUrlConfig } from 'xhr';


const SUCCESSFUL_CHUNK_UPLOAD_CODES = [200, 201, 202, 204, 308];
const TEMPORARY_ERROR_CODES         = [408, 502, 503, 504]; // These error codes imply a chunk may be retried

type EventName =
    | 'attempt'
    | 'attemptFailure'
    | 'chunkSuccess'
    | 'error'
    | 'offline'
    | 'online'
    | 'progress'
    | 'success';

// NOTE: This and the EventTarget definition below could be more precise
// by e.g. typing the detail of the CustomEvent per EventName.
type UpChunkEvent = CustomEvent & Event<EventName>;

type AllowedMethods =
    | 'PUT'
    | 'POST'
    | 'PATCH';

type UploadMode = 'xhr' | 'HttpClient' | 'fetch';

export interface UpChunkOptions
{
    endpoint: string | ((file?: File) => Promise<string>);
    file: File;
    method?: AllowedMethods;
    headers?: XhrHeaders;
    maxFileSize?: number;
    chunkSize?: number;
    attempts?: number;
    delayBeforeAttempt?: number;
    mode?: UploadMode;
    /**
     * Indicates if detailed progress events should be fired.
     * If `true` on mobile and using upload mode xhr/HttpClient, it will cause memory leakage and app freeze.
     */
    reportProgress?: boolean;
}

export class UpChunk implements UpChunkOptions
{
    public endpoint: string | ((file?: File) => Promise<string>);
    public file: File;
    public headers: XhrHeaders;
    public method: AllowedMethods;
    public chunkSize: number;
    public attempts: number;
    public delayBeforeAttempt: number;
    public mode: UploadMode;
    /** @inheritDoc */
    public reportProgress: boolean;

    private chunk!: Blob;
    private chunkCount: number;
    private chunkByteSize: number;
    //private bytesUploaded!: number;
    private maxFileBytes: number;
    private endpointValue!: string;
    private totalChunks: number;
    private attemptCount: number;
    private offline: boolean;
    private paused: boolean;
    private success: boolean;
    private currentXhr?: XMLHttpRequest;

    private eventTarget: EventTarget<Record<EventName, UpChunkEvent>>;

    constructor(
        options: UpChunkOptions,
    )
    {
        this.endpoint           = options.endpoint;
        this.file               = options.file;
        this.headers            = options.headers || ({} as XhrHeaders);
        this.method             = options.method || 'PUT';
        this.chunkSize          = options.chunkSize || 30720;
        this.attempts           = options.attempts || 5;
        this.delayBeforeAttempt = options.delayBeforeAttempt || 1;
        this.mode               = options.mode || 'fetch';
        this.reportProgress     = options.reportProgress || true;

        this.maxFileBytes  = (options.maxFileSize || 0) * 1024;
        this.chunkCount    = 0;
        this.chunkByteSize = this.chunkSize * 1024;
        this.totalChunks   = Math.ceil(this.file.size / this.chunkByteSize);
        this.attemptCount  = 0;
        this.offline       = false;
        this.paused        = false;
        this.success       = false;

        this.eventTarget = new EventTarget();

        this.validateOptions();

        AppInjector.get(NgZone).runOutsideAngular(() =>
        {
            this.getEndpoint().then(() => this.sendChunks());
        });

        // restart sync when back online
        // trigger events when offline/back online
        if (typeof (window) !== 'undefined') {
            window.addEventListener('online', () =>
            {
                if (!this.offline) {
                    return;
                }

                this.offline = false;
                this.dispatch('online');
                this.sendChunks();
            });

            window.addEventListener('offline', () =>
            {
                this.offline = true;
                this.dispatch('offline');
            });
        }
    }

    /**
     * Subscribe to an event
     */
    public on(eventName: EventName, fn: (event: CustomEvent) => void): void
    {
        this.eventTarget.addEventListener(eventName, fn as EventListener);
    }

    public abort(): void
    {
        this.pause();
        this.currentXhr?.abort();
    }

    public pause(): void
    {
        this.paused = true;
    }

    public resume(): void
    {
        if (this.paused) {
            this.paused = false;

            this.sendChunks();
        }
    }

    /**
     * Dispatch an event
     */
    private dispatch(eventName: EventName, detail?: any): void
    {
        const event: UpChunkEvent = new CustomEvent(eventName, { detail }) as UpChunkEvent;

        AppInjector.get(NgZone).run(() =>
        {
            this.eventTarget.dispatchEvent(event);
        });
    }

    /**
     * Validate options and throw errors if expectations are violated.
     */
    private validateOptions(): void
    {
        if (
            !this.endpoint ||
            (typeof this.endpoint !== 'function' && typeof this.endpoint !== 'string')
        ) {
            throw new TypeError(
                'endpoint must be defined as a string or a function that returns a promise',
            );
        }
        if (!(this.file instanceof File)) {
            throw new TypeError('file must be a File object');
        }
        if (this.headers && typeof this.headers !== 'object') {
            throw new TypeError('headers must be null or an object');
        }
        if (
            this.chunkSize &&
            (typeof this.chunkSize !== 'number' ||
                this.chunkSize <= 0 ||
                this.chunkSize % 256 !== 0)
        ) {
            throw new TypeError(
                'chunkSize must be a positive number in multiples of 256',
            );
        }
        if (this.maxFileBytes > 0 && this.maxFileBytes < this.file.size) {
            throw new Error(
                `file size exceeds maximum (${this.file.size} > ${this.maxFileBytes})`,
            );
        }
        if (
            this.attempts &&
            (typeof this.attempts !== 'number' || this.attempts <= 0)
        ) {
            throw new TypeError('retries must be a positive number');
        }
        if (
            this.delayBeforeAttempt &&
            (typeof this.delayBeforeAttempt !== 'number' ||
                this.delayBeforeAttempt < 0)
        ) {
            throw new TypeError('delayBeforeAttempt must be a positive number');
        }
    }

    /**
     * Endpoint can either be a URL or a function that returns a promise that resolves to a string.
     */
    private getEndpoint(): Promise<string>
    {
        if (typeof this.endpoint === 'string') {
            this.endpointValue = this.endpoint;
            return Promise.resolve(this.endpoint);
        }

        return this.endpoint(this.file).then((value) =>
        {
            this.endpointValue = value;
            return this.endpointValue;
        });
    }

    /**
     * Get portion of the file of x bytes corresponding to chunkSize
     */
    private getChunk(): Promise<void>
    {
        // Since we start with 0-chunkSize for the range, we need to subtract 1.
        const length = this.totalChunks === 1 ? this.file.size : this.chunkByteSize;
        const start  = length * this.chunkCount;

        if ('arrayBuffer' in Blob.prototype) {
            return this.file.slice(start, start + length).arrayBuffer()
                .then((buffer) =>
                {
                    this.chunk = new Blob([buffer], {
                        type: 'application/octet-stream',
                    });
                });
        }
        return new Promise<void>((resolve, reject) =>
        {
            const reader  = new FileReader();
            reader.onload = (): void =>
            {
                if (reader.result !== null) {
                    this.chunk = new Blob([reader.result], {
                        type: 'application/octet-stream',
                    });
                }
                resolve();
            };

            reader.onerror = reject;

            reader.readAsArrayBuffer(this.file.slice(start, start + length));
        });
    }

    private onProgress(event: { loaded: number; total?: number }): void
    {
        const percentagePerChunk   = 100 / this.totalChunks;
        const sizePerChunk         = percentagePerChunk * this.file.size;
        const successfulPercentage = percentagePerChunk * this.chunkCount;
        const currentChunkProgress = event.loaded / (event.total ?? sizePerChunk);
        const chunkPercentage      = currentChunkProgress * percentagePerChunk;
        this.dispatch('progress', Math.min(successfulPercentage + chunkPercentage, 100));
    }

    /**
     * Creates a xhr request which returns the {@see XhrResponse}.
     *
     * @param options
     */
    private xhrPromise(options: XhrUrlConfig): Promise<XhrResponse>
    {
        const beforeSend = (xhrObject: XMLHttpRequest): void =>
        {
            if (this.reportProgress) {
                xhrObject.upload.onprogress = this.onProgress.bind(this);
            }
        };

        return new Promise((resolve, reject) =>
        {
            AppInjector.get(NgZone).runOutsideAngular(() =>
            {
                this.currentXhr = xhr({ ...options, beforeSend }, (err, resp) =>
                {
                    this.currentXhr = undefined;
                    if (err) {
                        return reject(err);
                    }

                    return resolve(resp);
                });
            });
        });
    }

    /**
     * Creates an angular http client request which returns the {@see HttpResponse}.
     *
     * @param options
     */
    private httpClientPromise(options: XhrUrlConfig): Promise<HttpResponse<string>>
    {
        return AppInjector.get(NgZone).runOutsideAngular(() => lastValueFrom(
            AppInjector.get(HttpClient).request(
                this.method,
                this.endpointValue,
                {
                    body          : this.chunk,
                    headers       : options.headers,
                    observe       : 'events',
                    reportProgress: this.reportProgress,
                },
            ).pipe(
                tap((response) =>
                    {
                        switch ((response as HttpEvent<string>).type) {
                            case HttpEventType.UploadProgress:
                                //const percentDone = event.total ? Math.round(100 * event.loaded / event.total) : 0;
                                this.onProgress(response as HttpProgressEvent);
                                break;
                        }
                    },
                ),
            ).pipe(
                catchError((error) =>
                {
                    if (SUCCESSFUL_CHUNK_UPLOAD_CODES.includes(error.status)) {
                        return of(error);
                    }
                    else {
                        return throwError(error);
                    }
                }),
                last(),
            )) as Promise<HttpResponse<string>>);
    }

    /**
     * Creates a fetch request and returns a promise which returns the {@see Response}.
     * WIP: TODO(lib/content/mux): fetch is causing cors errors.
     * {@link https://stackoverflow.com/questions/62353634/cors-policy-with-google-storage-allows-from-my-origin-but-no-access-control-al}
     *
     * @param options
     */
    private async fetchPromise(options: XhrUrlConfig): Promise<Response>
    {
        // Upload progress not possible, see:  https://github.com/whatwg/fetch/issues/1438
        /**const totalBytes  = this.file.size;
         this.bytesUploaded = 0;

         const blobReader             = this.chunk.stream().getReader();
         const progressTrackingStream = new ReadableStream({
            pull: async (controller): Promise<void> =>
            {
                const result = await blobReader.read();
                if (result.done) {
                    controller.close();
                    return;
                }
                controller.enqueue(result.value);
                this.bytesUploaded += result.value.byteLength;
                if (this.reportProgress) {
                    this.onProgress({ loaded: this.bytesUploaded, total: totalBytes });
                }
            },
        }, {
            size: (chunk): number => chunk.length,
        });*/
        return AppInjector.get(NgZone).runOutsideAngular(() => new Promise((resolve, reject) =>
        {
            fetch(options.url, {
                method : options.method,
                headers: options.headers,
                body   : this.chunk,
            })
                .then(resolve)
                .catch((error) =>
                {
                    if (SUCCESSFUL_CHUNK_UPLOAD_CODES.includes(error.status)) {
                        return resolve(error);
                    }
                    else {
                        return reject(error);
                    }
                });
        }));
    }

    /**
     * Send chunk of the file with appropriate headers and add post parameters if it's last chunk
     */
    private sendChunk(): Promise<XhrResponse | HttpResponse<string> | Response>
    {
        const rangeStart = this.chunkCount * this.chunkByteSize;
        const rangeEnd   = rangeStart + this.chunk.size - 1;
        const headers    = {
            /* eslint-disable @typescript-eslint/naming-convention */
            ...this.headers,
            'Content-Type' : this.file.type,
            'Content-Range': `bytes ${rangeStart}-${rangeEnd}/${this.file.size}`,
            /* eslint-enable @typescript-eslint/naming-convention */
            'ngsw-bypass': '',
        };

        this.dispatch('attempt', {
            chunkNumber: this.chunkCount,
            chunkSize  : this.chunk.size,
        });

        const options = {
            headers,
            'url'   : this.endpointValue,
            'method': this.method,
            'body'  : this.chunk,
        };

        switch (this.mode) {
            case 'xhr':
                return this.xhrPromise(options);
            case 'HttpClient':
                return this.httpClientPromise(options);
            case 'fetch':
                return this.fetchPromise(options);
        }
    }

    /**
     * Called on net failure. If retry counter !== 0, retry after delayBeforeAttempt
     */
    private manageRetries(): void
    {
        if (this.attemptCount < this.attempts) {
            AppInjector.get(NgZone).runOutsideAngular(() =>
            {
                setTimeout(() => this.sendChunks(), this.delayBeforeAttempt * 1000);
            });
            this.dispatch('attemptFailure', {
                message     : `An error occured uploading chunk ${this.chunkCount}. ${
                    this.attempts - this.attemptCount
                } retries left.`,
                chunkNumber : this.chunkCount,
                attemptsLeft: this.attempts - this.attemptCount,
            });
            return;
        }

        this.dispatch('error', {
            message : `An error occured uploading chunk ${this.chunkCount}. No more retries, stopping upload`,
            chunk   : this.chunkCount,
            attempts: this.attemptCount,
        });
    }

    /**
     * Manage the whole upload by calling getChunk & sendChunk
     * handle errors & retries and dispatch events
     */
    private sendChunks(): void
    {
        if (this.paused || this.offline || this.success) {
            return;
        }

        this.getChunk()
            .then(() =>
            {
                this.attemptCount = this.attemptCount + 1;

                return this.sendChunk();
            })
            .then((res) =>
            {
                const statusCode = res instanceof HttpResponseBase || res instanceof Response
                    ? res.status
                    : res.statusCode;

                if (SUCCESSFUL_CHUNK_UPLOAD_CODES.includes(statusCode)) {
                    this.dispatch('chunkSuccess', {
                        chunk   : this.chunkCount,
                        attempts: this.attemptCount,
                        response: res,
                    });

                    this.attemptCount = 0;
                    this.chunkCount   = this.chunkCount + 1;

                    if (this.chunkCount < this.totalChunks) {
                        AppInjector.get(NgZone).runOutsideAngular(() =>
                        {
                            setTimeout(() => this.sendChunks());
                        });
                    }
                    else {
                        this.success = true;
                        this.dispatch('success');
                    }

                    const chunkFraction = this.chunkCount / this.totalChunks;
                    const uploadedBytes = chunkFraction * this.file.size;

                    const percentProgress = (100 * uploadedBytes) / this.file.size;

                    this.dispatch('progress', percentProgress);
                }
                else if (TEMPORARY_ERROR_CODES.includes(statusCode)) {
                    if (this.paused || this.offline) {
                        return;
                    }
                    this.manageRetries();
                }
                else {
                    if (this.paused || this.offline) {
                        return;
                    }

                    this.dispatch('error', {
                        message    : `Server responded with ${statusCode}. Stopping upload.`,
                        chunkNumber: this.chunkCount,
                        attempts   : this.attemptCount,
                    });
                }
            })
            .catch((err) =>
            {
                if (this.paused || this.offline) {
                    return;
                }

                // this type of error can happen after network disconnection on CORS setup
                this.manageRetries();
            });
    }
}

export const createUpload = (options: UpChunkOptions): UpChunk => new UpChunk(options);
