import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { IServiceProvider } from '@awesome-nodes/mvvm/lib/mvvm/providers';
import { ObjectModel } from '@swan/mvvm/model';
import { ServiceProviderBase } from '@swan/mvvm/providers';
import { ServiceBase } from '@swan/mvvm/services';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import { UserDesignService } from '../services/user/UserDesignService';


@Injectable({
    providedIn: 'root',
})
export class UserServiceProvider extends ServiceProviderBase<ServiceBase<ObjectModel>>
{
    protected constructor(_parentInjector: Injector)
    {
        super(_parentInjector);
    }

    public get designFactory(): IServiceProvider
    {
        return {
            provide: UserDesignService,
        };
    }

    public get developmentFactory(): IServiceProvider
    {
        return this.productionFactory;
    }

    public get productionFactory(): IServiceProvider
    {
        return {
            deps: [
                HttpClient,
                ConfigService,
            ],
        };
    }

}
