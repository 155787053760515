import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityRepository } from '@swan/lib/content/utils';
import { DownloadSize, Image as IImage, ImageFilter, NotificationType, Profile as IProfile } from '@swan/lib/domain';
import { Session, SessionChangedEventArgs, SessionService } from '@swan/session';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { Info } from '@yukawa/chain-base-angular-domain/chain/base/core/info';
import { Credentials } from '@yukawa/chain-security-angular-core';
import { firstValueFrom, lastValueFrom, map, Observable, of, tap } from 'rxjs';
import { Nullable } from 'simplytyped';
import { Image } from './image.entity';
import { ImageService } from './image.service';
import { Profile } from './profile.entity';
import { User } from './user.model';
import { UserService } from './user.service';


@Injectable()
export class ProfileService extends RestAspect
{
    private _profile: Nullable<Profile>;
    private _lastResponse: Nullable<HttpResponse<unknown>> = null;
    private _profileRepository                             = new EntityRepository<Profile>({
        createInstanceFrom: this.createInstanceFrom.bind(this),
    });

    public constructor(
        http: HttpClient,
        _configService: ConfigService,
        private readonly _sessionService: SessionService,
        private readonly _imageService: ImageService,
        private readonly _userService: UserService,
    )
    {
        super(http, _configService, _configService.formatUrl('profileUrl'));

        _sessionService.sessionChanged.subscribe(async (session: Session, ea?: SessionChangedEventArgs) =>
        {
            if (ea?.session instanceof Session.SessionConnected) {
                await this.loadProfile();
            }
            else {
                this._profile = null;
            }
        });
    }

    public get lastResponse(): Nullable<HttpResponse<unknown>>
    {
        return this._lastResponse;
    }

    get profile(): Nullable<Profile>
    {
        return this._profile;
    }

    async init(): Promise<boolean>
    {
        return this.loadProfile();
    }

    async loadProfile(): Promise<boolean>
    {
        if (this._sessionService.auth.isAuthenticated) {
            try {
                this._profile = await lastValueFrom(this.getProfile());
            }
            catch (e) {
                console.error(e);
            }
        }

        return this._profile != null;
    }

    getCode(username?: string): Observable<User>
    {
        const body: Credentials = { username: username };
        return this.http.post<User>(this.formatServiceUrl('/refreshPassword'), body);
    }

    mergeProfile(profile: IProfile): Observable<Profile>
    {
        return this._profileRepository.update(
            this.http.post<Profile>(
                this.formatServiceUrl('/merge'),
                profile instanceof Profile ? profile.toJson() : profile,
            ),
            this.profile as Profile,
        );
    }

    addInterest(interestId: string): Observable<Profile>
    {
        return this._profileRepository.update(
            this.http.put<Profile>(this.formatServiceUrl('/interest/' + interestId), {}),
            this.profile as Profile,
        );
    }

    removeInterest(interestId: string): Observable<Profile>
    {
        return this._profileRepository.update(
            this.http.delete<Profile>(this.formatServiceUrl('/interest/' + interestId)),
            this.profile as Profile,
        );
    }

    getProfile(): Observable<Profile>
    {
        return this._profileRepository.add(this.http.get<IProfile>(this.formatServiceUrl('/')));
    }

    signUp(email: string): Observable<User>
    {
        return this.http.post<User>(this.formatServiceUrl('/register'), {
            username: email,
        }, { observe: 'response' }).pipe(
            map((response) =>
            {
                this._lastResponse = response;
                return response.body as User;
            }),
        );
    }

    public async addSkipNotification(profile: Profile, notificationType: NotificationType): Promise<Profile>
    {
        return await lastValueFrom(this._profileRepository.update(
            this.http.put<Profile>(this.formatServiceUrl(`/skip-notification/${notificationType}`), null),
            profile,
        ));
    }

    public async removeSkipNotification(profile: Profile, notificationType: NotificationType): Promise<Profile>
    {
        return await lastValueFrom(this._profileRepository.update(
            this.http.delete<Profile>(this.formatServiceUrl(`/skip-notification/${notificationType}`)),
            profile,
        ));
    }

    deleteProfile(username: string): Observable<any>
    {
        return this.http.delete(`${this.formatBaseUrl('/profile-service/admin/profile')}/?key=${username}`);
    }

    //region Profile Image

    /**
     * Upload image for current profile
     *
     * @param file
     * @return The image url
     */
    public async uploadImage(file: File): Promise<string>
    {
        const allowedTypes = ['image/jpeg', 'image/png'];

        // Return if the file is not allowed
        if (!allowedTypes.includes(file.type)) {
            return '';
        }

        const image = this._profile?.image || new Image(this._profile?.imageMetadata as IImage);
        image.info  = {
            name: this._profile?.username,
        } as Info;

        await lastValueFrom(this._imageService.uploadAvatar(image, file));

        return this._imageService.downloadUrl(image, {
            size: DownloadSize.medium,
        });
    }

    /**
     * Delete image of current profile
     *
     */
    public async deleteImage(profile: Nullable<Profile> = this._profile): Promise<void>
    {
        await firstValueFrom(this._imageService.query(profile?.imageMetadata as ImageFilter).pipe(
            tap((images) =>
            {
                if (images.rows > 0) {
                    return lastValueFrom(this._imageService.delete(images.items[0]));
                }

                return of(images);
            }),
        ));
    }

    public async loadImage(profile: Nullable<Profile> = this._profile): Promise<void>
    {
        await firstValueFrom(this._imageService.query(profile?.imageMetadata as ImageFilter));
    }

    //endregion

    protected createInstanceFrom(json: Profile): Profile
    {
        return new Profile(json);
    }
}
