import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import moment from 'moment';


@Pipe({ name: 'dateFormat' })
export class DateFormatPipe implements PipeTransform
{
    static _translationsInitialized = false;

    constructor(
        private _translocoService: TranslocoService,
    )
    {
        _translocoService.getAvailableLangs().forEach(
            (lang) =>
            {
                if (!DateFormatPipe._translationsInitialized) {
                    moment.updateLocale(typeof lang === 'string' ? lang : lang.id, {
                        /* eslint-disable @typescript-eslint/naming-convention */
                        longDateFormat: {
                            LT  : 'h:mm A',
                            LTS : 'h:mm:ss A',
                            L   : _translocoService.translate('FORMAT.DATE.SHORT_DATE'),
                            LL  : 'MMMM Do YYYY',
                            LLL : 'MMMM Do YYYY LT',
                            LLLL: 'dddd, MMMM Do YYYY LT',
                        },
                        /* eslint-enable @typescript-eslint/naming-convention */
                    });
                }
            });
    }

    transform(value: moment.MomentInput, dateFormat: string): any
    {
        const date = moment(value);
        return date.isValid() ? date.format(dateFormat) : value;
    }
}
