import { NgModule, Optional, SkipSelf } from '@angular/core';
import { determineUserLanguage, TranslocoCoreModule } from '@swan/lib/config';
import { ContentModule } from '@swan/lib/content';
import { ProfileModule } from '@swan/lib/profile';
import { IconsModule } from 'app/core/icons/icons.module';
import { environment } from 'environments/app';


@NgModule({
    imports: [
        ContentModule,
        IconsModule,
        ProfileModule,
        TranslocoCoreModule.forRoot({
            availableLangs      : [
                {
                    id   : 'en',
                    label: 'English',
                },
                {
                    id   : 'de',
                    label: 'German',
                },
            ],
            defaultLang         : determineUserLanguage(),
            fallbackLang        : 'en',
            reRenderOnLangChange: true,
            prodMode            : environment.production,
        }),
    ],
})
export class CoreModule
{
    /**
     * Constructor
     */
    constructor(
        @Optional() @SkipSelf() parentModule?: CoreModule,
    )
    {
        // Do not allow multiple injections
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
        }
    }
}
