<h1 mat-dialog-title>{{ 'QR.TITLE' | transloco }}</h1>
<div mat-dialog-content>
    <div class="mb-32">
        <div *ngIf="qrCode" class="flex flex-col items-center">
            <qrcode [qrdata]="qrCode"
                    [width]="256"
                    [errorCorrectionLevel]="'M'"></qrcode>
            <div class="qr-area">
                <div class="area"></div>
            </div>
        </div>

        <div *ngIf="!qrCode" class="w-full">

            <!-- <div >
                <mat-form-field style="width:100%"  class="mr-16" fxFlex>
                  <input  matInput name="userPassword"
                    placeholder="password" type="password" [(ngModel)]="userPassword">

                </mat-form-field>
                </div>
                <br><br>
             -->
            <div>
                <mat-form-field class="w-full">
                    <mat-label>SSID</mat-label>
                    <input matInput name="ssid" placeholder="SSID" type="text" [(ngModel)]="ssid">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="w-full">
                    <mat-label>WIFI Password</mat-label>
                    <input matInput name="password" placeholder="WIFI Password" type="password"
                           [(ngModel)]="password">
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions class="space-x-2 space-y-2">
    <button mat-flat-button color="accent" (click)="onNoClick('cancel')" class="mt-2"
    >{{ 'BUTTON.CLOSE' | transloco }}</button>
    <button mat-flat-button color="primary" (click)="generateQR()">Generate QR</button>
    <button mat-flat-button color="accent" (click)="reset()">Reset</button>
</div>
