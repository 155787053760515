import { ObjectBase } from '@awesome-nodes/object/data';
import { IEquatable } from '@swan/mvvm/model';
import { Credentials as ICredentials } from '@yukawa/chain-security-angular-core';


export class Credentials extends ObjectBase implements ICredentials, IEquatable<Credentials>
{
    #_orgID: string | undefined;
    #_password: string;
    #_username: string;

    public constructor(username: string | ICredentials, password?: string, orgId?: string)
    public constructor(username: string | ICredentials, password: string, orgId?: string)
    {
        super();

        if (typeof username !== 'object') {
            this.#_orgID    = orgId;
            this.#_password = password;
            this.#_username = username;
        }
        else {
            this.#_orgID    = username.orgId;
            this.#_password = username.password as string;
            this.#_username = username.username as string;
        }
    }

    //region Public Properties

    public get orgId(): string | undefined
    {
        return this.#_orgID;
    }

    public set orgId(value: string | undefined)
    {
        this.#_orgID = value;
    }

    public get username(): string
    {
        return this.#_username;
    }

    public set username(value: string)
    {
        this.#_username = value;
    }

    public get password(): string
    {
        return this.#_password;
    }

    public set password(value: string)
    {
        this.#_password = value;
    }

    //endregion

    public override equals(other: Credentials): boolean
    {
        return super.equals(other) &&
            this.#_orgID === other.#_orgID &&
            this.#_username === other.#_username &&
            this.#_password === other.#_password;
    }

    public override toJSON(): ICredentials
    {
        return super.toJSON('username', 'password', 'orgId');
    }
}
