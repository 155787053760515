import { ObjectModel } from '@awesome-nodes/mvvm/model';
import { ServiceBase as Base } from '@awesome-nodes/mvvm/services';
import { isPrototypeOf } from '@awesome-nodes/object';
import { Observable } from 'rxjs';
import { ServiceException } from './ServiceException';


/**
 * Represents the basic behavior for any service.
 * Accepts only one worker for a specific keyword at a time.
 */
export abstract class ServiceBase<T extends ObjectModel | Array<ObjectModel>> extends Base<T>
{
    public static handleError(e: Error): void
    {
        if (isPrototypeOf((e as object).constructor, ServiceException)) {
            console.error('Service exception thrown:', e);
        }
        else {
            console.error('Unhandled exception thrown:', e);
        }
    }

    /**
     * Creates a new worker instance for the specified worker name.
     *
     * @param workerName
     * @param args
     * @throws ServiceException
     */
    public createWorker(workerName: string, ...args: Array<unknown>): Observable<T>
    {
        if (workerName == null || workerName == '') {
            throw new ServiceException('The keyword cannot be null or empty.');
        }
        if (this._backgroundWorkers.has(workerName)) {
            throw new ServiceException(`A '${workerName}' task is already in progress.`);
        }

        return super.createBackgroundWorker(this.doWork(workerName, ...args), workerName);
    }

    /**
     * Implement in the derived class to perform the work and return the work result.
     *
     * @param workerName
     * @param args
     */
    // tslint:disable-next-line:member-ordering
    protected abstract doWork(workerName: string, ...args: Array<unknown>): Observable<T>;
}
