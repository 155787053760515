<lib-video-background videoUrl="assets/videos/pexels-ron-lach-8154846_2k.mp4"
                      poster="assets/videos/pexels-ron-lach-8154846_2k.jpg"
                      [autoplay]="true"
                      [loop]="true">
    <div class="backdrop"></div>
</lib-video-background>
<div class='flex flex-col w-full mt-20 overflow-hidden'>

    <div class="z-10 h-full flex flex-col text-white">
        <div class="p-6">
            <mat-icon [svgIcon]="'swan:logo'"
                      class="w-15 sm:w-18 text-black"></mat-icon>
            <h1 class="mt-6 text-white">
                <ng-container [ngSwitch]="showPersonalizeExperience">
                        <span *ngSwitchCase="false" class="absolute" @fadeIn @fadeOut
                              transloco="PERSONALIZE.WELCOME" [translocoParams]="{name: username}"></span>
                    <span *ngSwitchCase="true" @fadeIn
                          transloco="PERSONALIZE.WE_CARE" [translocoParams]="{name: username}"></span>
                </ng-container>
            </h1>
            <span *ngIf="showPersonalizeExperience" @fadeIn class="font-sans inline-block mt-4"
                  transloco="PERSONALIZE.TELL_US"></span>
        </div>

        <div id="user-actions" class="h-full p-6 pb-12 flex flex-col overflow-hidden">

            <button mat-flat-button *ngIf="showPersonalizeExperience" @slideInBottom @fadeIn
                    color="primary" class="swan-mat-button-large tracking-wide mt-auto"
                    [routerLink]="['/setting/personalize']"

                    transloco="PERSONALIZE.PERSONALIZE">
            </button>

            <button mat-flat-button *ngIf="showPersonalizeLater" @slideInBottom @fadeIn
                    class="swan-mat-button-large mt-4 text-black font-bold tracking-wide bg-white"
                    [routerLink]="['/video']"
                    transloco="PERSONALIZE.LATER">
            </button>

        </div>
    </div>
</div>
