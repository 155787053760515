import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { SwanAlertModule } from '@swan/components/alert';
import { VideoModule } from '@swan/lib/content/video';
import { CustomRouteReuseStrategyService } from '@swan/lib/shared';
import { EmptyLayoutComponent } from 'app/layout/layouts/empty/empty.component';
import { QRCodeModule } from 'app/modules/admin/video/qr-code/qr-code.module';
import { SharedModule } from 'app/shared/shared.module';


@NgModule({
    declarations: [
        EmptyLayoutComponent,
    ],
    imports     : [
        MatButtonModule,
        MatIconModule,
        QRCodeModule,
        RouterModule,
        SharedModule,
        SwanAlertModule,
        VideoModule,
    ],
    exports     : [
        EmptyLayoutComponent,
    ],
    providers   : [
        {
            provide : RouteReuseStrategy,
            useClass: CustomRouteReuseStrategyService,
        },
    ],
})
export class EmptyLayoutModule
{
}
