import { Upload } from '@mux/mux-node/types/interfaces/Upload';
import { APP_ENVIRONMENT_TOKEN } from '@swan/lib/config';
import { CordovaService } from '@swan/lib/cordova';
import { AppInjector } from '@swan/lib/shared';
import { Observable, Subject } from 'rxjs';
import { Nullable } from 'simplytyped';
import { createUpload, UpChunk } from './upchunk';


export type UploadStatus = UploadAttempt
    | UploadAttemptFailure
    | UploadChunkSuccess
    | UploadError
    | number
    | undefined;

export type MuxUploadEvent = 'attempt'
    | 'attemptFailure'
    | 'chunkSuccess'
    | 'offline'
    | 'online'
    | 'error'
    | 'progress'
    | 'completed';

export interface MuxUploadStatus
{
    event: MuxUploadEvent;

    message: string;

    progress?: UploadStatus;
}

export interface UploadAttempt
{
    /**
     * The number of the current chunk being attempted.
     */
    chunkNumber: number;
    /**
     * The size (in bytes) of that chunk
     */
    chunkSize: number;
}

export interface UploadAttemptFailure
{
    message: string;
    /**
     * The number of the current chunk being attempted.
     */
    chunkNumber: number;
    attemptsLeft: number;
}

export interface UploadChunkSuccess
{
    /**
     * The number of the chunk being successfully uploaded.
     */
    chunk: number;
    attempts: number;
    response: Response;
}

export interface UploadError
{
    message: string;
    /**
     * The number of the chunk which could not be uploaded.
     */
    chunkNumber: number;
    attempts: number;
}

export class MuxUpload
{
    public readonly uploadProgress$ = new Subject<MuxUploadStatus>();

    private _upload: UpChunk;
    private _completed: boolean = false;
    private _progress: Nullable<MuxUploadStatus>;

    public constructor(upload: Upload, file: File)
    {
        const cordovaService = AppInjector.get(CordovaService);
        this._upload         = createUpload({
            endpoint      : upload.url,
            file,
            chunkSize     : 30720, // Uploads the file in ~30mb chunks
            attempts      : AppInjector.get(APP_ENVIRONMENT_TOKEN).production ? 5 : 1,
            reportProgress: !cordovaService.onCordova,
            mode          : cordovaService.onCordova ? 'fetch' : 'HttpClient',
        });

        this._upload.on('attempt', this.onAttempt.bind(this));
        this._upload.on('attemptFailure', this.onAttemptFailure.bind(this));
        this._upload.on('chunkSuccess', this.onChunkSuccess.bind(this));
        this._upload.on('error', this.onError.bind(this));
        this._upload.on('offline', this.onOffline.bind(this));
        this._upload.on('online', this.onOnline.bind(this));
        this._upload.on('progress', this.onProgress.bind(this));
        this._upload.on('success', this.onSuccess.bind(this));
    }

    public get progress(): Observable<MuxUploadStatus>
    {
        return this.uploadProgress$;
    }

    public get completed(): boolean
    {
        return this._completed;
    }

    private static log(...args: Array<unknown>): void
    {
        if (AppInjector.get(APP_ENVIRONMENT_TOKEN).production === true) {
            return;
        }

        console.log(...args);
    }

    private static error(...args: Array<unknown>): void
    {
        if (AppInjector.get(APP_ENVIRONMENT_TOKEN).production === true) {
            return;
        }

        console.error(...args);
    }

    public pause(): void
    {
        this._upload.pause();
    }

    public resume(): void
    {
        this._upload.resume();
    }

    public abort(): void
    {
        this._upload.abort();
    }

    protected next(event: MuxUploadEvent, status?: UploadStatus, ...message: Array<number | string | object>): void
    {
        MuxUpload.log(...message);
        this._progress = {
            event,
            progress: status,
            message : message.join(' ').replace(' [object Object]', ''),
        };
        this.uploadProgress$.next(this._progress);
    }

    /**
     * Fired immediately before a chunk upload is attempted. chunkNumber is the number of the current chunk being attempted, and chunkSize is the size (in bytes) of that chunk.
     *
     * @param progress
     * @protected
     */
    protected onAttempt(progress: CustomEvent<UploadAttempt>): void
    {
        this.next('attempt', progress.detail, 'Attempt uploading chunk:', progress.detail);
    }

    /**
     * Fired when an attempt to upload a chunk fails.
     *
     * @param progress
     * @protected
     */
    protected onAttemptFailure(progress: CustomEvent<UploadAttemptFailure>): void
    {
        this.next('attemptFailure', progress.detail, 'Attempt failure:', progress.detail);
    }

    /**
     * Fired when an individual chunk is successfully uploaded.
     *
     * @param progress
     * @protected
     */
    protected onChunkSuccess(progress: CustomEvent<UploadChunkSuccess>): void
    {
        this.next('chunkSuccess', progress.detail, 'Chunk success:', progress.detail);
    }

    /**
     * Fired when a chunk has reached the max number of retries or the response code is fatal and implies that retries should not be attempted.
     *
     * @param err
     * @protected
     */
    protected onError(err: CustomEvent<UploadError>): void
    {
        MuxUpload.error('💥 🙀', err.detail);
        this.next('error', err.detail);
        this.uploadProgress$.error(err.detail);
    }

    /**
     * Fired when the client has gone offline.
     *
     * @protected
     */
    protected onOffline(): void
    {
        MuxUpload.log();
        this.next('offline', undefined, 'Client has gone offline.');
    }

    /**
     * Fired when the client has gone online.
     *
     * @protected
     */
    protected onOnline(): void
    {
        this.next('online', undefined, 'Client has gone online.');
    }

    /**
     * Fired continuously with incremental upload progress. This returns the current percentage of the file that's been uploaded.
     *
     * @param progress
     * @protected
     */
    protected onProgress(progress: CustomEvent<number>): void
    {
        const percentage = Math.round(progress.detail);
        this.next('progress', percentage, 'Uploaded', percentage, '% of this file.');
    }

    /**
     * Fired when the upload is finished successfully.
     *
     * @protected
     */
    protected onSuccess(): void
    {
        this._completed = true;
        this.next('completed', undefined, 'Wrap it up, we\'re done here. 👋');
        this.uploadProgress$.complete();
    }
}
