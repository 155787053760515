import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { EntityFilter } from '@yukawa/chain-base-angular-domain/chain/base/core/entity';
import { Observable, tap } from 'rxjs';
import { PlainObject } from 'simplytyped';
import { CachedRepoAspectRest } from './cached-repo-aspect-rest';
import { Entity } from './entity';
import { EntityKey, EntityRepository } from './entity-repository';


export abstract class CachedRepoEntityService<TEntity extends Entity, TFilter extends EntityFilter, TKey extends EntityKey>
    extends CachedRepoAspectRest<TKey, TEntity, TFilter>
{
    public readonly repository = new EntityRepository<TEntity>(
        {
            createInstanceFrom: this.createInstanceFrom.bind(this),
            keyForEntityCache : this.keyForEntityCache ? this.keyForEntityCache.bind(this) : this.keyForEntityCache,
        },
    );

    //region RepoAspectRest Overloads

    public override load(key: TKey): Observable<TEntity>
    {
        return this.repository.add(super.load(key));
    }

    public override find(filter: TFilter): Observable<TEntity[]>
    {
        return this.repository.add(super.find(filter));
    }

    public override query(filter: TFilter): Observable<QueryResult<TEntity>>
    {
        return this.repository.add(super.query(filter));
    }

    public override put(entity: TEntity): Observable<TEntity>
    {
        return this.repository.add(super.put(entity.toJson() as TEntity), entity);
    }

    public override create(entity: TEntity): Observable<TEntity>
    {
        return this.repository.add(super.create(entity.toJson() as TEntity), entity);
    }

    public override update(entity: TEntity): Observable<TEntity>
    {
        return this.repository.update(super.update(entity.toJson() as TEntity), entity);
    }

    public override merge(entity: TEntity): Observable<TEntity>
    {
        return this.repository.update(super.merge(entity.toJson() as TEntity), entity);
    }

    public override edit(entity: TEntity): Observable<TEntity>
    {
        return this.repository.update(super.edit(entity.toJson() as TEntity), entity);
    }

    public override deleteByKey(key: TKey): Observable<TEntity>
    {
        return this.repository.remove(super.deleteByKey(key), this.repository.get(key) as TEntity);
    }

    public override delete(entity: TEntity): Observable<TEntity>
    {
        return this.repository.remove(super.delete(entity.toJson() as TEntity), entity);
    }

    public override deleteAll(filter: TFilter): Observable<TEntity[]>
    {
        return this.repository.remove<Observable<Array<TEntity>>>(super.deleteAll(filter)).pipe(
            tap((entities) =>
            {
                this.removeQueryCache(filter);
            }),
        );
    }

    //endregion

    public all(): Observable<Array<TEntity>>
    {
        return this.repository.add(this.http.get<Array<TEntity>>(
            this.formatServiceUrl('/all') as string, {}),
        );
    }

    public add(key: TKey): Observable<TEntity>
    {
        return this.repository.add(this.http.post<Array<TEntity>>(
            this.formatServiceUrl('/add') as string, key),
        );
    }

    public remove(key: TKey): Observable<TEntity>
    {
        return this.repository.remove(
            this.http.post<Array<TEntity>>(this.formatServiceUrl('/remove') as string, key),
            this.repository.get(key) as TEntity,
        );
    }

    //region IEntityRepositoryService Members

    protected keyForEntityCache?(json: TEntity): string;

    protected abstract createInstanceFrom(json: PlainObject, other?: PlainObject): TEntity;

    //endregion
}
