import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IQRAuth } from '@swan/lib/config';
import { SessionConnected, SessionService, User } from '@swan/session';


@Component({
    selector   : 'qr-code-dialog',
    templateUrl: 'qr-code-dialog.component.html',
})
export class QRCodeDialogComponent
{
    ssid: string     = null;
    password: string = null;
    qrCode: string   = '';

    constructor(
        public dialogRef: MatDialogRef<QRCodeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _sessionService: SessionService,
    )
    {
        dialogRef.disableClose = true;
    }

    get user(): User
    {
        return (this._sessionService.session as SessionConnected).user;
    }

    onNoClick(type): void
    {
        this.dialogRef.close({ type: type });
    }

    generateQR(): void
    {
        //WIFI:T:WPA;S:mynetwork;P:mypass;
        const _code: IQRAuth = {
            wifi : {
                id      : this.ssid,
                password: this.password,
            },
            token: this.user.authToken.refresh_token,
        };
        this.qrCode          = JSON.stringify(_code);
    }

    reset(): void
    {
        this.qrCode   = null;
        this.password = null;
        this.ssid     = null;
    }

}


