import {Change, ChangeFilter, Created, EntityFilter} from '@yukawa/chain-base-angular-domain';
import {Content} from './content';
import {PublicProfile} from './profile';


/**
*	@generator rest2ts
*	Represents the notification for some target user <br/>
*	javaType: inc.yukawa.swan.base.core.domain.notification.Notification <br/>
*/
export interface Notification extends  Created{
created?: Change;

/**
*	@example {}
*/
details?: {[key: string]: Object};

/**
*	@example 1
*/
notificationId?: number;
sourceContent?: Content;
sourceContentId?: number;
sourceUser?: PublicProfile;

/**
*	@example user1@example.com
*/
sourceUsername?: string;

/**
*	values: FOLLOW, UNFOLLOW, CONTENT_PUBLISHED, COMMENT_ADDED<br/>
*	@example FOLLOW
*/
type?: string;

/**
*	@example user1@example.com
*/
username?: string;
}


/**
*	@generator rest2ts
*	Notification filter <br/>
*	javaType: inc.yukawa.swan.base.core.domain.notification.NotificationFilter <br/>
*/
export interface NotificationFilter extends  EntityFilter{
created?: ChangeFilter;

/**
*	@example 1
*/
notificationId?: number;

/**
*	@example ["FOLLOW"]
*/
types?: string[];

/**
*	@example user1@example.com
*/
username?: string;
}

export enum NotificationType {
FOLLOW = "FOLLOW",
UNFOLLOW = "UNFOLLOW",
CONTENT_PUBLISHED = "CONTENT_PUBLISHED",
COMMENT_ADDED = "COMMENT_ADDED",
CONTENT_LIKED = "CONTENT_LIKED"
}

