import { Injectable, Injector } from '@angular/core';
import { ObjectModel } from '@swan/mvvm/model';
import { ServiceBase } from '@swan/mvvm/services';
import { Observable, Subscriber } from 'rxjs';
import { AuthToken } from '../../model/auth';
import { ChangePassword, Credentials, UserInfo } from '../../model/user';
import { IAuthTokenResponse } from '../auth';
import { SessionStoreService } from '../SessionStoreService';
import { IUserService, UserRestEndpoints } from './IUserService';


@Injectable({
    providedIn: 'root',
})
export class UserDesignService extends ServiceBase<ObjectModel> implements IUserService
{
    public constructor(
        private _injector: Injector,
    )
    {
        super();
    }

    private static generateUserInfo(sessionToken: IAuthTokenResponse): UserInfo
    {
        return new UserInfo({
            account      : {
                credentials : {
                    orgId   : 'yuk',
                    password: 'admin',
                    username: sessionToken.username,
                },
                details     : {},
                roleContexts: [
                    {
                        orgId: 'string',
                        roles: [
                            'string',
                        ],
                    },
                ],
                roles       : [
                    'string',
                ],
                status      : {
                    accountNonExpired    : true,
                    accountNonLocked     : true,
                    credentialsNonExpired: true,
                    enabled              : true,
                },
            },
            change       : {
                date : new Date('2021-12-15T17:38:23.969Z'),
                notes: 'my example change',
                user : 'sample@yukawa.de',
            },
            created      : {
                date : new Date('2021-12-15T17:38:23.969Z'),
                notes: 'my example change',
                user : 'sample@yukawa.de',
            },
            defaultOrgId : 'string',
            details      : {},
            groupContexts: [
                {
                    groups: [
                        'string',
                    ],
                    orgId : 'string',
                },
            ],
            groups       : [
                'string',
            ],
            orgIds       : [
                'string',
            ],
            owner        : {
                groups: [
                    'string',
                ],
                user  : 'string',
            },
            person       : {
                addresses  : [
                    {
                        addressId  : 'string',
                        city       : 'string',
                        countryCode: 'string',
                        geoLocation: {
                            altitude : 0,
                            latitude : 0,
                            longitude: 0,
                        },
                        houseNumber: 'string',
                        region     : 'string',
                        state      : 'string',
                        street     : 'string',
                        type       : 'main',
                        zipCode    : 'string',
                    },
                ],
                change     : {
                    date : new Date('2021-12-15T17:38:23.969Z'),
                    notes: 'my example change',
                    user : 'sample@yukawa.de',
                },
                companyName: 'string',
                created    : {
                    date : new Date('2021-12-15T17:38:23.969Z'),
                    notes: 'my example change',
                    user : 'sample@yukawa.de',
                },
                email      : sessionToken.username,
                firstName  : 'string',
                lang       : 'string',
                lastName   : 'string',
                mobile     : 'string',
                phoneNumber: 'string',
                role       : 'string',
                // salutation : 'string',
                shortName: 'string',
                title    : 'string',
                vatNumber: 'string',
            },
            username     : sessionToken.username,
        });
    }

    public loadUser(token: AuthToken): Observable<UserInfo>
    {
        return this.createWorker(UserRestEndpoints.loadUser, token) as Observable<UserInfo>;
    }

    public changePassword(credentials: Credentials): Observable<ChangePassword>
    {
        return this.createWorker(UserRestEndpoints.changePassword) as Observable<ChangePassword>;
    }

    public resetPassword(username: string): Observable<ChangePassword>
    {
        return this.createWorker(UserRestEndpoints.resetPassword) as Observable<ChangePassword>;
    }

    protected doWork(workerName: string, token?: AuthToken): Observable<ObjectModel>
    {
        return new Observable((observer: Subscriber<ObjectModel>) =>
        {
            setTimeout(
                () =>
                {
                    switch (workerName) {
                        case UserRestEndpoints.loadUser:
                            observer.next(UserDesignService.generateUserInfo(token as AuthToken));
                            break;
                        case UserRestEndpoints.changePassword:
                            observer.next(new ChangePassword(this.generateChangePassword()));
                            break;
                    }
                    observer.complete();
                },
                0,
            );
            //eslint-disable-next-line
            return {
                unsubscribe: (): void =>
                {
                },
            };
        });
    }

    private generateChangePassword(): ChangePassword
    {
        return new ChangePassword({
            fqn     : 'java.util.Date',
            key     : 4711,
            messages: [
                {
                    code : 'ENV.COM',
                    level: 2,
                    text : 'Swagger Message',
                    type : 'string',
                },
            ],
            op      : 'ping',
            stamp   : new Date().toISOString(),
            took    : 0,
            userId  : this._injector.get(SessionStoreService).getJSON<UserInfo>('user')?.username as string,
        });
    }
}
