import { NgModule } from '@angular/core';
import { SwanTailwindService } from '@swan/services/tailwind/tailwind.service';

@NgModule({
    providers: [
        SwanTailwindService
    ]
})
export class SwanTailwindConfigModule
{
    /**
     * Constructor
     */
    constructor(private _swanTailwindConfigService: SwanTailwindService)
    {
    }
}
