import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from '@swan/lib/shared';
import { QueryTableComponent } from './query-table.component';


@NgModule({
    declarations: [
        QueryTableComponent,
    ],
    imports     : [
        CommonModule,
        MatIconModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSortModule,
        MatTableModule,
        SharedModule,
        TranslocoModule,
    ],
    exports     : [
        QueryTableComponent,
    ],
})
export class QueryTableModule
{
}
