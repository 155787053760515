export class DateUtils
{

    /**
     * Some browsers cannot handle Dateformat dd-mm-yyyy. This function converts to
     * dd/mm/yyyy (Safari requires this format for some reason) and fixes timezone offset
     *
     * @param date Date string that should be parsed
     */
    public static parseDateString(date: string): Date
    {
        let formattedDate = date.replace(/-/g, '/').toString();
        formattedDate     = formattedDate.replace(/T/g, ' ');
        formattedDate     = formattedDate.split('.')[0];
        const newDate     = new Date(formattedDate);

        return new Date(newDate.getTime() - (new Date().getTimezoneOffset() * 60000));
    }
}
