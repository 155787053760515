import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FavoriteContent } from '@swan/lib/domain';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { Change, QueryResult } from '@yukawa/chain-base-angular-domain';
import { TableFilter } from '@yukawa/chain-base-angular-domain/chain/base/core/filter';
import { Observable } from 'rxjs';
import { Content } from './content.model';
import { FileInfo } from './docs.service';


export interface Favorite extends FavoriteContent
{
    change: Change;
    contentId: number;
    username: string;
}

@Injectable()
export class FavoriteService<T extends FileInfo | Content = Content> extends RestAspect
{
    active: boolean = false;

    constructor(
        http: HttpClient,
        configService: ConfigService,
    )
    {
        super(http, configService, configService.formatUrl('favoriteUrl'));
    }

    private static isFileInfo(info: object): info is FileInfo
    {
        return info.hasOwnProperty('fileId');
    }

    add(file: T): Observable<Favorite>
    {
        return this.http.post<Favorite>(
            this.formatServiceUrl('/add'),
            FavoriteService.isFileInfo(file) ? file.fileId : file.contentId,
        );
    }

    remove(file: T): Observable<Favorite>
    {
        return this.http.post<Favorite>(
            this.formatServiceUrl('/remove'),
            FavoriteService.isFileInfo(file) ? file.fileId : file.contentId,
        );
    }

    override query<E = Favorite>(filter: string | TableFilter): Observable<QueryResult<E>>
    {
        return this.http.post<QueryResult<E>>(this.formatServiceUrl('/query'), filter);
    }
}
