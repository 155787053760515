import { Injector, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SwanModule } from '@swan';
import { CarouselModule } from '@swan/lib/components';
import { APP_ENVIRONMENT_TOKEN } from '@swan/lib/config';
import { CordovaService } from '@swan/lib/cordova';
import { SwanMockApiModule } from '@swan/lib/mock-api';
import { ProfileComponentsModule } from '@swan/lib/profile/components';
import { HammerConfig, setAppInjector } from '@swan/lib/shared';
import { ENVIRONMENT_TOKEN } from '@swan/mvvm/providers';
import { SwanConfigModule } from '@swan/services/config';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { appConfig } from 'app/core/config/app.config';
import { CoreModule } from 'app/core/core.module';
import { LayoutModule } from 'app/layout/layout.module';
import { mockApiServices } from 'app/mock-api';
import { environment } from 'environments/app';
import { LottieModule } from 'ngx-lottie';
import { LottiePlayerFactoryOrLoader } from 'ngx-lottie/lib/symbols';
import { MarkdownModule } from 'ngx-markdown';


ENVIRONMENT_TOKEN.scope.addProvider({
    provide : ENVIRONMENT_TOKEN,
    useValue: environment.production,
});

const routerConfig: ExtraOptions = {
    useHash                  : true,
    scrollPositionRestoration: 'enabled',
    preloadingStrategy       : PreloadAllModules,
    initialNavigation        : 'disabled',
};

export const playerFactory: LottiePlayerFactoryOrLoader = () => import('lottie-web');

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        LottieModule.forRoot({ player: playerFactory }),
        HammerModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Swan, SwanConfig & SwanMockAPI
        SwanModule,
        SwanConfigModule.forRoot(appConfig),
        SwanMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,
        CarouselModule.forRoot(),
        // Layout module of your application
        LayoutModule,
        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({}),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
        MatIconModule,
        ProfileComponentsModule,
    ],
    bootstrap   : [
        AppComponent,
    ],
    providers   : [
        CordovaService,
        {
            provide : APP_ENVIRONMENT_TOKEN,
            useValue: environment,
        },
        {
            provide : HAMMER_GESTURE_CONFIG,
            useClass: HammerConfig,
        },
    ],
})
export class AppModule
{
    constructor(injector: Injector)
    {
        setAppInjector(injector);
    }
}
