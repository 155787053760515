import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from '@swan/lib/shared';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { LottieModule } from 'ngx-lottie';
import { BirthdateComponent } from './birthdate/birthdate.component';
import { ConsentComponent } from './consent/consent.component';
import { DrumRollComponent } from './drum-roll/drum-roll.component';
import { GenderComponent } from './gender/gender.component';
import { InterestsComponent } from './interests/interests.component';
import { PersonalizeService } from './personalize.service';
import { ProfileIconComponent } from './profile-icon/profile-icon.component';
import { SettingsOptionsComponent } from './settings/options';
import { SkinTypeComponent } from './skin-type/skin-type.component';
import { UserIconComponent } from './user-icon/user-icon.component';


@NgModule({
    declarations: [
        BirthdateComponent,
        ConsentComponent,
        DrumRollComponent,
        GenderComponent,
        InterestsComponent,
        ProfileIconComponent,
        SettingsOptionsComponent,
        SkinTypeComponent,
        UserIconComponent,
    ],
    imports: [
        A11yModule,
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatDatepickerModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatRippleModule,
        MatSlideToggleModule,
        ReactiveFormsModule,
        RoundProgressModule,
        RouterModule,
        SharedModule,
        TranslocoModule,
        LottieModule,
        MatDialogModule,
    ],
    providers   : [
        PersonalizeService,
    ],
    exports     : [
        BirthdateComponent,
        ConsentComponent,
        GenderComponent,
        InterestsComponent,
        ProfileIconComponent,
        SettingsOptionsComponent,
        SkinTypeComponent,
        UserIconComponent,
    ],
})
export class ProfileComponentsModule
{

}
