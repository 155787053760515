import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Animation, StatusBarInfo } from '@capacitor/status-bar';
import { TranslocoService } from '@ngneat/transloco';
import { ErrorAlertComponentBase } from '@swan/components/error-alert-component-base';
import { swanAnimations } from '@swan/lib/animations';
import { VideoTutorialComponent } from '@swan/lib/content/components/record';
import { SwanVideoListService } from '@swan/lib/content/video';
import { CordovaService, StatusBar } from '@swan/lib/cordova';
import { AppInjector, ElementPadding, NavigationService, SwanAlertService } from '@swan/lib/shared';
import { LayoutScheme } from 'app/layout/layout.types';
import { takeUntil } from 'rxjs';


@Component({
    selector     : 'empty-layout',
    templateUrl  : './empty.component.html',
    encapsulation: ViewEncapsulation.None,
    animations   : swanAnimations,
})
export class EmptyLayoutComponent extends ErrorAlertComponentBase implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
    @Input() displayFooter: boolean = true;
    @Input() errorAlerts: boolean   = false;
    @Input() scheme: LayoutScheme;
    @Input() statusBar: boolean;
    @Input() statusBarOverlays: boolean;
    @Input() statusBarPadding: boolean;

    private _statusBarInfo: StatusBarInfo;

    /**
     * Constructor
     */
    constructor(
        private readonly _router: Router,
        private readonly _dialog: MatDialog,
        private readonly _cordovaService: CordovaService,
        private readonly _navigationService: NavigationService,
        private readonly _videoListService: SwanVideoListService,
        _swanAlertService: SwanAlertService,
        _translocoService: TranslocoService,
        changeDetector: ChangeDetectorRef,
    )
    {
        super(
            changeDetector,
            _swanAlertService,
            _translocoService,
        );
    }

    get bodyPaddingY(): string
    {
        let padding = (new ElementPadding()).y;
        if (this.displayFooter) {
            padding += 65;
        }

        return padding + 'px';
    }

    public get bodyPaddingTop(): string
    {
        return String(this.statusBarPadding && this._statusBarInfo?.visible && this._statusBarInfo.overlays ? 35 : 0) + 'px';
    }

    get currentActiveRoute(): string
    {
        return this._router.url.split('/').pop();
    };

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    async ngOnInit(): Promise<void>
    {
        if (this._cordovaService.onCordova) {
            await AppInjector.get(StatusBar).setOverlaysWebView(true);
            this._statusBarInfo = await AppInjector.get(StatusBar).getInfo();
        }
    }

    async ngAfterViewInit(): Promise<void>
    {
        (await this._cordovaService.backButton())
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(async (event) =>
            {
                await this._navigationService.back();
            });
    }

    public async ngOnChanges(changes: SimpleChanges): Promise<void>
    {
        if (!this._cordovaService.onCordova) {
            return;
        }

        const statusBar = AppInjector.get(StatusBar);

        if (changes['statusBar']) {
            if (changes['statusBar'].currentValue === false) {
                await statusBar.hide({ animation: Animation.Fade });
            }
            else {
                await statusBar.show({ animation: Animation.Fade });
            }
        }

        if (changes['statusBarOverlays']) {
            if (changes['statusBarOverlays'].currentValue === false) {
                await statusBar.setOverlaysWebView(false);
            }
            else {
                await statusBar.setOverlaysWebView(true);
            }
        }

        this._statusBarInfo = await AppInjector.get(StatusBar).getInfo();

        if (changes['scheme'] && this._statusBarInfo.visible) {
            switch (changes['scheme'].currentValue as LayoutScheme) {
                case 'dark':
                    await statusBar.setStyleDark();
                    break;
                case 'light':
                    await statusBar.setStyleLight();

                    break;
            }
        }

        this._statusBarInfo = await AppInjector.get(StatusBar).getInfo();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        super.ngOnDestroy();
    }

    navigateTo(url: string): void
    {
        this._router.navigate([url]);
    }

    routeSegmentActive(segment: string): boolean
    {
        return this._router.url.split('/').some(_segment => _segment === segment);
    }

    public navigateToRecord(): void
    {
        this.navigateTo(VideoTutorialComponent.skip ? '/video/record' : '/video/record/tutorial');
    }

    public navigateToHome(): void
    {
        this.navigateTo('/video');
    }
}
