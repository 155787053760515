<div class="swan-vertical-navigation-wrapper">

    <!-- Header -->
    <div class="swan-vertical-navigation-header">
        <ng-content select="[swanVerticalNavigationHeader]"></ng-content>
    </div>

    <!-- Content -->
    <div
        class="swan-vertical-navigation-content"
        swanScrollbar
        [swanScrollbarOptions]="{wheelPropagation: inner, suppressScrollX: true}"
        #navigationContent>

        <!-- Content header -->
        <div class="swan-vertical-navigation-content-header">
            <ng-content select="[swanVerticalNavigationContentHeader]"></ng-content>
        </div>

        <!-- Items -->
        <ng-container *ngFor="let item of navigation; trackBy: trackByFn">

            <!-- Skip the hidden items -->
            <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">

                <!-- Aside -->
                <swan-vertical-navigation-aside-item
                    *ngIf="item.type === 'aside'"
                    [item]="item"
                    [name]="name"
                    [activeItemId]="activeAsideItemId"
                    [autoCollapse]="autoCollapse"
                    [skipChildren]="true"
                    (click)="toggleAside(item)"></swan-vertical-navigation-aside-item>

                <!-- Basic -->
                <swan-vertical-navigation-basic-item
                    *ngIf="item.type === 'basic'"
                    [item]="item"
                    [name]="name"></swan-vertical-navigation-basic-item>

                <!-- Collapsable -->
                <swan-vertical-navigation-collapsable-item
                    *ngIf="item.type === 'collapsable'"
                    [item]="item"
                    [name]="name"
                    [autoCollapse]="autoCollapse"></swan-vertical-navigation-collapsable-item>

                <!-- Divider -->
                <swan-vertical-navigation-divider-item
                    *ngIf="item.type === 'divider'"
                    [item]="item"
                    [name]="name"></swan-vertical-navigation-divider-item>

                <!-- Group -->
                <swan-vertical-navigation-group-item
                    *ngIf="item.type === 'group'"
                    [item]="item"
                    [name]="name"
                    [autoCollapse]="autoCollapse"></swan-vertical-navigation-group-item>

                <!-- Spacer -->
                <swan-vertical-navigation-spacer-item
                    *ngIf="item.type === 'spacer'"
                    [item]="item"
                    [name]="name"></swan-vertical-navigation-spacer-item>

            </ng-container>

        </ng-container>

        <!-- Content footer -->
        <div class="swan-vertical-navigation-content-footer">
            <ng-content select="[swanVerticalNavigationContentFooter]"></ng-content>
        </div>

    </div>

    <!-- Footer -->
    <div class="swan-vertical-navigation-footer">
        <ng-content select="[swanVerticalNavigationFooter]"></ng-content>
    </div>

</div>

<!-- Aside -->
<div
    class="swan-vertical-navigation-aside-wrapper"
    *ngIf="activeAsideItemId"
    swanScrollbar
    [swanScrollbarOptions]="{wheelPropagation: false, suppressScrollX: true}"
    [@fadeInLeft]="position === 'left'"
    [@fadeInRight]="position === 'right'"
    [@fadeOutLeft]="position === 'left'"
    [@fadeOutRight]="position === 'right'">

    <!-- Items -->
    <ng-container *ngFor="let item of navigation; trackBy: trackByFn">

        <!-- Skip the hidden items -->
        <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">

            <!-- Aside -->
            <swan-vertical-navigation-aside-item
                *ngIf="item.type === 'aside' && item.id === activeAsideItemId"
                [item]="item"
                [name]="name"
                [autoCollapse]="autoCollapse"></swan-vertical-navigation-aside-item>

        </ng-container>

    </ng-container>

</div>
