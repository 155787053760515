import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import { catchError, Observable, switchMap, tap, throwError } from 'rxjs';
import { Session, SessionException } from '../model';
import { AUTH_INTERCEPTOR_OPTIONS } from './AuthInterceptiorOptions';
import { SessionService } from './SessionService';


@Injectable({
    providedIn: 'root',
})
/**
 * Adds authorization header with jwt token if available.
 */
export class AuthInterceptor implements HttpInterceptor
{
    private _refreshObservable!: Observable<Session>;

    constructor(
        private readonly _configService: ConfigService,
        private readonly _sessionService: SessionService,
    )
    {

    }

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>>
    {
        if (AUTH_INTERCEPTOR_OPTIONS.enabled && Session.instance instanceof Session.SessionConnected &&
            request.url.startsWith(this._configService.getBaseUrl())) {

            if (AUTH_INTERCEPTOR_OPTIONS.refreshEnabled && this._refreshObservable == null) {

                AUTH_INTERCEPTOR_OPTIONS.enabled = false;

                this._refreshObservable = this._sessionService.refresh();

                AUTH_INTERCEPTOR_OPTIONS.enabled = true;
            }

            if (AUTH_INTERCEPTOR_OPTIONS.refreshEnabled && this._refreshObservable != null) {
                return this._refreshObservable.pipe(
                    tap(() => this._refreshObservable = null as never),
                    switchMap(response => this.handle(response, request, next)),
                );
            }

            return this.handle(Session.instance, request, next);
        }

        return next.handle(request);
    }

    private handle(session: Session, request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>>
    {
        if (session instanceof Session.SessionDisconnected) {
            this.signOut();

            return throwError(() => new SessionException('Session expired'));
        }

        if (!request.headers.has('Authorization')) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${Session.instance.user?.authToken.accessToken}`,
                },
            });

            // Response
            return next.handle(request).pipe(
                catchError((error) =>
                {
                    // Catch "401 Unauthorized" responses
                    if (error instanceof HttpErrorResponse && error.status === 401) {
                        this.signOut();
                    }

                    return throwError(error);
                }),
            );
        }

        return next.handle(request);
    }

    private signOut(): void
    {
        // Sign out
        this._sessionService.logout();

        // Reload the app
        location.reload();
    }
}
