import { A11yModule } from '@angular/cdk/a11y';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { CloseButtonModule, TooltipModule, VideoBackgroundModule } from '@swan/lib/components';
import { DefaultImageModule, SharedModule } from '@swan/lib/shared';
import { VideoDetailsComponent, VideoDetailsGuardService, VideoTagsComponent } from './details';
import { VideoTutorialComponent } from './tutorial';
import { VideoRecordComponent } from './video-record.component';
import { videoRecordRoutes } from './video-record.routing';


@NgModule({
    declarations: [
        VideoDetailsComponent,
        VideoRecordComponent,
        VideoTagsComponent,
        VideoTutorialComponent,
    ],
    imports     : [
        A11yModule,
        CloseButtonModule,
        CommonModule,
        DefaultImageModule,
        MatButtonModule,
        MatChipsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressBarModule,
        MatTooltipModule,
        ReactiveFormsModule,
        RouterModule,
        RouterModule.forChild(videoRecordRoutes),
        SharedModule,
        TextFieldModule,
        TooltipModule,
        TranslocoModule,
        VideoBackgroundModule,
    ],
    providers   : [
        VideoDetailsGuardService,
    ],
})
export class VideoRecordModule
{
}
