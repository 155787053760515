<mat-paginator [length]="100"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 25, 100]"
               [showFirstLastButtons]="true"
               aria-label="Select page">
</mat-paginator>

<mat-table mat-table [dataSource]="dataSource" matSort matSortDirection="asc" matSortDisableClear
           class="w-full">
    <ng-container *ngFor="let _vodDetail of dataSource.headers" matColumnDef="{{ _vodDetail.key }}">

        <mat-header-cell mat-sort-header="{{ _vodDetail.key }}" *matHeaderCellDef>
            {{ _vodDetail.label | transloco }}
        </mat-header-cell>

        <mat-cell *matCellDef="let row">
            <ng-container [ngSwitch]="_vodDetail.group !== undefined && _vodDetail.tableGroup === true">

                <ng-container *ngSwitchCase="false" [ngTemplateOutlet]="itemTemplate"
                              [ngTemplateOutletContext]="{ row, detail: _vodDetail }">
                </ng-container>

                <ng-container *ngSwitchCase="true">

                    <div class="flex space-x-2">
                        <ng-container *ngFor="let _group of dataSource.detailGroups.get(_vodDetail.key)">

                            <div class="flex" [ngClass]="{
                            'flex-col space-y-1': _group.direction === 'vertical',
                            'flex-row space-x-1': _group.direction === 'horizontal'
                        }">
                                <ng-container *ngFor="let _groupDetail of _group.details"
                                              [ngTemplateOutlet]="itemTemplate"
                                              [ngTemplateOutletContext]="{ row, detail: _groupDetail }"></ng-container>
                            </div>

                        </ng-container>

                    </div>

                </ng-container>

            </ng-container>

        </mat-cell>

    </ng-container>

    <mat-header-row *matHeaderRowDef="dataSource.displayedColumns;sticky: true"></mat-header-row>
    <mat-row matRipple *matRowDef="let row; columns: dataSource.displayedColumns;"
             class="pt-2 pb-2"
             [ngClass]="{'mat-row-active': row.selected}"
             (click)="dataSource.selectedEntry = row;">
    </mat-row>

</mat-table>

<ng-template #itemTemplate let-row="row" let-detail="detail">
    <ng-container [ngSwitch]="detail.type">

        <span *ngSwitchCase="'date'" @fadeIn
              class="whitespace-nowrap {{ detail.class }}">{{ getRowDetail(row, detail) | dateFormat: 'L' }}</span>

        <ng-container *ngSwitchCase="'image'" [ngSwitch]="!!getRowDetail(row, detail)">

            <div class="inline-block w-20 h-20 flex justify-center">
                <mat-icon *ngSwitchCase="false" class="icon-size-14 text-hint"
                          [svgIcon]="'iconsmind:file'" @fadeIn></mat-icon>

                <img *ngSwitchCase="true" class="h-20 w-auto object-cover rounded-lg {{ detail.class }}"
                     [src]="getRowDetail(row, detail)" alt="Thumbnail" @fadeIn>

            </div>

        </ng-container>

        <ng-container *ngSwitchDefault [ngSwitch]="detail.markdownSupport === true">
            <span *ngSwitchCase="false" @fadeIn class="{{ detail.class }}">{{ getRowDetail(row, detail) }}</span>
            <span *ngSwitchCase="true" @fadeIn [innerHTML]="getRowDetail(row, detail)"
                  class="{{ detail.class }}"></span>
        </ng-container>

    </ng-container>
</ng-template>
