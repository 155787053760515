import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { DocsService, ThumbnailDimensions } from './docs.service';


@Pipe({ name: 'docsImageThumbnail' })
export class DocsImageThumbnailPipe implements PipeTransform
{
    constructor(private _service: DocsService)
    {
    }

    transform(fileId: string, dimensions: ThumbnailDimensions = 'MEDIUM'): Observable<string>
    {
        const getThumbnail = async (): Promise<string> => await this._service.getImageThumbnail(fileId, dimensions);

        return new Observable<string>((observer) =>
        {
            observer.next('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');

            const thumbnail = getThumbnail();
            thumbnail.then(thumb => observer.next(thumb));
            thumbnail.catch(error => observer.error(error));

            return {
                unsubscribe: (): void =>
                {
                },
            };
        });
    }

}
