import { Entity } from '@swan/lib/content/utils';
import { DownloadSize } from '@swan/lib/domain';
import { AppInjector } from '@swan/lib/shared';
import { Nullable } from 'simplytyped';
import { Image } from './image.entity';
import { ImageService } from './image.service';
import { IInterestInfo } from './interests.types';


export class InterestInfo extends Entity<IInterestInfo> implements IInterestInfo
{
    public assignments!: number;
    public desc!: string;
    public id!: string;
    public interests!: InterestInfo[];
    public name!: string;
    public selected!: boolean;
    public shortName!: string;

    constructor(initialData: IInterestInfo)
    {
        super(initialData);
    }

    public get key(): string
    {
        return this.id;
    }

    public get image(): Nullable<Image>
    {
        return Array.from(AppInjector.get(ImageService).repository.store.values())
            .find(_interest => _interest.related.id === this.id) as Image;
    }

    public get imageUrl(): Nullable<string>
    {
        const image = this.image;

        if (!image) {
            return null;
        }

        return AppInjector.get(ImageService).downloadUrl(image, {
            size: DownloadSize.medium,
        });
    }

    public get hasSelectedChildren(): boolean
    {
        return this.interests.some((_interest: InterestInfo) => _interest.selected);
    }

    public get hasMore(): boolean
    {
        return this.interests.some((_interest: InterestInfo) => _interest.interests.length > 0);
    }

    public get selectedChildren(): Array<InterestInfo>
    {
        return this.interests.reduce(
            this.filterSelectedInterests.bind(this),
            new Array<InterestInfo>(),
        );
    }

    public updateFromJson(data: IInterestInfo, other: unknown): void
    {
        this.setFromJson(data, [
            'assignments',
            'desc',
            'id',
            'interests',
            'name',
            'selected',
            'shortName',
        ], undefined, {
            interests: (interests: Array<IInterestInfo>) => interests.map(_interest => new InterestInfo(_interest)),
        });
    }

    public toJson(): IInterestInfo
    {
        return this.toJsonWithKeys(['id']);
    }

    public override toString(): string
    {
        return this.name;
    }

    private filterSelectedInterests(current: Array<InterestInfo>, next: InterestInfo): Array<InterestInfo>
    {
        if (next.selected || next.hasSelectedChildren) {
            const interests = new Array<InterestInfo>();
            next.interests.forEach(interest => this.filterSelectedInterests(interests, interest));
            if (interests.length > 0) {
                current.push(...interests);
            }
            else {
                current.push(next);
            }
        }
        return current;
    };
}
